import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { isoToDate } from "../../utils/helpers";
import { Button, Modal } from "react-bootstrap";

function PestTable({ pestRecords, allowEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
        },
        {
            id: 1,
            name: "Edit",
            icon: "icon-edit1",
            routerPath: "/pestmanagementrecord/edit",
            authType: 'admin',
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (pestRecords && pestRecords.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "12%", targets: 1 },
                        { width: "12%", targets: 3 }
                    ]
                });
            });
        }
    }, [pestRecords, $]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemovePest = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/pestmanagementrecords/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Pest Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Pest Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };
    return (
        <>
            <Preloader show={showPreloader} />

            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive">
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>Date</th>
                                        <th>Name of Product</th>
                                        <th>Source</th>
                                        <th>Active Ingredients</th>
                                        <th>Maturity Stage Of Crop</th>
                                        <th>Quantity Applied</th>
                                        <th>Applied By</th>
                                        <th>Supervised By</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pestRecords && pestRecords.length > 0 ? pestRecords.map((plantingRecord, index) => (
                                        <tr key={index}>
                                            <td>{plantingRecord.code ? plantingRecord.code : '--'}</td>
                                            <td style={{ width: '5rem' }}>{isoToDate(plantingRecord.date)}</td>
                                            <td>{plantingRecord.nameOfProduct}</td>
                                            <td>{plantingRecord.source}</td>
                                            <td>{plantingRecord.activeIngredients}</td>
                                            <td>{plantingRecord.maturityStageOfCrop}</td>
                                            <td>{plantingRecord.quantityApplied} {plantingRecord.quantityAppliedUnit}</td>
                                            <td>{plantingRecord.appliedBy}</td>
                                            <td>{plantingRecord.supervisedBy}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                to="#"
                                                                id={"dropdownMenuLink" + plantingRecord.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + plantingRecord.id}
                                                            >
                                                                {profileMenuItems.map((actionMenuItem) => (
                                                                    <>
                                                                        {(!actionMenuItem.authType || (actionMenuItem.authType === "admin" && allowEdit)) &&
                                                                            <li key={plantingRecord.id + '-' + actionMenuItem.id}>
                                                                                {actionMenuItem.onClick ? (
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="dropdown-item"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault(); // Prevent default anchor behavior
                                                                                            actionMenuItem.onClick(plantingRecord);
                                                                                        }}
                                                                                        style={
                                                                                            isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                ? { color: "#ff5a5f" }
                                                                                                : undefined
                                                                                        }
                                                                                    >
                                                                                        <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={actionMenuItem.routerPath + "/" + plantingRecord.id} // Changed href to to
                                                                                        className="dropdown-item"
                                                                                        style={
                                                                                            isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                ? { color: "#ff5a5f" }
                                                                                                : undefined
                                                                                        }
                                                                                    >
                                                                                        <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                    </Link>
                                                                                )}
                                                                            </li>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="7" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>
                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Pest Management</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this pest management record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemovePest}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Pest Management Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Code:</strong> {selectedRecord.code ? selectedRecord.code : "N/A"}</p>
                                            <p><strong>Date:</strong> {isoToDate(selectedRecord.date)}</p>
                                            <p><strong>Maturity Stage Of Crop:</strong> {selectedRecord.maturityStageOfCrop}</p>
                                            <p><strong>Name Of Product:</strong> {selectedRecord.nameOfProduct}</p>
                                            <p><strong>Active Ingredients:</strong> {selectedRecord.activeIngredients}</p>
                                            <p><strong>Quantity Applied:</strong> {selectedRecord.quantityApplied} {selectedRecord.quantityAppliedUnit}</p>
                                            <p><strong>Source:</strong> {selectedRecord.source}</p>
                                            <p><strong>Applied By:</strong> {selectedRecord.appliedBy}</p>
                                            <p><strong>Supervised By:</strong> {selectedRecord.supervisedBy}</p>
                                            <p><strong>Associated Cost:</strong> GHS {selectedRecord.associatedCost ? selectedRecord.notes :"N/A"}</p>

                                            {/* Environmental Conditions */}
                                            <hr/>
                                            <h4>Environmental Conditions</h4>
                                            {selectedRecord.environmentalCondition ? (
                                                <div>
                                                    <p><strong>Temperature:</strong> {selectedRecord.environmentalCondition.temperature}</p>
                                                    <p><strong>Weather Conditions:</strong> {selectedRecord.environmentalCondition.weatherConditions}</p>
                                                    <p><strong>Humidity:</strong> {selectedRecord.environmentalCondition.humidity ? selectedRecord.environmentalCondition.humidity :"N/A"}</p>
                                                    <p><strong>Rainfall:</strong> {selectedRecord.environmentalCondition.rainfall ? selectedRecord.environmentalCondition.rainfall :"N/A"}</p>
                                                    <p><strong>Wind Speed:</strong> {selectedRecord.environmentalCondition.windSpeed ? selectedRecord.environmentalCondition.windSpeed :"N/A"}</p>
                                                    <p><strong>Recorded Date:</strong> {isoToDate(selectedRecord.environmentalCondition.recordedDate)}</p>
                                                    <p><strong>Effects:</strong> {selectedRecord.environmentalCondition.effects}</p>
                                                </div>
                                            ) : (
                                                <p>No environmental conditions data available.</p>
                                            )}
                                        </div>
                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PestTable;