import { useEffect, useState } from "react";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../../utils/helpers";
import { getQuantityUnits } from "../../../api/miscellaneous/getQuantityUnits";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { toast } from "react-toastify";
import Preloader from "../../common/Preloader";
import { getProcessActivities } from "../../../api/ManufacturingProcesses/getManufacturingProcessList";

const ManufacturingProcessForm = ({ manufacturingProcesses,manufacturing}) => {
  const [quantityUnitList, setQuantityUnitList] = useState([]);



  const { user } = useAuth();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [performedBy, setPerformedBy] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [quantityUsed, setQuantityUsed] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('');
  const [processName, setProcessName] = useState('Dehydration');
  const [toolsAndMaterialsUsed, setToolsAndMaterialsUsed] = useState('');
  const [notes, setNotes] = useState('');
  const [qualityChecks, setQualityChecks] = useState('');
  const [issuesIdentified, setIssuesIdentified] = useState('');
  const [correctiveActions, setCorrectiveActions] = useState('');

  const [manufacturingProcessId, setManufacturingProcessId] = useState(null);


  const [processActivitiesList, setManufacturingProcessesList] = useState([]);

  //env conditions data

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {

    if (manufacturingProcessId) {
      getManufacturingProcesses(manufacturingProcessId);
    }
  }, [manufacturingProcessId])

  useEffect(() => {
    getQuantityUnits().then((data) => setQuantityUnitList(data));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.user.token) {
        try {
          const processActivities = await getProcessActivities(user.user.token);
          setManufacturingProcessesList(processActivities);
        } catch (error) {
          console.error("Failed to fetch process activities", error);
          // Optionally set an error state or show a message
        }
      } else {
        navigate('/login'); // Redirect if the token is not available
      }
    };

    fetchData();
  }, [user, navigate]);


  useEffect(() => {
    if (manufacturingProcesses) {
      setManufacturingProcessId(manufacturingProcesses.id);
      setStartDate(manufacturingProcesses.startDate);
      setProcessName(manufacturingProcesses.processName);
      setQuantityUsed(manufacturingProcesses.quantityUsed);
      setQuantityUnit(manufacturingProcesses.quantityUnit);
      setToolsAndMaterialsUsed(manufacturingProcesses.toolsAndMaterialsUsed);
      setPerformedBy(manufacturingProcesses.performedBy);
      setNotes(manufacturingProcesses.notes);
      setSupervisedBy(manufacturingProcesses.supervisedBy);
      setEndDate(manufacturingProcesses.endDate);
      setQualityChecks(manufacturingProcesses.qualityChecks);
      setIssuesIdentified(manufacturingProcesses.issuesIdentified);
      setCorrectiveActions(manufacturingProcesses.correctiveActions);
      // setUserId(userData.id);
    }
  }, [manufacturingProcesses]);



  const getManufacturingProcesses = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/manufacturingprocesses/${manufacturingProcessId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setStartDate(data.startDate);
        setProcessName(data.processName);
        setQuantityUsed(data.quantityUsed);
        setQuantityUnit(data.quantityUnit);
        setToolsAndMaterialsUsed(data.toolsAndMaterialsUsed);
        setPerformedBy(data.performedBy);
        setNotes(data.notes);
        setSupervisedBy(data.supervisedBy);
        setEndDate(data.endDate);
        setQualityChecks(data.qualityChecks);
        setIssuesIdentified(data.issuesIdentified);
        setCorrectiveActions(data.correctiveActions);


      })
      .catch((error) => {
        toast.error('Error fetching manufacturing process records data.');
        console.error("Error fetching manufacturing process records data:", error);
      })
      .finally(() => {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!startDate) {
      fieldWithIdRequired('startDate');
      toast.error("Start Date is required.");
      retVal = false;
    }

    if (!endDate) {
      fieldWithIdRequired('endDate');
      toast.error("End Date is required.");
      retVal = false;
    }
    if (!performedBy) {
      fieldWithIdRequired('performedBy');
      toast.error("Performed By is required.");
      retVal = false;
    }
    if (!processName) {
      fieldWithIdRequired('processName');
      toast.error("Process Name is required.");
      retVal = false;
    }
    if (!toolsAndMaterialsUsed) {
      fieldWithIdRequired('toolsAndMaterialsUsed');
      toast.error("Tools And Materials Used is required.");
      retVal = false;
    }
    if (!supervisedBy) {
      fieldWithIdRequired('supervisedBy');
      toast.error("Supervised By is required.");
      retVal = false;
    }

    // environmental conditions data


    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      manufacturingRecordId: manufacturing.id,
      startDate: startDate,
      endDate: endDate,
      processName: processName,
      toolsAndMaterialsUsed: toolsAndMaterialsUsed,
      notes: notes,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantityUsed: quantityUsed,
      quantityUnit: quantityUnit,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/manufacturingprocesses`, requestOptions);

      if (response.ok) {
        toast.success("The Manufacturing Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/manufacturingrecord/edit/${manufacturing.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the manufacturingprocesses.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: manufacturingProcessId,
      manufacturingRecordId: manufacturing.id,
      startDate: startDate,
      endDate: endDate,
      processName: processName,
      toolsAndMaterialsUsed: toolsAndMaterialsUsed,
      notes: notes,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantityUsed: quantityUsed,
      quantityUnit: quantityUnit,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/manufacturingprocesses/${manufacturingProcessId}`, requestOptions);

      if (response.ok) {
        toast.success("The manufacturing process has been successfully updated.");
        setShowPreloader(true);
        navigate(`/manufacturingrecord/edit/${manufacturing.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the manufacturing process.");
    } finally {
      setShowPreloader(false);
    }
  };


  return (
    <>

      <Preloader show={showPreloader} />

      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Manufacturing Process Form</div>
              <div className="mt-3 p-2 ">
                <div className="mt-2 mb-1">
                  <Link to={`/manufacturingrecord/edit/${manufacturing && manufacturing.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="processName">Process Name *</label>
                    <select
                      className="form-control"
                      id="processName"
                      value={processName}
                      onChange={(e) => { setProcessName(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Process Activity...</option>
                      {processActivitiesList && processActivitiesList.map((unit, index) =>
                        (<option key={index} value={unit}>{unit}</option>)
                      )}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      value={isoToDate(startDate)}
                      onChange={(e) => { setStartDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="endDate">End Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      value={isoToDate(endDate)}
                      onChange={(e) => { setEndDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>



                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="toolsAndMaterialsUsed">Tools and Materials Used *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="toolsAndMaterialsUsed"
                      placeholder="Enter the tools and materials used..."
                      value={toolsAndMaterialsUsed}
                      onChange={(e) => { setToolsAndMaterialsUsed(e.target.value); fieldRequired(e); }}

                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUsed">Quantity Used</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantityUsed"
                      placeholder="Enter the quantity used..."
                      value={quantityUsed}
                      onChange={(e) => setQuantityUsed(e.target.value)}

                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Unit</label>
                    <select
                      className="form-control"
                      id="quantityUnit"
                      value={quantityUnit}
                      onChange={(e) => setQuantityUnit(e.target.value)}
                    >
                      <option value="">Select Quantity Unit...</option>
                      {quantityUnitList && quantityUnitList.map((unit, index) =>
                        (<option key={index} value={unit}>{unit}</option>)
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="performedBy">Performed By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="performedBy"
                      placeholder="Enter the name of the person who performed the process..."
                      value={performedBy}
                      onChange={(e) => { setPerformedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="supervisedBy">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the name of the supervisor..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="qualityChecks">Quality Checks</label>
                    <input
                      type="text"
                      className="form-control"
                      id="qualityChecks"
                      placeholder="Enter the quality checks..."
                      value={qualityChecks}
                      onChange={(e) => setQualityChecks(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="issuesIdentified">Issues Identified</label>
                    <input
                      type="text"
                      className="form-control"
                      id="issuesIdentified"
                      placeholder="Enter the issues identified..."
                      value={issuesIdentified}
                      onChange={(e) => setIssuesIdentified(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="correctiveActions">Corrective Actions</label>
                    <input
                      type="text"
                      className="form-control"
                      id="correctiveActions"
                      placeholder="Enter the corrective actions..."
                      value={correctiveActions}
                      onChange={(e) => setCorrectiveActions(e.target.value)}
                    />
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      id="notes"
                      placeholder="Enter any notes..."
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(manufacturingProcessId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManufacturingProcessForm;
