import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import { Modal } from "react-bootstrap";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import { getWarehouseRecordsByCropId } from "../../api/WareHouseRecords/getWareRecordsList";
import WarehouseTable from "./WarehouseTable";
import WarehouseFormBasic from "./WarehouseFormBasic";

function WarehouseRecords({ crop }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [warehouseRecords, setWarehouseRecords] = useState([]);
  const [warehouseRecord, setWarehouseRecord] = useState();
  const [showModal, setShowModal] = useState(false)
  const [warehouseId, setWarehouseId] = useState('');
  const isAdmin = user && user.user && hasAdminRole(user.user.roles); 

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

  useEffect(() => {
    if (!user || !user.user || !user.user.token) {
      toast.warn("You are not authorized to do this!");
      navigate("/");
      return;
    }

    if (crop) {
      getWarehouseRecordsByCropId(user.user.token, crop.id).then(m => { setWarehouseRecords(m); });
    }
  }, [crop, user, navigate]);

  useEffect(() => {
    if (warehouseRecord) {
      setWarehouseId(warehouseRecord.id);

      if (warehouseRecord.environmentalCondition) {
        setEnvironmentalConditionId(warehouseRecord.environmentalCondition.id);
        setEnvironmentalCondition(warehouseRecord.environmentalCondition);
      }
    }
  }, [warehouseRecord]);

  const handleModalShow = (record = null) => {
    setWarehouseRecord(record || {
      cropId: crop.id,
      date: '',
      environmentalCondition: {
        temperature: '',
        weatherConditions: '',
        humidity: '',
        rainfall: '',
        windSpeed: '',
        recordedDate: '',
        effects: ''
      },
      capacity: null,
      contactInformation: '',
      location: '',
      manager: '',
      name: '',
      operationalHours: '',
      storageConditions: '',
      storageType: '',
      productsStored: '',
      securityMeasures: '',
      certification: '',
      certificationAttachment: '',
      inventoryManagementSystem: '',
    });
    setWarehouseId(record ? record.id : null);
    setShowModal(true);
  };


  const handleModalClose = () => {
    setShowModal(false);
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      cropId: crop.id,
      date: warehouseRecord.date,
      inventoryManagementSystem: warehouseRecord.inventoryManagementSystem,
      name: warehouseRecord.name,
      contactInformation: warehouseRecord.contactInformation,
      location: warehouseRecord.location,
      manager: warehouseRecord.manager,
      operationalHours: warehouseRecord.operationalHours,
      capacity: warehouseRecord.capacity,
      productsStored: warehouseRecord.productsStored,
      storageConditions: warehouseRecord.storageConditions,
      storageType: warehouseRecord.storageType,
      securityMeasures: warehouseRecord.securityMeasures,
      certification: warehouseRecord.certification,
      certificationAttachment: warehouseRecord.certificationAttachment,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/warehouserecords`, requestOptions);

      if (response.ok) {
        toast.success("The Warehouse Record has been successfully created.");
        getWarehouseRecordsByCropId(user.user.token, crop.id).then(records => {
          setWarehouseRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the warehouserecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: warehouseId,
      cropId: crop.id,
      date: warehouseRecord.date,
      inventoryManagementSystem: warehouseRecord.inventoryManagementSystem,
      name: warehouseRecord.name,
      contactInformation: warehouseRecord.contactInformation,
      location: warehouseRecord.location,
      manager: warehouseRecord.manager,
      operationalHours: warehouseRecord.operationalHours,
      capacity: warehouseRecord.capacity,
      productsStored: warehouseRecord.productsStored,
      storageConditions: warehouseRecord.storageConditions,
      storageType: warehouseRecord.storageType,
      securityMeasures: warehouseRecord.securityMeasures,
      certification: warehouseRecord.certification,
      certificationAttachment: warehouseRecord.certificationAttachment,
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/warehouserecords/${warehouseId}`, requestOptions);

      if (response.ok) {
        toast.success("The Warehouse record has been successfully updated.");
        getWarehouseRecordsByCropId(user.user.token, crop.id).then(records => {
          setWarehouseRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the warehouse record.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="main-container">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card" style={{ backgroundColor: "#eff1f5" }}>
              <div className="card-header">
                <div className="card-title">
                  Warehouse Records {crop ? " - " + getFullCropName(crop) : ""}
                </div>
                {isAdmin && (
                  <Link
                    to="#"
                    onClick={() => handleModalShow()}
                    className="btn btn-primary float-end"
                  >
                    <i className="icon-add"></i> Add Warehouse Record
                  </Link>
                )}
              </div>
              {warehouseRecords && warehouseRecords.length > 0 && (
                <WarehouseTable
                  warehouseRecords={warehouseRecords}
                  warehouseRecord={warehouseRecord}
                  setWarehouseRecord={setWarehouseRecord}
                  crop={crop}
                  handleSubmit={warehouseId ? handleSubmitEdit : handleSubmitCreate}
                  handleModalShow={handleModalShow}
                  handleModalClose={handleModalClose}
                  warehouseId={warehouseId}
                  allowModalEdit={true}
                />
              )}
            </div>
            <Modal size="lg" show={showModal} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>{warehouseId ? "Edit Warehouse Record" : "Add Warehouse Record"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <WarehouseFormBasic
                  crop={crop}
                  warehouseRecord={warehouseRecord}
                  setWarehouseRecord={setWarehouseRecord}
                  handleSubmit={warehouseId ? handleSubmitEdit : handleSubmitCreate}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default WarehouseRecords;