import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getPlantingMaterialTypes, getSourcesOfPlantingMaterials, getTreatmentTypesBeforePlanting } from "../../api/PlantingRecords/getPlantingRecordsList";
import EnvironmentalCondition from "../common/EnvironmentalCondition";

const PlantingForm = ({ plantingRecords,crop}) => {
  const { user } = useAuth();
  
  const [date, setDate] = useState('');
  const [expectedYield, setExpectedYield] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [plantingRecordId, setPlantingRecordId] = useState(null);
  const [source, setSource] = useState('');
  const [treatmentBeforePlanting, setTreatmentBeforePlanting] = useState('');
  const [plantingMaterialForm, setPlantingMaterialForm] = useState('');
  const [associatedCost, setAssociatedCost] = useState('');
  const [code, setCode] = useState('');

  const [sourceList, setSourceList] = useState([]);
  const [treatmentBeforePlantingList, setTreatmentBeforePlantingList] = useState([]);
  const [plantingMaterialFormList, setPlantingMaterialFormList] = useState([]);

  //env conditions data
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {

    if (plantingRecordId) {
      getPlantingRecords(plantingRecordId);
    }
  }, [plantingRecordId])

  useEffect(() => {
    if (plantingRecords) {
      setPlantingRecordId(plantingRecords.id);
      setDate(plantingRecords.date);
      setExpectedYield(plantingRecords.expectedYield);
      setSource(plantingRecords.source);
      setPlantingMaterialForm(plantingRecords.plantingMaterialForm);
      setTreatmentBeforePlanting(plantingRecords.treatmentBeforePlanting);
      setAssociatedCost(plantingRecords.associatedCost);
      setCode(plantingRecords.code);

      if (plantingRecords.environmentalCondition) {
        setEnvironmentalConditionId(plantingRecords.environmentalCondition.id);
        setEnvironmentalCondition(plantingRecords.environmentalCondition);
      }

      // setUserId(userData.id);
    }
  }, [plantingRecords]);


  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setSourceList(await getSourcesOfPlantingMaterials(user.user.token));
      setTreatmentBeforePlantingList(await getTreatmentTypesBeforePlanting(user.user.token));
      setPlantingMaterialFormList(await getPlantingMaterialTypes(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getPlantingRecords = async (plantingRecordId) => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }
  
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
  
    try {
      const response = await fetch(`${baseURL}/plantingrecords/${plantingRecordId}`, requestOptions);
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response text:", errorText);
        throw new Error('Failed to fetch data');
      }
  
      const data = await response.json();
  
      setDate(data.date);
      setExpectedYield(data.expectedYield);
      setSource(data.source);
      setPlantingMaterialForm(data.plantingMaterialForm);
      setTreatmentBeforePlanting(data.treatmentBeforePlanting);
      setAssociatedCost(plantingRecords.associatedCost);
      setCode(plantingRecords.code);
  
      if (data.environmentalCondition) {
        setEnvironmentalConditionId(data.environmentalCondition.id);
        setEnvironmentalCondition(data.environmentalCondition);
      }
    } catch (error) {
      toast.error('Error fetching planting records data.');
      console.error("Error fetching planting records data:", error);
    } finally {
      setShowPreloader(false);
    }
  };
  

  const validateFields = () => {
    let retVal = true;

    //planting records data

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Planting Date is required.");
      retVal = false;
    }
    if (!expectedYield) {
      fieldWithIdRequired('expectedYield');
      toast.error("Expected Yield is required.");
      retVal = false;
    }
    if (!plantingMaterialForm) {
      fieldWithIdRequired('plantingMaterialForm');
      toast.error("Planting Material Type is required.");
      retVal = false;
    }

    if (!source) {
      fieldWithIdRequired('source');
      toast.error("Cropping Type is required.");
      retVal = false;
    }

    if (!treatmentBeforePlanting) {
      fieldWithIdRequired('treatmentBeforePlanting');
      toast.error("Treatment Type Before Planting is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }


    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      expectedYield: expectedYield,
      plantingMaterialForm: plantingMaterialForm,
      source: source,
      treatmentBeforePlanting: treatmentBeforePlanting,
      associatedCost: associatedCost,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/plantingrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Planting Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/plantingrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the plantingrecord.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: plantingRecordId,
      cropId: crop.id,
      date: date,
      expectedYield: parseFloat(expectedYield),
      plantingMaterialForm,
      source,
      treatmentBeforePlanting,
      associatedCost,
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/plantingrecords/${plantingRecordId}`, requestOptions);

      if (response.ok) {
        toast.success("The planting record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/plantingrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the field.");
    } finally {
      setShowPreloader(false);
    }
  };


  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Planting Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/plantingrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {plantingRecordId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code ? code : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Planting Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="name"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Source *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={source}
                      onChange={(e) => { setSource(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Source Type...</option>
                      {sourceList && sourceList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Planting Material Type *</label>
                    <select
                      className="form-control form-control-lg"
                      id="plantingMaterialType"
                      value={plantingMaterialForm}
                      onChange={(e) => { setPlantingMaterialForm(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Planting Material Type...</option>
                      {plantingMaterialFormList && plantingMaterialFormList.map((materialList) => (
                        <option key={materialList} value={materialList}>
                          {materialList}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Treatment Before Planting *</label>
                    <select
                      className="form-control form-control-lg"
                      id="treatmentBeforePlanting"
                      value={treatmentBeforePlanting}
                      onChange={(e) => { setTreatmentBeforePlanting(e.target.value); fieldRequired(e); }}
                    >
                      {treatmentBeforePlantingList && treatmentBeforePlantingList.map((treatment) => (
                        <option key={treatment} value={treatment}>
                          {treatment}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Expected Yield <em>(in kg)</em> *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the expected yield..."
                      value={expectedYield}
                      onChange={(e) => {setExpectedYield(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="associatedCost">Associated Cost <em>(in GHS)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Associated Cost"
                      id="associatedCost"
                      value={associatedCost}
                      onChange={(e) => setAssociatedCost(e.target.value)}
                    />
                  </div>
                </div>

                <br />

              </div>

                <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(plantingRecordId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Row end */}
      </div>
    </>
  );
}

export default PlantingForm;