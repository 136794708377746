import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import Preloader from "../../common/Preloader";
import { getInvitationTargets } from "../../../api/Invitations/getInvitationTargets";
import { fieldRequired, fieldWithIdRequired } from "../../../utils/helpers";
import { getInvitationByCode } from "../../../api/Invitations/getInvitationByCode";
import FileInput from "../../common/FileInput";

const RegisterExporter = ({ invitationUniqueCode }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [uniqueCode, setUniqueCode] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [ownersLastName, setOwnersLastName] = useState('');
  const [ownersFirstName, setOwnersFirstName] = useState('');
  const [ownersOtherNames, setOwnersOtherNames] = useState('');
  const [ownersHomeAddress, setOwnersHomeAddress] = useState('');
  const [startingYearOfBusiness, setStartingYearOfBusiness] = useState('');
  const [logo, setLogo] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [certifications, setCertifications] = useState('');
  const [insuranceInformation, setInsuranceInformation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState('');

  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  const getSetUpData = async () => {
    try {
      setShowPreloader(true);
      const invitation = await getInvitationByCode(invitationUniqueCode);

      if (!invitation) {
        toast.warn("The registration token submitted is invalid!");
        navigate('/');
        return;
      } else {
        const theTarget = invitation.target.toUpperCase();
        if (theTarget !== "EXPORTER") {
          toast.warn("Invalid registration type!");
          navigate('/');
          return;
        }


      }
    } catch { } finally {
      setShowPreloader(false);
    }
  };

  const validateFields = () => {
    // let retVal = true;
    // if (!telephone) {
    //   fieldWithIdRequired('telephone');
    //   toast.error("Telephone is required.");
    //   retVal = false;
    // }
    // if (!email) {
    //   fieldWithIdRequired('email');
    //   toast.error("Email is required.");
    //   retVal = false;
    // }
    // if (!target) {
    //   fieldWithIdRequired('target');
    //   toast.error("Target is required.");
    //   retVal = false;
    // }
    // if (!purpose) {
    //   fieldWithIdRequired('purpose');
    //   toast.error("Purpose is required.");
    //   retVal = false;
    // }
    // return retVal;
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    // const data = {
    //   telephone,
    //   email,
    //   target,
    //   purpose,
    //   returnUrl: window.location.origin + "/register"
    // };

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "text/plain",
    //     Authorization: `Bearer ${user.user.token}`,
    //   },
    //   body: JSON.stringify(data),
    //   redirect: "follow",
    // };
    setShowPreloader(true);
    // try {
    //   const response = await fetch(`${baseURL}/invitations`, requestOptions);

    //   if (response.ok) {
    //     toast.success("The Invitations has been successfully sent.");

    //     setTelephone("");
    //     setEmail("");
    //     setTarget("");

    //     navigate("/registration/invitations");

    //     setShowPreloader(false);
    //   } else {
    //     handleNotOkHttpResponse(response);
    //   }
    // } catch (error) {
    //   toast.error("Could not send the invitation!");
    // } finally {
    //   setShowPreloader(false);
    // }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Register as an Exporter</div>
              <div className="card-sub-title">Enter your details below and submit when you are done.</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="telephone">Unique Code *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="uniqueCode"
                      value={uniqueCode}
                      onChange={(e) => { setUniqueCode(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Unique..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Business Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="businessName"
                      value={businessName}
                      onChange={(e) => { setBusinessName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Business Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Email..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="target">Telephone *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="telephone"
                      value={telephone}
                      onChange={(e) => { setTelephone(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Telephone..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Business Address *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="businessAddress"
                      value={businessAddress}
                      onChange={(e) => { setBusinessAddress(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Business Address..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Owner's Last Name *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="ownersLastName"
                      value={ownersLastName}
                      onChange={(e) => { setOwnersLastName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Owner's Last Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Owner's First Name *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="ownersFirstName"
                      value={ownersFirstName}
                      onChange={(e) => { setOwnersFirstName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Owner's First Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Owner's Other Names</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="ownersOtherNames"
                      value={ownersOtherNames}
                      onChange={(e) => { setOwnersOtherNames(e.target.value); }}
                      placeholder="Enter the Owner's Other Name..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Owner's Home Address *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="ownersHomeAddress"
                      value={ownersHomeAddress}
                      onChange={(e) => { setOwnersHomeAddress(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Owner's First Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Starting Year of Business *</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="startingYearOfBusiness"
                      value={startingYearOfBusiness}
                      onChange={(e) => { setStartingYearOfBusiness(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Starting Year of Business..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="logo">Business Logo</label>
                    <FileInput
                      id={"logo"}
                      value={logo}
                      setValue={setLogo}
                      required={false}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="businessRegistrationNumber">Business Registration Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="businessRegistrationNumber"
                      value={businessRegistrationNumber}
                      onChange={(e) => { setBusinessRegistrationNumber(e.target.value); }}
                      placeholder="Enter the Business Registration Number..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="taxIdentificationNumber">Tax Identification Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="taxIdentificationNumber"
                      value={taxIdentificationNumber}
                      onChange={(e) => { setTaxIdentificationNumber(e.target.value); }}
                      placeholder="Enter the Tax Identification Number..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="website"
                      value={website}
                      onChange={(e) => { setWebsite(e.target.value); }}
                      placeholder="Enter Website..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="certifications">Certifications</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="certifications"
                      value={certifications}
                      onChange={(e) => { setCertifications(e.target.value); }}
                      placeholder="Enter the Certifications..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="insuranceInformation">Insurance Information</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="insuranceInformation"
                      value={insuranceInformation}
                      onChange={(e) => { setInsuranceInformation(e.target.value); }}
                      placeholder="Enter Insurance Information..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="yearsOfExperience">Years of Experience</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="yearsOfExperience"
                      value={yearsOfExperience}
                      onChange={(e) => { setYearsOfExperience(e.target.value); }}
                      placeholder="Enter your Years of Experience..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="businessDescription">Business Description</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="businessDescription"
                      value={businessDescription}
                      onChange={(e) => { setBusinessDescription(e.target.value); }}
                      placeholder="Enter Business Description..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="socialMediaLinks">Social Media Links</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="socialMediaLinks"
                      value={socialMediaLinks}
                      onChange={(e) => { setSocialMediaLinks(e.target.value); }}
                      placeholder="Enter your Social Media Links..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="float-end">
                    <button type="button" onClick={handleSubmitCreate} className="btn btn-primary float-end">
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default RegisterExporter;
