import { Navigate } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Preloader from "../../../../components/common/Preloader";
import { fieldRequired } from "../../../../utils/helpers";

const UserForm = ({ userData }) => {
  const { user } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [otherNames, setOtherNames] = useState('');
  const [username, setUsername] = useState('');
  const [designation, setDesignation] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  useEffect(() => {

    if (userId) {
      getUserData(userId);
    }
  }, [userId])




  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setUsername(userData.username)
      setEmail(userData.email);
      setOtherNames(userData.otherNames);
      setPassword(userData.password);
      setDesignation(userData.designation);
      setUserId(userData.id);
    }
  }, [userData]);



  const getUserData = async (userId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/Users/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setDesignation(data.designation);
        setEmail(data.email);
        setOtherNames(data.otherNames);
        setTelephone(data.telephone);
        setPassword(data.password);
        setUsername(data.username);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching user data.');
        console.error("Error fetching user data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if ( !firstName || !lastName || !email || !password ||
      !username || !telephone) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      firstName: firstName,
      lastName: lastName,
      otherNames: otherNames,
      username: username,
      email: email,
      telephone: telephone,
      password: password,
      designation: designation,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/Users`, requestOptions);

      if (response.ok) {
        toast.success("The User has been successfully created.");
        setShowPreloader(true);
        navigate("/users");
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the user.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if ( !firstName || !lastName || !email || !password ||
      !username || !telephone 
    ) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      id: userId,
      firstName: firstName,
      lastName: lastName,
      otherNames: otherNames,
      username: username,
      email: email,
      telephone: telephone,
      password: password,
      designation: designation,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/Users/${userId}`, requestOptions);

      if (response.ok) {
        toast.success("The user has been successfully updated.");
        setShowPreloader(true);
        navigate("/users");
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the user.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">User Form</div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter the First Name..."
                      value={firstName}
                      onChange={(e) => {setFirstName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter the Last Name..."
                      value={lastName}
                      onChange={(e) => {setLastName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Other Name(s)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="otherNames"
                      placeholder="Enter the Other Name(s)..."
                      value={otherNames}
                      onChange={(e) => setOtherNames(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="username">Username *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter the Username..."
                      value={username}
                      onChange={(e) => {setUsername(e.target.value); fieldRequired(e); }}
                    />
                  </div>                    
                  <div className="invalid-feedback">This is required</div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter the Email..."
                      value={email}
                      onChange={(e) => {setEmail(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="telephone">Telephone *</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="telephone"
                      placeholder="Enter the Telephone..."
                      value={telephone}
                      onChange={(e) => {setTelephone(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="designation">Designation</label>
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      placeholder="Enter the Designation..."
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="password">Password *</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter Password..."
                      value={password}
                      onChange={(e) => {setPassword(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(userId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default UserForm;