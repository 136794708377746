import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, getUserFarmerName, getUserFullName, isoToDate } from "../../utils/helpers";

const FarmForm = ({ farmData }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [area, setArea] = useState('');
  const [farmCode, setFarmCode] = useState('');
  const [dateEstablished, setDateEstablished] = useState('');
  const [location, setLocation] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [farmerId, setFarmerId] = useState();
  const [farmerList, setFarmerList] = useState([]);
  const [farmId, setFarmId] = useState(null);

  const locations = useLocation();  
  const queryParams = new URLSearchParams(locations.search);
  const value = queryParams.get('farmer'); // Retrieve the value of a specific query parameter
  useEffect(() => {

    getFarmerData()
    if (farmId) {
      getFarmData(farmId);
    }
  }, [farmId])

  useEffect(() => {
    if (value) {
      setFarmerId(value);
    }
  }, [value]);

  useEffect(() => {
    if (farmData) {
      setFarmId(farmData.id);
      setFarmerId(farmData.farmerId);
      setName(farmData.name);
      setArea(farmData.area);
      setLocation(farmData.location);
      setDateEstablished(farmData.dateEstablished);
      setFarmCode(farmData.farmCode);
      
    }
  }, [farmData]);

  const getFarmData = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/farms/${farmId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setFarmerId(data.farmerId);
        setName(data.name);
        setArea(data.area);
        setLocation(data.location);
        setDateEstablished(data.dateEstablished);
        setFarmCode(data.farmCode);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching farm data.');
        console.error("Error fetching farm data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const getFarmerData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch user data when the component mounts
    fetch(`${baseURL}/farmers`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching farmers')
        }
      })
      .then((data) => {
        // Set the useState valuations with the fetched data
        setFarmerList(data);
      })
      .catch((error) => {
        toast.error("Error fetching Farmer Data.");
      })
      .finally(()=>{
        setShowPreloader(false);
      })
  };

  const validateFields = () => {
    let retVal = true;
    if (!name) {
      fieldWithIdRequired('name');
      toast.error("Farm Name is required.");
      retVal = false;
    }
    if (!area) {
      fieldWithIdRequired('area');
      toast.error("Farm Area is required.");
      retVal = false;
    }
    if (!location) {
      fieldWithIdRequired('location');
      toast.error("Farm Location is required.");
      retVal = false;
    }

    if (!dateEstablished) {
      fieldWithIdRequired('dateEstablished');
      toast.error("Date Established is required.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      name: name,
      farmerId,
      area: area,
      location: location,
      dateEstablished: dateEstablished,
    };
    console.log("farm data >:", data);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/farms`, requestOptions);

      if (response.ok) {
        toast.success("The Farm has been successfully created.");
        setShowPreloader(true);
        navigate(`/farms/farmer/${farmerId}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the farm.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: farmId,
      farmerId,
      name: name,
      area: area,
      location: location,
      dateEstablished: dateEstablished,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/farms/${farmId}`, requestOptions);

      if (response.ok) {
        toast.success("The farm has been successfully updated.");
        setShowPreloader(true);
        navigate(`/farms/farmer/${farmerId}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the farm.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Farm Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Farmer: </span>
                  {/* am fetching for the farmer detail and the unique  */}
                  {
                    (() => {
                      const farmer = farmerList.find(farmer => farmer.id === farmerId);
                      if (farmer) {
                        const fullName = getUserFullName(farmer);
                        return `${farmer.uniqueCode || ''} -- ${fullName || ''}`.trim();
                      }
                      return '--';
                    })()
                  }
                  {/* a button to navigate back (-1) */}
                  <button className="btn btn-primary float-end"
                    style={{ marginTop: "-7px" }}
                    onClick={() => navigate(-1)}>
                    Go Back
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {farmId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={farmCode ? farmCode : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Farm Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the name of the Farm..."
                      value={name}
                      onChange={(e) => {setName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Farm Location *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      placeholder="Enter the location of the farm..."
                      value={location}
                      onChange={(e) => {setLocation(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Farm Area <em>(acres)</em> *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="area"
                      placeholder="Enter the area of the farm..."
                      value={area}
                      onChange={(e) => {setArea(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="password">Date Established *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateEstablished"
                      value={isoToDate(dateEstablished)}
                      onChange={(e) => {setDateEstablished(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(farmId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default FarmForm;