import { useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../../components/common/SideBarWrapper";
import Navbar from "../../../../layout/Navbar";
import WorkFlowProcessForm from "../WorkFlowProcessForm";
import { useEffect, useState } from "react";
import { baseURL } from "../../../../utils/misc";
import useAuth from "../../../../hooks/useAuth";

const EditWorkFlowProcess = () => {
    const { workflowprocessId } = useParams();
    const [workflowData, setWorkflowData] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        // Fetch workflow process data when the component mounts
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };

            try {
                const response = await fetch(`${baseURL}/workflowprocesses/${workflowprocessId}`, requestOptions);

                if (response.ok) {
                    const data = await response.json();
                    setWorkflowData(data);
                } else {
                    console.error('Error fetching workflow process data');
                }
            } catch (error) {
                console.error('Error fetching workflow process data:', error);
            }
        };

        fetchData();
    }, [workflowprocessId]);



    return (<>
        {/* Loading starts */}
        {/* <div id="loading-wrapper">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
        {/* Loading ends */}


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">WorkFlow Process</li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <WorkFlowProcessForm workflowData={workflowData}  />
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}

        </div>
        {/* Page wrapper end */}
    </> );
}
 
export default EditWorkFlowProcess;