import { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Accordion, Button, Card, CardHeader } from "react-bootstrap";
import Preloader from "../common/Preloader";
import { getFullCropName, isoToDate } from "../../utils/helpers";
import { Modal } from "react-bootstrap";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import { getExportCustomRecordsByCropId } from "../../api/ExportCustomsRecords/getExportCustomRecords";
import ExportCustomTable from "./ExportCustomTable";
import ExportCustomFormBasic from "./ExportCustomFormBasic";

function ExportCustomRecords({ crop }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [exportCustomRecords, setExportCustomRecords] = useState([]);
  const [exportCustomRecord, setExportCustomRecord] = useState();
  const [showModal, setShowModal] = useState(false)
  const [exportCustomId, setExportCustomId] = useState(null);
  const isAdmin = user && user.user && hasAdminRole(user.user.roles);

  //env conditions data

  // useEffect(() => {
  //     //Instantiation record with empty values
  //     setTransferRecord(
  //         {
  //           cropId: crop.id,
  //           date: '',
  //           environmentalCondition: {
  //             temperature: '',
  //             weatherConditions: '',
  //             humidity: '',
  //             rainfall: '',
  //             windSpeed: '',
  //             recordedDate: '',
  //             effects: ''
  //           },
  //           origin: '',
  //           destination: '',
  //           quantity: null,
  //           quantityUnit: '',
  //           transportMethod: '',
  //           transporterName: '',
  //           transporterContact: '',
  //           vehicleDetails: '',
  //           remarks: ''
  //         }
  //       );
  // }, []);


  useEffect(() => {
    if (!user || !user.user || !user.user.token) {
      toast.warn("You are not authorized to do this!");
      navigate("/");
      return;
    }

    if (crop) {
      getExportCustomRecordsByCropId(user.user.token, crop.id).then(m => { setExportCustomRecords(m); });
    }
  }, [crop, user, navigate]);

  useEffect(() => {
    if (exportCustomRecord) {
      setExportCustomId(exportCustomRecord.id);

    }
  }, [exportCustomRecord]);

  const handleModalShow = (record = null) => {
    setExportCustomRecord(record || {
      cropId: crop.id,
      dateReported: '',
      quantityAffected: null,
      quantityUnit: '',
      spoilageType: '',
      causes: '',
      reportedBy: '',
      actionsTaken: '',
      inspectionDetails: '',
      comments: '',
      attachments: ''
    });
    setExportCustomId(record ? record.id : null);
    setShowModal(true);
  };


  const handleModalClose = () => {
    setShowModal(false);
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      cropId: crop.id,
      currency: exportCustomRecord.currency,
      customsDeclarationNumber: exportCustomRecord.customsDeclarationNumber,
      destinationCountry: exportCustomRecord.destinationCountry,
      exporter: exportCustomRecord.exporter,
      exportMode: exportCustomRecord.exportMode,
      portOfDischarge: exportCustomRecord.portOfDischarge,
      portOfLoading: exportCustomRecord.portOfLoading,
      totalDeclaredValue: exportCustomRecord.totalDeclaredValue,
      shippingLineOrAirline: exportCustomRecord.shippingLineOrAirline,
      flightOrVesselNumber: exportCustomRecord.flightOrVesselNumber,
      departureDate: exportCustomRecord.departureDate,
      arrivalDate: exportCustomRecord.arrivalDate,
      totalWeight: exportCustomRecord.totalWeight,
      weightUnit: exportCustomRecord.weightUnit,
      containerNumber: exportCustomRecord.containerNumber,
      freightForwarder: exportCustomRecord.freightForwarder,
      handlingAgent: exportCustomRecord.handlingAgent,
      shippingDocuments: exportCustomRecord.shippingDocuments,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/spoilagereports`, requestOptions);

      if (response.ok) {
        toast.success("The Spoilage Record has been successfully created.");
        getExportCustomRecordsByCropId(user.user.token, crop.id).then(records => {
          setExportCustomRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the Spoilage record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: exportCustomId,
      cropId: crop.id,
      currency: exportCustomRecord.currency,
      customsDeclarationNumber: exportCustomRecord.customsDeclarationNumber,
      destinationCountry: exportCustomRecord.destinationCountry,
      exporter: exportCustomRecord.exporter,
      exportMode: exportCustomRecord.exportMode,
      portOfDischarge: exportCustomRecord.portOfDischarge,
      portOfLoading: exportCustomRecord.portOfLoading,
      totalDeclaredValue: exportCustomRecord.totalDeclaredValue,
      shippingLineOrAirline: exportCustomRecord.shippingLineOrAirline,
      flightOrVesselNumber: exportCustomRecord.flightOrVesselNumber,
      departureDate: exportCustomRecord.departureDate,
      arrivalDate: exportCustomRecord.arrivalDate,
      totalWeight: exportCustomRecord.totalWeight,
      weightUnit: exportCustomRecord.weightUnit,
      containerNumber: exportCustomRecord.containerNumber,
      freightForwarder: exportCustomRecord.freightForwarder,
      handlingAgent: exportCustomRecord.handlingAgent,
      shippingDocuments: exportCustomRecord.shippingDocuments,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/exportcustomsrecords/${exportCustomId}`, requestOptions);

      if (response.ok) {
        toast.success("The export customs record has been successfully updated.");
        getExportCustomRecordsByCropId(user.user.token, crop.id).then(records => {
          setExportCustomRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the export customs record.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="main-container">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card" style={{ backgroundColor: "#eff1f5" }}>
              <div className="card-header">
                <div className="card-title">
                  Export Customs Records {crop ? " - " + getFullCropName(crop) : ""}
                </div>
                {isAdmin && (
                <Link
                  to="#"
                  onClick={() => handleModalShow()}
                  className="btn btn-primary float-end"
                >
                  <i className="icon-add"></i> Add Export Customs Record
                </Link>
                )}
              </div>
              {exportCustomRecords && exportCustomRecords.length > 0 && (
                <ExportCustomTable 
                  exportCustomRecords={exportCustomRecords}
                  exportCustomRecord={exportCustomRecord}
                  setExportCustomRecord={setExportCustomRecord}
                  crop={crop}
                  allowEdit={true}
                  handleSubmit={exportCustomId ? handleSubmitEdit : handleSubmitCreate}
                  handleModalShow={handleModalShow}
                  handleModalClose={handleModalClose}
                  exportCustomId={exportCustomId}
                  allowModalEdit={true}
                />
              )}
            </div>
            <Modal size="lg" show={showModal} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>{exportCustomId ? "Edit Export Customs Record" : "Add Export Customs Record"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ExportCustomFormBasic
                  crop={crop}
                  exportCustomRecord={exportCustomRecord}
                  setExportCustomRecord={setExportCustomRecord}
                  handleSubmit={exportCustomId ? handleSubmitEdit : handleSubmitCreate}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportCustomRecords;