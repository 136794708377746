import { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { displayDateTime, getFullBMRProductTypeName, isoToDate, isoToDateTime } from "../../utils/helpers";
import { getCertificationByBMR } from "../../api/BMRCertification/getCertificationByBMR";

function Contracts() {
    const { user } = useAuth();
    const [workflowRecords, setWorkflowRecords] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [certifications, setCertifications] = useState({});

    const $ = require("jquery");

    useEffect(() => {
        if (workflowRecords && workflowRecords.length > 0) {
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();
                if (dataTable) {
                    dataTable.destroy();
                }
                $("#data-table").DataTable({
                    columnDefs: [
                        // { width: "12%", targets: 0 },
                        // { width: "33%", targets: 1 },
                        // { width: "10%", targets: 2 }
                    ]
                });
            });
        }
    }, [workflowRecords]);

    useEffect(() => {
        const fetchWorkflowRecords = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/workflowrecords`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching Contracts');
                }
                const data = await response.json();
                setWorkflowRecords(data);

                const certPromises = data.map(async process => {
                    if (process.workflowRecord && process.workflowRecord.bmr && process.workflowRecord.bmr.id) {
                        const cert = await fetchCert(process.workflowRecord.bmr.id);
                        return { id: process.workflowRecord.bmr.id, cert };
                    }
                    return null;
                });

                const certResults = await Promise.all(certPromises);
                const certsMap = certResults.reduce((acc, curr) => {
                    if (curr && curr.cert) {
                        acc[curr.id] = curr.cert;
                    }
                    return acc;
                }, {});

                setCertifications(certsMap);
            } catch (error) {
                toast.error("Error fetching workflow record activities.");
                console.error("Error fetching workflow record activities:", error);
            } finally {
                setShowPreloader(false);
            }
        };

        fetchWorkflowRecords();
    }, []);

    const fetchCert = async (bmrId) => {
        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }
        if (!bmrId) {
            console.error("Invalid bmrId:", bmrId);
            return;
        }
        setShowPreloader(true);
        try {
            const theRecord = await getCertificationByBMR(user.user.token, bmrId);
            return theRecord;
        } catch (error) {
            toast.error("Error fetching certificate by BMR.");
            console.error("Error fetching certificate by BMR:", error);
            return null;
        } finally {
            setShowPreloader(false);
        }
    };


    const openOwnership = async (workflowRecordActivityId, processId, workflowStageId) => {
        if (!(user && user.user.token)) {
            navigate("/login");
        }

        setShowPreloader(true);
        navigate(`/bmr/${processId}/update-ownership/${workflowRecordActivityId}/${workflowStageId}`);
        setShowPreloader(false);
    };

    const openAttachment = async (processId, bmrId) => {
        if (!(user && user.user.token)) {
            navigate("/login");
        }

        setShowPreloader(true);
        navigate(`/${processId}/bmr/${bmrId}/attachment`);
        setShowPreloader(false);
    };

    const openReport = async (processId, bmrId) => {
        if (!(user && user.user.token)) {
            navigate("/login");
        }

        setShowPreloader(true);
        navigate(`/${processId}/bmr/${bmrId}/generate-report`);
        setShowPreloader(false);
    };

    const getProfileMenuItems = (process) => {
        const bmrId = process.workflowRecord?.bmr?.id;
        const cert = certifications[bmrId];
        const menuItems = [
            {
                id: 1,
                name: "Update Ownership",
                icon: "flaticon-transfer-1",
                onClick: () => openOwnership(process.id, process.workflowRecord.id, process.workflowStage.id),
            },
        ];
    
        if (cert && cert.finishedProductSatisfactory !== null && cert.operationalStepsComplete !== null) {
            menuItems.push({
                id: 2,
                name: "Generate Report",
                icon: "flaticon-transfer-1",
                onClick: () => openReport(process.id, bmrId),
            });
        }

        
    
        return menuItems;
    };
    

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">

                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">All Contracts</div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        {workflowRecords && workflowRecords.length > 0 ? (
                                            <div className="table-responsive">
                                                <table id="data-table" className="table custom-table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Crop</th>
                                                            <th>Buyer</th>
                                                            <th>Created Date</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {workflowRecords?.map((record) => {
                                                            const profileMenuItems = getProfileMenuItems(record);
                                                            return (
                                                                <tr key={record.id}>
                                                                    <td>{(record && record.contract) ? record.contract.contractCode : "--"}</td>
                                                                    <td>{(record && record.contract && record.contract.crop) ? record.contract.crop.cropCode + ' - ' + record.contract.crop.name : "--"}</td>
                                                                    <td>{(record && record.contract && record.contract.buyer) ? record.contract.buyer.code + ' - ' + record.contract.buyer.businessName : "--"}</td>
                                                                    <td>{(record && record.contract) ? displayDateTime(record && record.contract.createdDate) : "--"}</td>
                                                                    <td>{(record && record.contract) ? isoToDate(record && record.contract.startDate) : "--"}</td>
                                                                    <td>{(record && record.contract) ? isoToDate(record && record.contract.endDate) : "--"}</td>
                                                                    <td>{(record && record.workflowStatus) ? record.workflowStatus.name : "--"}</td>
                                                                    <td className="">
                                                                        <ul>
                                                                            <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                                                <div className="dropdown">
                                                                                    <Link
                                                                                        className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                                        role="button"
                                                                                        to="#"
                                                                                        id={"dropdownMenuLink" + record.id}
                                                                                        data-bs-toggle="dropdown"
                                                                                    >
                                                                                        Actions <i className="fa fa-caret-down"></i>
                                                                                    </Link>
                                                                                    <ul className="dropdown-menu" aria-labelledby={"dropdownMenuLink" + record.id}>
                                                                                        <li key={record.id + "updateOwnership"}>
                                                                                            <Link to={`/contracts/record/${record.id}`} className="dropdown-item">
                                                                                                <i className="icon-eye1"></i> View
                                                                                            </Link>
                                                                                        </li>
                                                                                        {/* <li className="dropdown-header">Reports</li> */}
                                                                                        
                                                                                    </ul>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default Contracts;
