import { useState } from 'react';

import {
  Link,
  NavLink,
} from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import { doesRoleExist } from '../utils/misc';
import { runningCompanyHasModule } from '../appSetup';

function SideBarMenu() {
  const { user } = useAuth();

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownToggle = (menuName) => {
    setActiveDropdown(activeDropdown === menuName ? null : menuName);
  };

  const handleCurrentPageMenuClick = () => { };

  return (
    <div className="sidebar-menu">
      <ul>
        <li className="header-menu">General</li>
        <li>
          <Link to="/">
            <i className="icon-home"></i>
            <span className="menu-text">Home</span>
          </Link>
        </li>

        
        {doesRoleExist(user, "Super Admin") && (
          <>
            <li className="header-menu">Admin</li>
            <li>
              <Link to="/users">
                <i className="icon-users"></i>
                <span className="menu-text">Users</span>
              </Link>
            </li>
            <li
              className={`sidebar-dropdown ${activeDropdown === "workflow" ? "active" : ""
                }`}
            >
              <Link to="#" onClick={() => handleDropdownToggle("workflow")}>
                <i className="icon-developer_board"></i>
                <span className="menu-text">Workflows</span>
              </Link>

              <div className={`sidebar-submenu ${activeDropdown === "workflow" ? "active" : ""}`}>
                <ul>
                  <li>
                    <NavLink to="/workflows" className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "current-page" : ""
                    }>
                      List Workflows
                    </NavLink>
                  </li>
                  {/* <li>
                    <a href="sales-dashboard.html">Create WorkFlow</a>
                  </li> */}
                </ul>
              </div>
            </li>
          </>
        )}

        {doesRoleExist(user, "Admin") && (
          <>
            <li className="header-menu">Admin</li>
            <li>
              <Link to="/users">
                <i className="icon-users"></i>
                <span className="menu-text">Users</span>
              </Link>
            </li>
            <li className={`sidebar-dropdown ${activeDropdown === "invitations" ? "active" : ""}`}>
              <Link to="#" onClick={() => handleDropdownToggle("invitations")}>
                <i className="icon-plus1"></i>
                <span className="menu-text">Invitations</span>
              </Link>

              <div className={`sidebar-submenu ${activeDropdown === "invitations" ? "active" : ""}`}>
                <ul>
                  <li>
                    <NavLink to="/registration/invitations" className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "current-page" : ""
                    }>
                      List Invitations
                    </NavLink>
                  </li>
                  {/* <li>
                    <a href="sales-dashboard.html">Create WorkFlow</a>
                  </li> */}
                </ul>
              </div>
            </li>
            {runningCompanyHasModule('OUT-GROWERS') &&
              <li>
                <Link to="/outgrowers">
                  <i className="icon-people"></i>
                  <span className="menu-text">Outgrowers</span>
                </Link>
              </li>
            }
            {runningCompanyHasModule('MANUFACTURING') &&
              <li>
                <Link to="/crops-manufacturingrecords">
                  <i className="icon-shopping-basket"></i>
                  <span className="menu-text">Manufacturing Records</span>
                </Link>
              </li>
            }
            <li>
              <Link to="/crops-spoilagerecords">
                <i className="icon-cloud-off"></i>
                <span className="menu-text">Spoilage Records</span>
              </Link>
            </li>

            <li>
              <Link to="/company-info">
                <i className="icon-info2"></i>
                <span className="menu-text">Company Info</span>
              </Link>
            </li>

          </>
        )}

        {/* {doesRoleExist(user, "Exporter") && (
          <>
            <li>
              <Link to="/company-info">
                <i className="icon-info2"></i>
                <span className="menu-text">Company Info</span>
              </Link>
            </li>
          </>
        )} */}
        {(doesRoleExist(user, "Farmer") ) && (
          <>
            <li className="header-menu">Operations</li>
            <li className={`sidebar-dropdown ${activeDropdown === "workflow" ? "active" : ""}`}>
              <Link to="#" onClick={() => handleDropdownToggle("workflow")}>
                <i className="icon-developer_board"></i>
                <span className="menu-text">Farms</span>
              </Link>

              <div className={`sidebar-submenu ${activeDropdown === "workflow" ? "active" : ""}`}>
                <ul>
                  <li>
                    <NavLink to={`/farms`} className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "current-page" : ""
                    }>
                      My Farms
                    </NavLink>
                  </li>
                  {/* <li>
                    <a href="sales-dashboard.html">Create WorkFlow</a>
                  </li> */}
                </ul>
              </div>
            </li>
          </>
        )}

        {doesRoleExist(user, "Admin") && (
          <>
            <li className="header-menu">Transactions</li>
            <li
              className={`sidebar-dropdown ${activeDropdown === "bmr" ? "active" : ""
                }`}
            >
              <Link to="#" onClick={() => handleDropdownToggle("bmr")}>
                <i className="icon-devices_other"></i>
                <span className="menu-text">Contracts</span>
              </Link>
              <div className="sidebar-submenu">
                <ul>
                  <li>
                    <Link to="/contracts">
                      <span className="menu-text">All Contracts</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contracts/initiate">
                      <span className="menu-text">Initiate Contract</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contracts/attention-required">
                      <span className="menu-text" style={{color: "red"}}>Attention Required</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </>
        )}

        {doesRoleExist(user, "Buyer") && (
          <>
            <li className="header-menu">Transactions</li>
            <li
              className={`sidebar-dropdown ${activeDropdown === "bmr" ? "active" : ""
                }`}
            >
              <Link to="#" onClick={() => handleDropdownToggle("bmr")}>
                <i className="icon-devices_other"></i>
                <span className="menu-text">Contracts</span>
              </Link>
              <div className="sidebar-submenu">
                <ul>
                  <li>
                    <Link to="/contracts">
                      <span className="menu-text">All Contracts</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-submenu">
                <ul>
                  <li>
                    <Link to="/contracts/attention-required">
                      <span className="menu-text" style={{color: "red"}}>Attention Required</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </>
        )}

        {user && user.user && (
          <>
            <li className="header-menu">SETTINGS</li>
            <li>
              <Link to={"/user-profile"}>
                <i className="icon-user1"></i>
                <span className="menu-text">User Profile</span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
export default SideBarMenu;