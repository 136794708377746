import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Preloader from "../../components/common/Preloader";

const WorkFlowForm = ({ workflowData }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [workflowId, setWorkFlowId] = useState(null);
  useEffect(() => {
    getWorkFlowData();

    if (workflowId) {
      getWorkFlowData(workflowId);
    }
  }, [workflowId])




  useEffect(() => {
    if (workflowData) {
      setName(workflowData.name);
      setDescription(workflowData.description);
      setWorkFlowId(workflowData.id);
    }
  }, [workflowData]);



  const getWorkFlowData = async (workflowId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/workflows/${workflowId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setName(data.name);
        setDescription(data.description);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching workflow data.');
        console.error("Error fetching workflow data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if ( !name || !description) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      name: name,
      description: description
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflows`, requestOptions);

      if (response.ok) {
        toast.success("The Workflow has been successfully created.");
        setShowPreloader(true);
        navigate("/workflows");
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the workflow.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if ( !name || !description) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      id: workflowId,
      name: name,
      description: description
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflows/${workflowId}`, requestOptions);

      if (response.ok) {
        toast.success("The workflow has been successfully updated.");
        setShowPreloader(true);
        navigate("/workflows");
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the workflow.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">WorkFlow</div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the Name..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="fillVolume">Description</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="description"
                      placeholder="Enter the Description.."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(workflowId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WorkFlowForm;