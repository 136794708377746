import { Link } from "react-router-dom";
import SideBarMenu from "../../layout/SideBarMenu";
import { runningCompany } from "../../appSetup";

function SideBarWrapper({farmerId}){
	const rootUrl = window.location.origin;
    return (
      <nav id="sidebar" className="sidebar-wrapper">
        {/* Sidebar brand start  */}
        <div className="sidebar-brand">
          <Link to="/" className="logo">
            <img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logoTiny}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
          </Link>
        </div>
        {/* Sidebar brand end  */}

        {/* Sidebar content start */}
        <div className="sidebar-content">
          {/* sidebar menu start */}
          <SideBarMenu farmerId= {farmerId}/>
          {/* sidebar menu end */}
        </div>
        {/* Sidebar content end */}
      </nav>
    );
}

export default SideBarWrapper;