import { Link } from "react-router-dom";
import { getFullBuyerName, getFullCropName, isoToDate } from "../../utils/helpers";

const ContractView = ({ contract }) => {
  return (
    <>
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card" style={{backgroundColor: "#eff1f5"}}>
            <div className="card-header">
              <div className="card-title">{`${(contract ? contract.contractCode + ' - ' : 'Contract ') + 'Details'} `}</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                {/* Crop */}
                <div className="col-12 mb-3">
                  <p><strong>Crop:</strong> {contract && contract.crop ? getFullCropName(contract.crop) : 'n/a'}</p>
                </div>

                {/* Buyer */}
                <div className="col-12 mb-3">
                  <p><strong>Buyer:</strong> {contract && contract.buyer ? getFullBuyerName(contract.buyer) : 'n/a'}</p>
                </div>

                {/* Description */}
                <div className="col-12 mb-3">
                  <p><strong>Description:</strong> {contract ? contract.description : 'n/a'}</p>
                </div>

                {/* Details */}
                <div className="col-12 mb-3">
                  <p><strong>Details:</strong> {contract ? contract.details : 'n/a'}</p>
                </div>

                {/* Start Date */}
                <div className="col-12 mb-3">
                  <p><strong>Start Date:</strong> {contract ? isoToDate(contract.startDate) : 'n/a'}</p>
                </div>

                {/* End Date */}
                <div className="col-12 mb-3">
                  <p><strong>End Date:</strong> {contract ? isoToDate(contract.endDate) : 'n/a'}</p>
                </div>

                {/* Contract Amount */}
                <div className="col-12 mb-3">
                  <p><strong>Contract Amount:</strong> {contract && contract.contractAmount ? contract.contractAmount : 'n/a'}</p>
                </div>

                {/* Currency */}
                <div className="col-12 mb-3">
                  <p><strong>Currency:</strong> {contract && contract.currency ? contract.currency : 'n/a'}</p>
                </div>

                {/* Payment Terms */}
                <div className="col-12 mb-3">
                  <p><strong>Payment Terms:</strong> {contract && contract.paymentTerms ? contract.paymentTerms : 'n/a'}</p>
                </div>

                {/* Delivery Terms */}
                <div className="col-12 mb-3">
                  <p><strong>Delivery Terms:</strong> {contract && contract.deliveryTerms ? contract.deliveryTerms : 'n/a'}</p>
                </div>

                {/* Exporter Contact Information */}
                <div className="col-12 mb-3">
                  <p><strong>Exporter Contact Information:</strong> {contract && contract.exporterContactInfo ? contract.exporterContactInfo : 'n/a'}</p>
                </div>

                {/* Buyer Contact Information */}
                <div className="col-12 mb-3">
                  <p><strong>Buyer Contact Information:</strong> {contract && contract.buyerContactInfo ? contract.buyerContactInfo : 'n/a'}</p>
                </div>

                {/* Attachments */}
                <div className="col-12 mb-3">
                  <p><strong>Attachment:</strong>
                    {contract && contract.attachments ? (
                      <Link to={contract.attachments} target="_blank" rel="noopener noreferrer">
                        <span className="icon-eye1">
                          <em>View</em>
                        </span>
                      </Link>
                    ) : (
                      'n/a'
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default ContractView;