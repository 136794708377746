import React, { useEffect, useState, } from 'react';
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { toast } from 'react-toastify';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import useAuth from '../../hooks/useAuth';
import Navbar from '../../layout/Navbar';
import { baseURL, handleNotOkHttpResponse } from '../../utils/misc';
import SetAssignments from '../common/SetAssignments';
import Preloader from "../../components/common/Preloader";

function WorkFlowPermission() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [showPreloader, setShowPreloader] = useState(false);

  const {workflowStageId} = useParams();

  useEffect(() => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
  }
  }, []);

  useEffect(() => {
    if(workflowStageId) {
      fetchWorkflowStage();
      getAllRoles();
      getAllAlreadyAssignedRoles();
    }
  }, [workflowStageId]); // Add workflowProcessId to the dependency array

  useEffect(() => {
    removeSelectedItemsFromAll();
  }, [selectedRoles]);

  function removeSelectedItemsFromAll() {
    // Create an array of selected item values
    const selectedValues = selectedRoles.map(item => item.value);
  
    // Filter out items from allRoles that are not present in selectedValues
    const remainingItems = allRoles.filter(item => !selectedValues.includes(item.value));
  
    setAllRoles(remainingItems);
  }

  const fetchWorkflowStage = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }
    setShowPreloader(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        }
      };
      const response = await fetch(`${baseURL}/workflowstages/${workflowStageId}`, requestOptions); // Make sure the endpoint is correct
      if (!response.ok) {
        throw new Error('Error fetching workflow stage');
      }
      const data = await response.json();
      setCurrentStage(data); // Assuming the response contains the name field or whatever you want to display
    } catch (error) {
      toast.error("Error fetching workflow stage.");
      console.error("Error fetching workflow stage:", error);
    } finally {
      setShowPreloader(false);
    }
  };



const getAllRoles = async () => {
    if (!(user && user.user.token)) {
        navigate('/login');
        return;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${user.user.token}`,
        },
    };
    setShowPreloader(true);
    try {
        const response = await fetch(`${baseURL}/roles`, requestOptions);

        if (!response.ok) {
            throw new Error(`Error fetching roles: ${response.statusText}`);
        }

      const data = await response.json();

      // Filter out the current workflow stage
      const filteredRoles = data.filter(stage => stage.id !== workflowStageId);

      const formattedRoles = filteredRoles.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      // Set the filtered and formatted stages in the state
      setAllRoles(formattedRoles);
    } catch (error) {
      toast.error(`Error fetching roles: ${error.message}`);
      console.error("Error fetching all roles:", error);
    } finally {
      setShowPreloader(false);
    }
  };

  
const getAllAlreadyAssignedRoles = async () => {
  if (!(user && user.user.token)) {
      navigate('/login');
      return;
  }

  const requestOptions = {
      method: "GET",
      headers: {
          Authorization: `Bearer ${user.user.token}`,
      },
  };
  setShowPreloader(true);
  try {
      const response = await fetch(`${baseURL}/workflowpermissions/workflowStage/${workflowStageId}`, requestOptions);

      if (!response.ok) {
          throw new Error(`Error fetching already assigned roles.`);
      }

    const data = await response.json();

    const formattedRoles = data.map(({ role }) => ({
      value: role.id,
      label: role.name,
    }));

    // Set the filtered and formatted stages in the state
    setSelectedRoles(formattedRoles);
  } catch (error) {
    toast.error(`Error fetching roles: ${error.message}`);
    console.error("Error fetching all roles:", error);
  } finally {
    setShowPreloader(false);
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }
    setShowPreloader(true);
    try {
      const data = selectedRoles.map((stage) => stage.value);
      const response = await fetch(`${baseURL}/workflowpermissions/assign-roles/${workflowStageId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`, 
        },
        body: JSON.stringify(data), 
      });
  
      if (response.ok) {
        setShowPreloader(true);
        toast.success('Workflow permissions have been updated successfully.');
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      console.error('Error updating workflow permission:', error);
      toast.error('Error updating workflow permission.');
    } finally {
      setShowPreloader(false);
    }
  };
  
  
  


  return (
    <>
      <Preloader show={showPreloader} />
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Workflow Stages</li>
              <li className="breadcrumb-item active">Permissions</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          <div className="main-container">
            <div className="row gutter card">
              <div className="col-lg-12 mb10 mt-2">
                <div className="breadcrumb_content style2">
                  <h4 className="breadcrumb_title">
                  Workflow Permissions for Workflow Stage: {currentStage ? '\'' + currentStage.name +  '\'' : (<em>No current stage selected</em>)}
                  </h4>
                </div>
              </div>
              <div className="col-lg-12 card-body">
                <nav>
                  {(allRoles.length > 0) ? (<>
                  <SetAssignments
                    allItems={allRoles}
                    setAllItems={setAllRoles}
                    selectedItems={selectedRoles}
                    setSelectedItems={setSelectedRoles}
                    allItemsLabel="Available Roles"
                    selectedItemsLabel="Selected Roles"
                  />
                  <div className="my_profile_setting_input form-group">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary float-end"
                    >
                      Update Transition
                    </button>
                  </div></>) : (<em>No Role Exit for selection!</em>)}

                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkFlowPermission;
