import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../../../components/common/SideBarWrapper";
import Navbar from "../../../../../layout/Navbar";
import { useEffect, useState } from "react";
import { baseURL } from "../../../../../utils/misc";
import useAuth from "../../../../../hooks/useAuth";
import UserForm from "../UserForm";
import Footer from "../../../../../layout/Footer";

const EditUser = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        // Fetch workflow process data when the component mounts
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };

            try {
                const response = await fetch(`${baseURL}/Users/${userId}`, requestOptions);

                if (response.ok) {
                    const data = await response.json();
                    setUserData(data);
                } else {
                    console.error('Error fetching user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [userId]);



    return (<>


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item">User</li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <UserForm userData={userData} />
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}
            <Footer/>

        </div>
        {/* Page wrapper end */}
    </>);
}

export default EditUser;