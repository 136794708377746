import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate} from 'react-router-dom';
import Preloader from "../common/Preloader";
import {  fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getHarvestQuantityUnit } from "../../api/HarvestingRecords/getHarvestingRecords";
import FileInput from "../common/FileInput";

const SpoilageFormBasic = ({ spoilageRecord, setSpoilageRecord, handleSubmit, crop }) => {
  const { user } = useAuth();

  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [quantityUnitList, setQuantityUnitList] = useState([]);

  useEffect(() => {
    getSetUpData(); 
  }, []);

  useEffect(() => {
    if (spoilageRecord) {
      // setUserId(userData.id);
    }
  }, [spoilageRecord]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getHarvestQuantityUnit(user.user.token));
    } catch { } finally {
      setShowPreloader(false);
    }
  };


  const validateFields = () => {
    let retVal = true;

    if (!spoilageRecord.dateReported) {
      fieldWithIdRequired('dateReported');
      toast.error("Date Reported is required.");
      retVal = false;
    }
    if (!spoilageRecord.quantityAffected) {
      fieldWithIdRequired('quantityAffected');
      toast.error("Quantity Affected is required.");
      retVal = false;
    }

    if (!spoilageRecord.causes) {
      fieldWithIdRequired('causes');
      toast.error("Causes is required.");
      retVal = false;
    }


    if (!spoilageRecord.spoilageType) {
      fieldWithIdRequired('spoilageType');
      toast.error("Spoilage Type is required.");
      retVal = false;
    }

    if (!spoilageRecord.reportedBy) {
      fieldWithIdRequired('reportedBy');
      toast.error("Reported By is required.");
      retVal = false;
    }

    if (!spoilageRecord.quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    // environmental conditions data

    return retVal;
  };

  const handleChange = (field, value, required) => {
    if (typeof setSpoilageRecord === 'function') { // Check if setSpoilageRecord is a function
      const updatedItem = { ...spoilageRecord };
      updatedItem[field] = value;
      setSpoilageRecord(updatedItem);
      if (required) fieldWithIdRequired(field); //Show UI validation
    } else {
      console.error('setSpoilageRecord is not a function');
    }
  };


  return (
    <>
      <Preloader show={showPreloader} />
      <div className="row gutters">

        {spoilageRecord && spoilageRecord.id &&
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={spoilageRecord.code ? spoilageRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Date Reported *</label>
            <input
              type="date"
              className="form-control"
              id="dateReported"
              value={isoToDate(spoilageRecord && spoilageRecord.dateReported)}
              onChange={(e) => handleChange("dateReported", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Quantity Affected *</label>
            <input
              type="number"
              className="form-control"
              id="quantityAffected"
              placeholder="Enter the quantity affected..."
              value={spoilageRecord && spoilageRecord.quantityAffected}
              onChange={(e) => handleChange("quantityAffected", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Quantity Unit *</label>
            <select
              className="form-control form-control-lg"
              id="quantityUnit"
              value={spoilageRecord && spoilageRecord.quantityUnit}
              onChange={(e) => handleChange("quantityUnit", e.target.value, true)}
            >
              <option value="">Select Quantity Unit...</option>
              {quantityUnitList && quantityUnitList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="spoilageType">Spoilage Type *</label>
            <input
              type="text"
              className="form-control"
              id="spoilageType"
              placeholder="Enter the spoilageType..."
              value={spoilageRecord && spoilageRecord.spoilageType}
              onChange={(e) => handleChange("spoilageType", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="otherNames">Causes *</label>
            <textarea
              rows="2"
              className="form-control"
              id="causes"
              placeholder="Enter the causes..."
              value={spoilageRecord && spoilageRecord.causes}
              onChange={(e) => handleChange("causes", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="otherNames">Reported By *</label>
            <input
              type="text"
              className="form-control"
              id="reportedBy"
              placeholder="Enter the reported by..."
              value={spoilageRecord && spoilageRecord.reportedBy}
              onChange={(e) => handleChange("reportedBy", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="actionsTaken">Actions Taken</label>
            <input
              type="text"
              className="form-control"
              id="actionsTaken"
              placeholder="Enter the actionsTaken..."
              value={spoilageRecord && spoilageRecord.actionsTaken}
              onChange={(e) => handleChange("actionsTaken", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="inspectionDetails">Inspection Details</label>
            <textarea
              rows="2"
              className="form-control"
              id="inspectionDetails"
              placeholder="Enter the inspection Details..."
              value={spoilageRecord && spoilageRecord.inspectionDetails}
              onChange={(e) => handleChange("inspectionDetails", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea
              rows="3"
              className="form-control"
              id="comments"
              placeholder="Enter the comments..."
              value={spoilageRecord && spoilageRecord.comments}
              onChange={(e) => handleChange("comments", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="attachments">Attachment</label>
            <FileInput
              id="attachments"
              value={spoilageRecord && spoilageRecord.attachments}
              setValue={(value) => handleChange("attachments", value, false)}
              required={false}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if (validateFields()) { handleSubmit(e); } }}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default SpoilageFormBasic;