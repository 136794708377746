import { useEffect, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import { getAllNextWorkflowStages } from "../../../api/WorkflowStage/getAllNextWorkflowStages";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../utils/misc";
import { getUserFullName, isoToDateTime } from "../../../utils/helpers";

const ReferencedFormRejectionInstructions = ({ theWorkflowRecordActivity }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [workflowRejectionInstructions, setWorkflowRejectionInstructions] = useState([]);

  useEffect(() => {
    if(theWorkflowRecordActivity) fetchRejectionInstructions();
  }, []);

  useEffect(() => {}, [workflowRejectionInstructions]);

  const fetchRejectionInstructions = async () => { 
    if (!(user && user.user.token)) {
      navigate("/login");
      return;
    }
    setShowPreloader(true);

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };
      const response = await fetch(
        `${baseURL}/workflow-rejection-instructions/contract/${theWorkflowRecordActivity.workflowRecord.contractId}/targetStage/${theWorkflowRecordActivity.workflowStageId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(
          "Error fetching rejection instructions for the current workflow stage!"
        );
      }

      const data = await response.json();
      if (data) {
        setWorkflowRejectionInstructions(data);
      }
    } catch (error) {
      toast.error(
        "Error fetching rejection instructions for the current workflow stage!"
      );
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {workflowRejectionInstructions && workflowRejectionInstructions.length > 0 && (<>
        <div className="p-1 workflow-rejection-instructions">
        <div style={{color: "red"}}><em><strong>Rejection Instructions:</strong></em></div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <table id="data-table" className="table custom-table">
                <thead>
                  <tr>
                    <th style={{width: "6px"}}>No.</th>
                    <th>Instructions</th>
                    <th>From Stage</th>
                    <th>Given By</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {workflowRejectionInstructions && workflowRejectionInstructions.map((instructionData, index) => (
                    <tr key={instructionData.id}>
                      <td>{index + 1}</td>
                      <td>{instructionData.instructions}</td>
                      <td>{instructionData.sourceStage ? instructionData.sourceStage.order + " - " + instructionData.sourceStage.name : "-"}</td>
                      <td>{getUserFullName(instructionData.createdByUser)}</td>
                      <td>{isoToDateTime(instructionData.createdDate)}</td>
                    </tr>
                  ))}    
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </>)}
    </>
  );
};

export default ReferencedFormRejectionInstructions;
