import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import SpoilageFormBasic from "./SpoilageFormBasic";

const SpoilageForm = ({ spoilageRecord,setSpoilageRecord,crop }) => {
  const { user } = useAuth();
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [spoilageId, setSpoilageId] = useState(null);

  useEffect(() => {
    if (spoilageRecord) {
      setSpoilageId(spoilageRecord.id);
      // setUserId(userData.id);
    }
  }, [spoilageRecord]);

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      cropId:crop.id,
      dateReported: spoilageRecord.dateReported,
      quantityAffected: spoilageRecord.quantityAffected,
      quantityUnit: spoilageRecord.quantityUnit,
      causes: spoilageRecord.causes,
      comments: spoilageRecord.comments,
      reportedBy: spoilageRecord.reportedBy,
      spoilageType: spoilageRecord.spoilageType,
      actionsTaken: spoilageRecord.actionsTaken,
      inspectionDetails: spoilageRecord.inspectionDetails,
      attachments: spoilageRecord.attachments,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/spoilagereports`, requestOptions);

      if (response.ok) {
        toast.success("The Spoilage Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/spoilagerecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the Spoilage record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: spoilageId,
      cropId:crop.id,
      dateReported: spoilageRecord.dateReported,
      quantityAffected: spoilageRecord.quantityAffected,
      quantityUnit: spoilageRecord.quantityUnit,
      causes: spoilageRecord.causes,
      comments: spoilageRecord.comments,
      reportedBy: spoilageRecord.reportedBy,
      spoilageType: spoilageRecord.spoilageType,
      actionsTaken: spoilageRecord.actionsTaken,
      inspectionDetails: spoilageRecord.inspectionDetails,
      attachments: spoilageRecord.attachments,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/spoilagereports/${spoilageId}`, requestOptions);

      if (response.ok) {
        toast.success("The spoilage record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/spoilagerecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the spoilageing record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Spoilage Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/spoilagerecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <SpoilageFormBasic crop={crop} spoilageRecord={spoilageRecord} setSpoilageRecord={setSpoilageRecord} handleSubmit={spoilageId ? handleSubmitEdit : handleSubmitCreate} />
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default SpoilageForm;