import React, {
  useRef,
  useState,
} from 'react';

import {
	Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { baseURL } from '../../../utils/misc';
import { toast } from 'react-toastify';
import Footer from '../../../layout/Footer';
import { runningCompany } from '../../../appSetup';

function ForgotPassword() {
	const { dispatch } = useAuth();
	const usernameRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from.pathname || '/';
	const [resetMessage, setResetMessage] = useState(null);

	const handleSubmit = async (e) => {
	  e.preventDefault();

	  const username = usernameRef.current.value;

	  try {
		// Make the API call to send reset link
		const response = await fetch(`${baseURL}/Users/forgottenpassword/${username}`, {
		  method: 'PATCH',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  // Add any necessary body parameters
		});

		if (response.ok) {
			toast.success("Password reset code has been sent to your email address!")
			// navigate(`/resetpassword/${username}`)
		} else {
		  // Handle error cases, e.g., display an error message
		  setResetMessage('Failed to send reset code. Please try again.');
		}
	  } catch (error) {
		console.error('Error occurred:', error);
		setResetMessage('An error occurred. Please try again.');
	  }
	};

	return (
	  <div className="container">
		<form onSubmit={handleSubmit}>
		  <div className="row justify-content-md-center">
			<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
			  <div className="login-screen">
				<div className="login-box">
					<Link to="#" className="login-logo">
						<img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
					</Link>
				  <h5>Forgot your password!</h5>
				  <div className="form-group">
					<input type="text" ref={usernameRef} className="form-control" placeholder="Username..." required />
				  </div>
				  <div className="actions mt-4">
					<button type="submit" className="btn btn-primary">
					  Submit
					</button>
				  </div>
				  <br />
				  <Footer/>

				  {resetMessage && <p>{resetMessage}</p>}
				</div>
			  </div>
			</div>
		  </div>
		</form>
	  </div>
	);
  }
export default ForgotPassword;
