import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";

import { getPackagingRecordsByCropId } from "../../api/PackagingRecords/getPackagingList";
import PackagingTable from "./PackagingTable";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import PackagingBasicForm from "./PackagingBasicForm";
import { Modal } from "react-bootstrap";

function PackagingRecords({ crop }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [packagingRecords, setPackagingRecords] = useState([]);
    const [packagingRecord, setPackagingRecord] = useState();
    const [showModal, setShowModal] = useState(false)
    const [packagingId, setPackagingId] = useState(null);
    //env conditions data
    const [environmentalCondition, setEnvironmentalCondition] = useState();
    const [environmentalConditionId, setEnvironmentalConditionId] = useState('');


    useEffect(() => {
        if (!user || !user.user || !user.user.token) {
            toast.warn("You are not authorized to do this!");
            navigate("/");
            return;
        }

        if (crop) {
            getPackagingRecordsByCropId(user.user.token, crop.id).then(m => { setPackagingRecords(m); });
        }
    }, [crop, user, navigate]);

    useEffect(() => {
        if (packagingRecord) {
            setPackagingId(packagingRecord.id);

            if (packagingRecord.environmentalCondition) {
                setEnvironmentalConditionId(packagingRecord.environmentalCondition.id);
                setEnvironmentalCondition(packagingRecord.environmentalCondition);
            }
        }
    }, [packagingRecord]);

    const handleModalShow = (record = null) => {
        setPackagingRecord(record || {
            cropId: crop.id,
            date: '',
            environmentalCondition: {
                temperature: '',
                weatherConditions: '',
                humidity: '',
                rainfall: '',
                windSpeed: '',
                recordedDate: '',
                effects: ''
            },
            packagedBy: '',
            packagingMaterial: '',
            packagingType: '',
            quantityPackaged: null,
            quantityUnit: '',
            supervisedBy: '',
            qualityChecks: '',
            issuesIdentified: '',
            correctiveActions: '',
            labelling: '',
            packagingDetails: '',
        });
        setPackagingId(record ? record.id : null);
        setShowModal(true);
    };


    const handleModalClose = () => {
        setShowModal(false);
    };


    const handleSubmitCreate = async (e) => {
        // setShowPreloader(true);
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            return; // Ensure the function stops execution if the user is not authenticated
        }

        const data = {
            cropId: crop.id,
            date: packagingRecord.date,
            packagingMaterial: packagingRecord.packagingMaterial,
            packagingType: packagingRecord.packagingType,
            packagingDetails: packagingRecord.packagingDetails,
            supervisedBy: packagingRecord.supervisedBy,
            packagedBy: packagingRecord.packagedBy,
            correctiveActions: packagingRecord.correctiveActions,
            issuesIdentified: packagingRecord.issuesIdentified,
            qualityChecks: packagingRecord.qualityChecks,
            quantityPackaged: packagingRecord.quantityPackaged,
            quantityUnit: packagingRecord.quantityUnit,
            labelling: packagingRecord.labelling,
            environmentalCondition
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
            redirect: "follow",
        };
        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/packagingrecords`, requestOptions);

            if (response.ok) {
                toast.success("The Packaging Record has been successfully created.");
                const createdRecord = await response.json(); // Ensure this is awaited
                setPackagingRecords([...packagingRecords, createdRecord]);
                // // Fetch updated records list
                // getPackagingRecordsByCropId(user.user.token, crop.id).then(records => {
                //     setPackagingRecords(records);
                // });
                handleModalClose();
            } else {
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error creating the packagingrecords.");
        } finally {
            setShowPreloader(false);
        }
    };
    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            return; // Ensure the function stops execution if the user is not authenticated
        }

        const data = {
            id: packagingId,
            cropId: crop.id,
            date: packagingRecord.date,
            packagingMaterial: packagingRecord.packagingMaterial,
            packagingType: packagingRecord.packagingType,
            packagingDetails: packagingRecord.packagingDetails,
            supervisedBy: packagingRecord.supervisedBy,
            packagedBy: packagingRecord.packagedBy,
            correctiveActions: packagingRecord.correctiveActions,
            issuesIdentified: packagingRecord.issuesIdentified,
            qualityChecks: packagingRecord.qualityChecks,
            quantityPackaged: packagingRecord.quantityPackaged,
            quantityUnit: packagingRecord.quantityUnit,
            labelling: packagingRecord.labelling,
            environmentalConditionId,
            environmentalCondition
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
            redirect: "follow",
        };
        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/packagingrecords/${packagingId}`, requestOptions);

            if (response.ok) {
                toast.success("The Packaging Record has been successfully updated.");
                getPackagingRecordsByCropId(user.user.token, crop.id).then(records => {
                    setPackagingRecords(records);
                });
                handleModalClose();
            } else {
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error updating the packaging records.");
        } finally {
            setShowPreloader(false);
        }
    };


    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card" style={{ backgroundColor: "#eff1f5" }}>
                            <div className="card-header">
                                <div className="card-title">
                                    Packaging Records {crop ? " - " + getFullCropName(crop) : ""}
                                </div>
                                <Link
                                    to="#"
                                    onClick={() => handleModalShow()}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Packaging Record
                                </Link>
                            </div>
                            {packagingRecords && packagingRecords.length > 0 && (
                                <div>
                                    <PackagingTable
                                        packagingId={packagingId}
                                        packagingRecords={packagingRecords}
                                        packagingRecord={packagingRecord}
                                        setPackagingRecord={setPackagingRecord}
                                        crop={crop}
                                        allowEdit={true}
                                        handleSubmit={packagingId ? handleSubmitEdit : handleSubmitCreate}
                                        handleModalShow={handleModalShow}
                                        handleModalClose={handleModalClose}
                                        allowModalEdit={true}
                                    />
                                </div>
                            )}
                        </div>
                        <Modal size="lg" show={showModal} onHide={handleModalClose} id="firstOne">
                            <Modal.Header closeButton>
                                <Modal.Title>{packagingId ? "Edit Packaging Record" : "Add Packaging Record"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <PackagingBasicForm
                                    crop={crop}
                                    packagingRecord={packagingRecord}
                                    setPackagingRecord={setPackagingRecord}
                                    handleSubmit={packagingId ? handleSubmitEdit : handleSubmitCreate}
                                />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PackagingRecords;