import { useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React, { useEffect, useState } from "react";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import Preloader from "../../../components/common/Preloader";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import WorkflowRecordActivity from "../../../components/Contracts/Contracts";
import { getGetWorkflowRecordActivityById } from "../../../api/workflowRecordActivity/getGetWorkflowRecordActivityById";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const WorkflowUpdateOwnership = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { workflowRecordActivityId, workflowStageId } = useParams();
    const [showPreloader, setShowPreloader] = useState(false);
    const [theWorkflowRecordActivity, setTheWorkflowRecordActivity] = useState();
    const [fetchedUsers, setFetchedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedStages, setSelectedStages] = useState([]);
    const [selectedStageUserId, setSelectedStageUserId] = useState(null);
    const [selectedBackToPool, setSelectedBackToPool] = useState(null);
    const [showAssign, setShowOnAssign] = useState(false);
    const [showPool, setShowOnPool] = useState(false);
    const [theChecklists, setTheChecklists] = useState([]);
    const [currentStage, setCurrentStage] = useState(null);
    

    


    useEffect(() => {
        getTheWorkflowRecordActivity();
    }, []);

    useEffect(() => {
        if(workflowStageId) {
          fetchWorkflowStage();
        }
        console.log('workflowStageId', workflowStageId)
      }, [workflowStageId]);

    const getTheWorkflowRecordActivity = async () => {
        setShowPreloader(true);
        const theActivity = await getGetWorkflowRecordActivityById(
            user.user.token,
            workflowRecordActivityId
        );

        setTheWorkflowRecordActivity(theActivity);
        setShowPreloader(false);
    };

    useEffect(() => {
        fetchUsers(workflowStageId);
    }, [workflowStageId]);

    const fetchWorkflowStage = async () => {
        if (!(user && user.user.token)) {
          navigate('/login');
          return;
        }
        setShowPreloader(true);
        try {
          const requestOptions = {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${user.user.token}`,
            }
          };
          const response = await fetch(`${baseURL}/workflowstages/${workflowStageId}`, requestOptions); // Make sure the endpoint is correct
          if (!response.ok) {
            throw new Error('Error fetching workflow stage');
          }
          const data = await response.json();
          setCurrentStage(data); // Assuming the response contains the name field or whatever you want to display
        } catch (error) {
          toast.error("Error fetching workflow stage.");
          console.error("Error fetching workflow stage:", error);
        } finally {
          setShowPreloader(false);
        }
      };

      const fetchUsers = async (workflowStageId) => {
        try {
            const response = await fetch(`${baseURL}/Users/workflowStage/${workflowStageId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
    
            const usersWithOptions = data.map((userData) => ({
                value: userData.id,
                label: `${userData.firstName} ${userData.otherNames ? userData.otherNames + ' ' : ''}${userData.lastName}`,
            }));
    
            setFetchedUsers(usersWithOptions);
        } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Failed to fetch users');
        }
    };
    



    const handleShowOnAssign = (stageId) => {
        setShowOnAssign(true);
        setSelectedStageUserId(stageId);
    };

    const handleCloseOnAssign = () => {
        setShowOnAssign(false);
        setSelectedStageUserId(null);
    };

    const handleShowOnPool = (stageId) => {
        setShowOnPool(true);
        setSelectedBackToPool(stageId);
    };

    const handleCloseOnPool = () => {
        setShowOnPool(false);
        setSelectedBackToPool(null);
    };

    const handleAssignUser = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        try {

            if (!workflowRecordActivityId) {
                console.error('No selected Services Provider ID');
                return;
            }

            const data = {
                id: workflowRecordActivityId,
                workflowRecordId: theWorkflowRecordActivity.workflowRecord.id,
                workflowStageId:theWorkflowRecordActivity.workflowStageId,
                workflowProcessId:theWorkflowRecordActivity.workflowProcessId,
                ownerUserId: selectedUser.value
            }
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
                body: JSON.stringify(data)
            };

            const response = await fetch(`${baseURL}/workflowrecordactivities/${workflowRecordActivityId}`, requestOptions);
            if (response.ok) {
                toast.success("The workflow record stage has been assigned to the user successfully");
                handleCloseOnAssign();
                navigate("/bmrs")
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on assigning user to this workflow-stage:", error.message);
        }
    };

    const handlePool = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        try {

            if (!workflowRecordActivityId) {
                console.error('No selected Services Provider ID');
                return;
            }

            const data = {
                id: workflowRecordActivityId,
                workflowRecordId: theWorkflowRecordActivity.workflowRecord.id,
                workflowStageId:workflowStageId,
                workflowProcessId:theWorkflowRecordActivity.workflowProcessId,
                ownerUserId: null

            }
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow", 
                body: JSON.stringify(data)
            };

            const response = await fetch(`${baseURL}/workflowrecordactivities/${workflowRecordActivityId}`, requestOptions);
            if (response.ok) {
                toast.success("The workflow record activity has been pushed to the pool successfully");
                navigate("/bmrs")
                handleCloseOnPool();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on setting the owner user to null:", error.message);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />

            {/* Page wrapper start */}
            <div className="page-wrapper">
                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}
                {/* Page content start  */}
                <div className="page-content">
                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}
                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item">BMR</li>
                            <li className="breadcrumb-item">Update Ownership</li>
                        </ol>
                        <ul className="app-actions"></ul>
                    </div>
                    {/* Page header end */}
                    {/* Main container start */}
                    <div className="main-container">
                        <div className="row gutters">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title"> Update Ownership </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        {/* {theWorkflowRecordActivity && (
                                            <>
                                                <div className="row gutters">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 workflow-stage-product-info">
                                                        <BMRProductInfo theWorkflowRecordActivity={theWorkflowRecordActivity} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <hr /> */}
                                        <div className="row gutters">
                                            <div className="col-12">
                                                <label htmlFor="sendBackToPool" className="form-label">Send Back To Pool</label>
                                                <div className="">
                                                    <button onClick={handleShowOnPool} className="btn btn-primary mb-3 mt-3">Send Back To Pool</button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row gutters">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="assignedToUser">Assign to User</label>
                                                    <select
                                                        id="selectedUser"
                                                        className="form-control form-control-sm"
                                                        value={selectedUser ? selectedUser.id : ""}
                                                        onChange={(e) => {
                                                            const userId = e.target.value;
                                                            const selectedUserData = fetchedUsers.find(user => user.value === userId);
                                                            setSelectedUser(selectedUserData);
                                                        }}
                                                    >
                                                        <option value="">Select a User...</option>
                                                        {fetchedUsers && fetchedUsers.map((user) => (
                                                            <option key={user.value} value={user.value}>{user.label}</option>
                                                        ))}
                                                    </select>
                                                    {selectedUser && (
                                                        <button
                                                            className="btn btn-primary mt-3"
                                                            onClick={handleShowOnAssign}
                                                        >
                                                            Assign
                                                        </button>
                                                    )}
                                                    
                                                </div>

                                                {/* assign user to workflowstage */}
                                                <Modal show={showAssign} onHide={handleCloseOnAssign}>
                                                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                                        <Modal.Title style={{ color: 'white' }}>Assign BMR To Workflow Stage</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="mb-4">
                                                            Are you sure you want to this BMR to <strong>{selectedUser ? selectedUser.label : ""}</strong>?
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ borderTop: 'none' }}>
                                                        <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseOnAssign}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleAssignUser}>
                                                            Assign
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                {/* send back to pool */}
                                                <Modal show={showPool} onHide={handleCloseOnPool}>
                                                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                                        <Modal.Title style={{ color: 'white' }}>Send Back To Pool</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="mb-4">
                                                            Are you sure you want to send this workflow stage back to Pool?
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ borderTop: 'none' }}>
                                                        <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseOnPool}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handlePool}>
                                                            Send Back To Pool
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main container end */}
                </div>
                {/* Page content end */}
            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default WorkflowUpdateOwnership;
