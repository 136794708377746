import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired } from "../../utils/helpers";
import { getQuantityUnits } from "../../api/miscellaneous/getQuantityUnits";
import { getLifeSpan } from "../../api/CropRecords/getLifeSpan";

const CropForm = ({ field, cropData }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [variety, setVariety] = useState('');
  const [isOrganic, setIsOrganic] = useState('');
  const [purpose, setPurpose] = useState('');
  const [quantity, setQuantity] = useState('');
  const [lifespan, setLifeSpan] = useState('');
  const [lifespanUnit, setLifeSpanUnit] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [cropId, setCropId] = useState(null);
  const [quantityUnit, setQuantityUnit] = useState('');
  const [cropCode, setCropCode] = useState();

  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [lifeSpanUnitList, setLifeSpanUnitList] = useState([]);

  useEffect(() => {
    getQuantityUnits().then((data) => setQuantityUnitList(data));
    getLifeSpan().then((data) => setLifeSpanUnitList(data));
  }, []);

  useEffect(() => {
    if (cropId) {
      getCropData(cropId);
    }
  }, [cropId])

  useEffect(() => {
    if (cropData) {
      setCropId(cropData.id);
      setName(cropData.name);
      setQuantity(cropData.quantity);
      setLifeSpan(cropData.lifespan);
      setLifeSpanUnit(cropData.lifespanUnit);
      setQuantityUnit(cropData.quantityUnit);
      setIsOrganic(cropData.isOrganic);
      setVariety(cropData.variety);
      setPurpose(cropData.purpose);
      setCropCode(cropData.cropCode);
      // setUserId(userData.id);
    }
  }, [cropData]);

  const getCropData = async (cropId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/crops/${cropId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setName(data.name);
        setQuantity(data.quantity);
        setLifeSpan(data.lifespan);
        setLifeSpanUnit(data.lifespanUnit);
        setQuantityUnit(data.quantityUnit);
        setIsOrganic(data.isOrganic);
        setPurpose(data.purpose);
        setVariety(data.variety);
        setCropCode(data.cropCode);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching crop data.');
        console.error("Error fetching crop data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!name) {
      fieldWithIdRequired('name');
      toast.error("Crop Name is required.");
      retVal = false;
    }
    if (!quantity) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }
    if (!lifespan) {
      fieldWithIdRequired('lifespan');
      toast.error("Life Span is required.");
      retVal = false;
    }

    if (!lifespanUnit) {
      fieldWithIdRequired('lifespanUnit');
      toast.error("Lifespan Unit is required.");
      retVal = false;
    }
    if (!quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!variety) {
      fieldWithIdRequired('variety');
      toast.error("Variety is required.");
      retVal = false;
    }
    
    if (isOrganic === '') {
      fieldWithIdRequired('isOrganic');
      toast.error("Is Organic? is required.");
      retVal = false;
    }
    
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      fieldId: field.id,
      name: name,
      quantity: quantity,
      lifespan: lifespan,
      lifespanUnit: lifespanUnit,
      quantityUnit: quantityUnit,
      variety: variety,
      purpose: purpose,
      isOrganic: isOrganic,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/crops`, requestOptions);

      if (response.ok) {
        toast.success("The Field has been successfully created.");

        const data = response.json();

        navigate(`/crops/field/${field.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the field.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: cropId,
      fieldId: field.id,
      name: name,
      quantity: quantity,
      lifespan: lifespan,
      lifespanUnit: lifespanUnit,
      quantityUnit: quantityUnit,
      variety: variety,
      purpose: purpose,
      isOrganic: isOrganic,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/crops/${cropId}`, requestOptions);

      if (response.ok) {
        toast.success("The crop has been successfully updated.");
        setShowPreloader(true);
        navigate(`/crops/field/${field.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the crop.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">{cropData ? `Edit Crop - ${cropData.cropCode + ' - ' + cropData.name}` : 'Create Crop'}</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Field {field && field.fieldCode + ' - ' + field.name}</span>
                  <Link to={`/crops/field/${field && field.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {cropId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={cropCode ? cropCode : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Crop Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the name of the Crop..."
                      value={name}
                      onChange={(e) => {setName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="variety">Variety *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="variety"
                      placeholder="Enter the variety..."
                      value={variety}
                      onChange={(e) => {setVariety(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lifeSpan">Lifespan *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="lifeSpan"
                      placeholder="Enter the lifespan..."
                      value={lifespan}
                      onChange={(e) => {setLifeSpan(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lifeSpan">Lifespan Unit *</label>
                    <select
                      type="text"
                      className="form-control"
                      id="lifeSpanUnit"
                      placeholder="Enter the life span unit..."
                      value={lifespanUnit}
                      onChange={(e) => {setLifeSpanUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Lifespan Unit...</option>
                      {lifeSpanUnitList && lifeSpanUnitList.map((unit, index) => 
                        (<option key={index} value={unit}>{unit}</option>)
                      )}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Expected Harvest Quantity *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      placeholder="Enter the expected harvest quantity..."
                      value={quantity}
                      onChange={(e) => {setQuantity(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Quantity Unit *</label>
                    <select
                      className="form-control"
                      id="quantityUnit"
                      value={quantityUnit}
                      onChange={(e) => {setQuantityUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Unit...</option>
                      {quantityUnitList && quantityUnitList.map((unit, index) => 
                        (<option key={index} value={unit}>{unit}</option>)
                      )}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Is Organic? *</label>
                    <select
                      className="form-control"
                      id="isOrganic"
                      value={isOrganic}
                      onChange={(e) => {setIsOrganic(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select...</option>
                      <option value="true">Organic</option>
                      <option value="false">Not organic</option>
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="isOrganic">Is Organic?</label>
                    <div id="isOrganic">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="organic"
                          name="isOrganic"
                          value="true"
                          checked={isOrganic === 'true'}
                          onChange={(e) => setIsOrganic(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="organic">Organic</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="notOrganic"
                          name="isOrganic"
                          value="false"
                          checked={isOrganic === 'false'}
                          onChange={(e) => setIsOrganic(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="notOrganic">Not Organic</label>
                      </div>
                    </div>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div> */}
                </>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Purpose</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      id="purpose"
                      placeholder="Enter the purpose..."
                      value={purpose}
                      onChange={(e) => setPurpose(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(cropId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default CropForm;