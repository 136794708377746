import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getActivityTypes, getEquipmentUsedList, getQuantityUnit } from "../../api/PackHouseRecords/getPackHouseRecordsList";
import Select from "react-select";

const PackHouseForm = ({ packHouseRecords,crop}) => {
  const { user } = useAuth();

  const [date, setDate] = useState('');
  const [performedBy, setPerformedBy] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [quantity, setQuantity] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('');
  const [equipmentsUsed, setEquipmentsUsed] = useState([]);
  const [qualityChecks, setQualityChecks] = useState('');
  const [issuesIdentified, setIssuesIdentified] = useState('');
  const [correctiveActions, setCorrectiveActions] = useState('');
  const [activityType, setActivityType] = useState('');

  const [packHouseId, setPackHouseId] = useState(null);
  const [code, setCode] = useState('');

  const [activityTypeList, setActivityTypeList] = useState([]);
  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [equipmentUsedList, setEquipmentUsedList] = useState([]);


  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);


  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {

    if (packHouseId) {
      getPlantProtectionRecords(packHouseId);
    }
  }, [packHouseId])

  useEffect(() => {
    if (packHouseRecords) {
      setPackHouseId(packHouseRecords.id);
      setDate(packHouseRecords.date);
      setActivityType(packHouseRecords.activityType);
      setQuantity(packHouseRecords.quantity);
      setQuantityUnit(packHouseRecords.quantityUnit);
      setPerformedBy(packHouseRecords.performedBy);
      setSupervisedBy(packHouseRecords.supervisedBy);
      setQualityChecks(packHouseRecords.qualityChecks);
      setIssuesIdentified(packHouseRecords.issuesIdentified);
      setCorrectiveActions(packHouseRecords.correctiveActions);
      setCode(packHouseRecords.code);
      setEquipmentsUsed(
        packHouseRecords.equipmentsUsed
          ? packHouseRecords.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
          : []
      );
    }
  }, [packHouseRecords]);
  
  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setActivityTypeList(await getActivityTypes(user.user.token));
      setQuantityUnitList(await getQuantityUnit(user.user.token));
      setEquipmentUsedList(await getEquipmentUsedList(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getPlantProtectionRecords = async (packHouseId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts 
    fetch(`${baseURL}/packhouserecords/${packHouseId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setPackHouseId(data.id);
        setDate(data.date);
        setActivityType(data.activityType);
        setQuantity(data.quantity);
        setQuantityUnit(data.quantityUnit);
        setPerformedBy(data.performedBy);
        setSupervisedBy(data.supervisedBy);
        setQualityChecks(data.qualityChecks);
        setIssuesIdentified(data.issuesIdentified);
        setCorrectiveActions(data.correctiveActions);
        setCode(data.code);
        setEquipmentsUsed(
          data.equipmentsUsed
            ? data.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
            : []
        );

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching plant protection records data.');
        console.error("Error fetching plant protection records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!activityType) {
      fieldWithIdRequired('activityType');
      toast.error("Activity Type is required.");
      retVal = false;
    }

    if (!quantity) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }

    if (!quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!performedBy) {
      fieldWithIdRequired('performedBy');
      toast.error("Performed By is required.");
      retVal = false;
    }


    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      activityType: activityType,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantity: quantity,
      quantityUnit:quantityUnit,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/packhouserecords`, requestOptions);

      if (response.ok) {
        toast.success("The Pack House Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/packhouserecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the packhouserecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: packHouseId,
      cropId:crop.id,
      date: date,
      activityType: activityType,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantity: quantity,
      quantityUnit:quantityUnit,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/packhouserecords/${packHouseId}`, requestOptions);

      if (response.ok) {
        toast.success("The pack house record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/packhouserecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the pack house record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const equipmentOptions = equipmentUsedList.map(equip => ({
    value: equip,
    label: equip
  }));

  const handleChange = (selectedOptions) => {
    setEquipmentsUsed(selectedOptions || []);
  };


  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Pack House Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/packhouserecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {packHouseId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code ? code : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantity">Quantity *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      placeholder="Enter the quantity..."
                      value={quantity}
                      onChange={(e) => {setQuantity(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={quantityUnit}
                      onChange={(e) => { setQuantityUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Quantity Type...</option>
                      {quantityUnitList && quantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="nameOfProduct">Activity Type *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={activityType}
                      onChange={(e) => { setActivityType(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Activity Type...</option>
                      {activityTypeList && activityTypeList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Equipment Used</label>
                    <Select
                      id="equipmentSelection"
                      options={equipmentOptions}
                      onChange={handleChange}
                      placeholder="Select Equipment..."
                      isClearable
                      isMulti
                      value={equipmentsUsed}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="appliedBy">Performed By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the performed by..."
                      value={performedBy}
                      onChange={(e) => {setPerformedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Quality Checks</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="qualityChecks"
                      placeholder="Enter the quality checks..."
                      value={qualityChecks}
                      onChange={(e) => setQualityChecks(e.target.value)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Issues Identified</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="issuesIdentified"
                      placeholder="Enter the issues identified..."
                      value={issuesIdentified}
                      onChange={(e) => setIssuesIdentified(e.target.value)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Corrective Actions</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="correctiveActions"
                      placeholder="Enter the corrective actions..."
                      value={correctiveActions}
                      onChange={(e) => setCorrectiveActions(e.target.value)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(packHouseId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default PackHouseForm;