import { useEffect, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import CardBox from "../../../components/common/CardBox";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../../utils/daynamicNavigation";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Preloader from "../../../components/common/Preloader";

function TableWorkFlowStatuses({workflowId}) {
    const { user } = useAuth();
    const [workflowstatuses, setWorkFlowStatuses] = useState([]);
    const [totalActive, setTotalActive] = useState(0);
    const [totalDiActive, setTotalDiActive] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [selectedStatusId, setSelectedStatusId] = useState(null);
    const [showPreloader, setShowPreloader] = useState(false);

    const profileMenuItems = [
        {
            id: 1,
            name: "Edit",
            icon: "flaticon-transfer-1",
            routerPath: "/workflowstatus/edit",
        },
        {
            id: 2,
            name: "Delete",
            icon: "flaticon-transfer-1",
            onClick: (status) => {
                handleShow(status.id)
            },
        }
    ];


    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        const fetchWorkflowStatuses = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/workflowstatuses/workflow/${workflowId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching workflow status');
                }
                const data = await response.json();
                setWorkFlowStatuses(data);
            } catch (error) {
                toast.error("Error fetching workflow statuses.");
                console.error("Error fetching workflow statuses:", error);
            } finally {
                setShowPreloader(false);
            }
          };          
        fetchWorkflowStatuses();
    }, [workflowId]);
    

    useEffect(() => {
        if (workflowstatuses && workflowstatuses.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                });
            });
        }
    }, [workflowstatuses]);

    const handleShow = (stageId) => {
        setShow(true);
        setSelectedStatusId(stageId);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedStatusId(null);
    };

    const handleRemoveStatus = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        setShowPreloader(true);
        try {

            if (!selectedStatusId) {
                console.error('No selected Services Provider ID');
                return;
            }

            const data = {
                id: selectedStatusId,
            }
            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
                body: JSON.stringify(data)
            };

            const response = await fetch(`${baseURL}/workflowstatuses/${selectedStatusId}`, requestOptions);
            if (response.ok) {
                toast.success("Workflow status removed successfully");
                setShowPreloader(true);
                handleClose();
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing workflow status:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">

                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of WorkFlow Statuses</div>
                                <Link
                                    to={`/workflowstatus/create?workflow=${workflowId}`}
                                    className="btn btn-primary float-end"
                                >
                                    Add Workflow Status
                                </Link>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        {workflowstatuses && workflowstatuses.length > 0 ? (
                                            <div className="table-responsive">
                                                <table id="data-table" className="table custom-table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Order</th>
                                                            <th><div className="float-end">Actions</div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {workflowstatuses?.map((status) => (
                                                            <tr key={status.id}>
                                                                <td>{status.name}</td>
                                                                <td>{status.order}</td>
                                                                <td className="">
                                                                    <ul>
                                                                        <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                                    role="button"
                                                                                    href="#"
                                                                                    id={"dropdownMenuLink" + status.id}
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    Actions <i className="fa fa-caret-down"></i>
                                                                                </a>
                                                                                <ul
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby={"dropdownMenuLink" + status.id}
                                                                                >
                                                                                    {profileMenuItems.map((actionMenuItem) => (
                                                                                        <li key={status.id + '-' + actionMenuItem.id}>
                                                                                            {actionMenuItem.onClick ? (
                                                                                                <a
                                                                                                    href="#"
                                                                                                    className="dropdown-item"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                                        actionMenuItem.onClick(status);
                                                                                                    }}
                                                                                                    style={
                                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                            ? { color: "#ff5a5f" }
                                                                                                            : undefined
                                                                                                    }
                                                                                                >
                                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                                </a>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to={actionMenuItem.routerPath + "/" + status.id} // Changed href to to
                                                                                                    className="dropdown-item"
                                                                                                    style={
                                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                            ? { color: "#ff5a5f" }
                                                                                                            : undefined
                                                                                                    }
                                                                                                >
                                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                                </Link>
                                                                                            )}
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <Modal show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                                        <Modal.Title style={{ color: 'white' }}>Delete Workflow Status</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="mb-4">
                                                            Are you sure you want to delete this workflowstatus?
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ borderTop: 'none' }}>
                                                        <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveStatus}>
                                                            Delete
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Row start */}

                {/* Row end */}

            </div>

        </>
    );
}

export default TableWorkFlowStatuses;