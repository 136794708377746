import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Preloader from "../common/Preloader";
import { getGetAllBuyers } from "../../api/Buyers/getGetAllBuyers";
import { getAllCrops } from "../../api/CropRecords/getAllCrops";
import { fieldRequired, fieldWithIdRequired, getFullCropName, isoToDate } from "../../utils/helpers";
import { getGetAllCurrencies } from "../../api/Currencies/getGetAllCurrencies";
import FileInput from "../common/FileInput";

const InitiateContractForm = ({contract, setContract, submitFunction}) => {
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [attachments, setAttachments] = useState();
  
  const [cropList, setCropList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
      getSetupData();
  }, [])

  const getSetupData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
    }

    // Fetch user data when the component mounts
    setShowPreloader(true);
    getGetAllBuyers(user.user.token).then(m => setBuyerList(m));
    getAllCrops(user.user.token).then(m => setCropList(m));
    getGetAllCurrencies(user.user.token).then(m => setCurrencyList(m));
    setShowPreloader(false);
  };


  const validateFields = () => {
    let retVal = true;
    if (!contract.cropId) {
      fieldWithIdRequired('cropId');
      toast.error("The Crop is required.");
      retVal = false;
    }
    if (!contract.buyerId) {
      fieldWithIdRequired('buyerId');
      toast.error("The Buyer is required.");
      retVal = false;
    }
    if (!contract.description) {
      fieldWithIdRequired('description');
      toast.error("Contract Description is required.");
      retVal = false;
    }

    if (!contract.startDate) {
      fieldWithIdRequired('startDate');
      toast.error("Start Date is required.");
      retVal = false;
    }

    if (!contract.endDate) {
      fieldWithIdRequired('startDate');
      toast.error("End Date is required.");
      retVal = false;
    }
    return retVal;
  };

  
  
  const handleChange = (field, value, required) => {
    const updatedItem = { ...contract }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setContract(updatedItem); // Update the state with the modified copy
    if(required) fieldWithIdRequired(field); //Show UI validation
  };
    
  return (
    <>
    <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">{contract && contract.id ? 'Update Contract - ' + contract.contractCode : 'Initiate New Contract'}</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="cropId">Crop *</label>
                    <select
                      type="text"
                      className="form-control"
                      id="cropId"
                      value={contract && contract.cropId}
                      onChange={(e) => handleChange("cropId", e.target.value, true)}
                    >
                      <option value="">Select Crop...</option>
                      {cropList && cropList.map((item, index) => 
                        (<option key={index} value={item.id}>{item.cropCode + ' - ' + item.name}</option>)
                      )}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="buyerId">Buyer *</label>
                    <select
                      type="text"
                      className="form-control"
                      id="buyerId"
                      value={contract && contract.buyerId}
                      onChange={(e) => handleChange("buyerId", e.target.value, true)}
                    >
                      <option value="">Select Buyer...</option>
                      {buyerList && buyerList.map((item, index) => 
                        (<option key={index} value={item.id}>{item.code + ' - ' + item.businessName}</option>)
                      )}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Description *</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="description"
                      placeholder="Enter the Description..."
                      value={contract && contract.description}
                      onChange={(e) => handleChange("description", e.target.value, true)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="productName">Details</label>
                    <textarea
                      className="form-control"
                      id="details"
                      placeholder="Enter Details..."
                      value={contract && contract.details}
                      onChange={(e) => handleChange("details", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter the Start Date..."
                      value={isoToDate(contract && contract.startDate)}
                      onChange={(e) => handleChange("startDate", e.target.value, true)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="endDate">End Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter the End Date..."
                      value={isoToDate(contract && contract.endDate)}
                      onChange={(e) => handleChange("endDate", e.target.value, true)}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Contract Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="contractAmount"
                      placeholder="Enter the Contract Amount..."
                      value={contract && contract.contractAmount}
                      onChange={(e) => handleChange("contractAmount", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="currency">Currency</label>
                    <select
                      type="text"
                      className="form-control"
                      id="currency"
                      value={contract && contract.currency}
                      onChange={(e) => handleChange("currency", e.target.value, false)}
                    >
                      <option value="">Select Currency...</option>
                      {currencyList && currencyList.map((item, index) => 
                        (<option key={index} value={item.code}>{item.name + ' (' + item.name + ')'}</option>)
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="paymentTerms">Payment Terms</label>
                    <textarea
                      className="form-control"
                      id="paymentTerms"
                      placeholder="Enter Payment Terms..."
                      value={contract && contract.paymentTerms}
                      onChange={(e) => handleChange("paymentTerms", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="deliveryTerms">Delivery Terms</label>
                    <textarea
                      className="form-control"
                      id="deliveryTerms"
                      placeholder="Enter Deliver Terms..."
                      value={contract && contract.deliveryTerms}
                      onChange={(e) => handleChange("deliveryTerms", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="exporterContactInfo">Exporter Contact Information</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exporterContactInfo"
                      placeholder="Enter the Exporter Contact Information..."
                      value={contract && contract.exporterContactInfo}
                      onChange={(e) => handleChange("exporterContactInfo", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="buyerContactInfo">Buyer Contact Information</label>
                    <input
                      type="text"
                      className="form-control"
                      id="buyerContactInfo"
                      placeholder="Enter the Buyer Contact Information..."
                      value={contract && contract.buyerContactInfo}
                      onChange={(e) => handleChange("buyerContactInfo", e.target.value, false)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="attachments">Attachment</label>
                    <FileInput id={"attachments"} 
                      value={attachments} 
                      setValue={setAttachments} 
                      required={false} 
                      className ="form-control"
                    />
                  </div>
                </div>
              </div>
              
            </div>
            {!contract || !contract.id ?
              <div className="card-footer">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                    <div className="float-end">
                      <button type="button" className="btn btn-primary float-end" onClick={(e) => {if(validateFields()) submitFunction(e);}}>
                        Initiate Contract
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              : ""
            }
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}
 
export default InitiateContractForm;