import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { openWorkflowDocument } from "../../api/workflowRecordActivity/openWorkflowDocument";

const AttentionRequiredContracts = () => {
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const { user } = useAuth();
  const [workflowRecordActivities, setWorkflowRecordActivities] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  
  useEffect(() => {
  }, [workflowRecordActivities]);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
    }

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    };

    setShowPreloader(true);

    // Fetch user data when the component mounts
    fetch(`${baseURL}/workflowrecordactivities/userspool`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("Error fetching Pool Workflow Records.");
        }
      })
      .then((data) => {
        // Set the useState valuations with the fetched data
        setWorkflowRecordActivities(data);
      })
      .catch((error) => {
        toast.error("Error fetching Pool Workflow Records.");
      })
      .finally((done) => {
        setShowPreloader(false);
      });
  };

  const openDocument = async (workflowRecordActivityId, workflowRecordId, workflowStageId, contractId) => {
    console.log(workflowRecordActivityId, workflowRecordId, workflowStageId, contractId);
    if (!(user && user.user.token)) {
      navigate("/login");
    }
    
    setShowPreloader(true);
    setShowPreloader(true);
    await openWorkflowDocument(user.user.token, navigate, workflowRecordActivityId, workflowRecordId, workflowStageId, contractId);
    setShowPreloader(false);
    
    // const retVal = await updateWorkflowRecordActivityOwner(user.user.token, workflowRecordActivityId, user.user.id);
    // if(retVal) {
    //   setShowPreloader(true);
    //   const retValOpenDoc = await openWorkflowDocument(user.user.token, navigate, workflowRecordActivityId, workflowRecordId, workflowStageId, contractId);
    //   setShowPreloader(false);
    //   return;
    // } 
    // else {
    //   //Document was not successfully owned by the user
    // }
    // setShowPreloader(false);
  };

  const tableHeaders = [
    "Contract Code",
    "Crop",
    "Buyer",
    "Process",
    "Stage",
    "Actions",
  ]; 

  return (
    <>
      {/* Row start */}
      <Preloader show={showPreloader} />
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                Contracts Requiring Your Attention ({user && user.user ? user.user.username : ""})
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="table-responsive">
                    <table id="data-table" className="table custom-table">
                      <thead className="thead-dark">
                        <tr>
                          {tableHeaders.map(header => (
                            <th key={header}>
                              {header === "Actions" ? (<div className="float-end">{header}</div>) : header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {workflowRecordActivities && workflowRecordActivities.length > 0 ? workflowRecordActivities.map((activity) => (
                          <tr key={activity.id}>
                            <td>{activity.workflowRecord.contract.contractCode}</td>
                            <td>{activity.workflowRecord.contract.crop.cropCode + ' - ' + activity.workflowRecord.contract.crop.name}</td>
                            <td>{activity.workflowRecord.contract.buyer.code + ' - ' + activity.workflowRecord.contract.buyer.businessName}</td>
                            <td>{activity.workflowProcess.name}</td>
                            <td>{activity.workflowStage.name}</td>
                            <td className="">
                              <button
                                type="button"
                                onClick={() => openDocument(activity.id, activity.workflowRecord.id, activity.workflowStage.id, activity.workflowRecord.contract.id)}
                                className="btn btn-secondary"
                              >
                                Open
                              </button>
                            </td>
                          </tr>
                        )) : (<tr><td colSpan={tableHeaders.length} className="text-center"><em>No data available!</em></td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default AttentionRequiredContracts;
