import React, { useEffect, useState, } from 'react';
import { Link, useNavigate, useParams, } from 'react-router-dom';
import { toast } from 'react-toastify';
import Preloader from "../../../../components/common/Preloader";
import SideBarWrapper from '../../../../components/common/SideBarWrapper';
import useAuth from '../../../../hooks/useAuth';
import Navbar from '../../../../layout/Navbar';
import { baseURL } from '../../../../utils/misc';
import SetAssignments from '../../SetAssignments';
import { getUserFullNameWithUsername } from '../../../../utils/helpers';

function UserRoles() {
    const { userId } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [theUser, setTheUser] = useState(null);
    const [allRoles, setAllRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [showPreloader, setShowPreloader] = useState(false);
    const EXCLUDED_ROLE_ID = '6a8a9ac0-28d1-4256-bfd0-e57bf1912db0';

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }

        getAllRoles();
    }, []);

    const getUserRoles = async (userId) => {
        let existingRoles = [];
        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }

        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            },
        };
        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/Users/${userId}`, requestOptions);

            if (!response.ok) {
                throw new Error(`Error fetching roles: ${response.statusText}`);
            }
            const user = await response.json();
            const fetchedUser = user ? user : null;
            setTheUser(fetchedUser);

            if (user) existingRoles = user.roles;


        } catch (error) {
          toast.error(`Error fetching user's roles!}`);
        } finally {
            setShowPreloader(false);
            return existingRoles;
        }
    }


    const getAllRoles = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }
    
        let existingRoles = await getUserRoles(userId);
    
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            },
        };
        setShowPreloader(true);
    
        try {
            const response = await fetch(`${baseURL}/roles/admin`, requestOptions);
    
            if (!response.ok) {
                throw new Error(`Error fetching roles: ${response.statusText}`);
            }
    
            const data = await response.json(); 
    
            // Exclude role with specific ID and filter roles to exclude those already assigned
            const availableRoles = data
                .filter(({ id }) => 
                    id !== EXCLUDED_ROLE_ID && 
                    !existingRoles.some((role) => role?.id === id)
                )
                .map(({ id, name }) => ({
                    value: id,
                    label: name,
                }));
    
            // Filter out roles with null id from existingRoles
            const filteredExistingRoles = existingRoles
                .filter((role) => role && role?.id !== null)
                .map(({ id, name }) => ({
                    value: id,
                    label: name,
                }));
    
            // Set the available roles
            setAllRoles(availableRoles);
    
            // Set the selected roles (existing roles without null id)
            setSelectedRoles(filteredExistingRoles);
    
        } catch (error) {
            toast.error(`Error fetching roles: ${error.message}`);
            console.error("Error fetching all roles:", error);
        } finally {
            setShowPreloader(false);
        }
    };
    




      const handleSubmit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }
        setShowPreloader(true);
        try {
            const data = selectedRoles.map((role) => role.value);

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "text/plain",
                    Authorization: `Bearer ${user.user.token}`,
                },
                body: JSON.stringify(data),
                redirect: "follow",
            };

            console.log('data >> : ', data);

            const response = await fetch(`${baseURL}/userroles/assign-roles/${userId}`, requestOptions);
            console.log('userId', userId);
            console.log('response >>>:', response);

            if (response.ok) {
                // If the request is successful, update the selectedRoles state with the submitted roles
                setSelectedRoles([...selectedRoles]);
                setShowPreloader(true);
                toast.success("Role has been Assigned to User successfully.");
            } else {
                const errorData = await response.text();
                console.error("Error assigning user role:", errorData);
            }
        } catch (error) {
            toast.error("Error updating user.");
            console.error("Error:", error);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Users</li>
                            <li className="breadcrumb-item active">User Role</li>
                        </ol>

                        <ul className="app-actions">

                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">

                        {/* Row end */}

                        <div className="row gutter card">
                            <div className="col-lg-12 mb10 mt-2">
                                <div className="breadcrumb_content style2">
                                    <h4 className="breadcrumb_title">
                                        Assign & Manage User Roles for <span>'{getUserFullNameWithUsername(theUser)}'</span>
                                    </h4>
                                </div>
                            </div>
                            <div className="col-lg-12 card-body">
                                <nav>
                                    <SetAssignments
                                        allItems={allRoles}
                                        setAllItems={setAllRoles}
                                        selectedItems={selectedRoles}
                                        setSelectedItems={setSelectedRoles}
                                        allItemsLabel="Available Roles"
                                        selectedItemsLabel="Selected Roles"
                                    />
                                    <div className="my_profile_setting_input form-group">
                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            className="btn btn-primary float-end"
                                        >
                                            Update Roles
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>


                        {/* Row start */}

                        {/* Row end */}

                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}

            </div>
            {/* Page wrapper end */}

        </>
    );
}

export default UserRoles;
