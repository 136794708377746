import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getManufacturingQuantityUnit } from "../../api/ManufacturingRecords/getManufacturingList";
import { getLifespanUnits } from "../../api/miscellaneous/getQuantityUnits";
import ManufacturingProcessListing from "./ManufacturingProcessRecords/ManufacturingProcessListing";

const ManufacturingForm = ({ manufacturingRecords,crop }) => {
  const { user } = useAuth();

  const [date, setDate] = useState('');
  const [performedBy, setPerformedBy] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const [quantityProduced, setQuantityProduced] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('');
  const [productName, setProductName] = useState('');
  const [gtin, setGtin] = useState('');
  const [packagingDetails, setPackagingDetails] = useState('');
  const [shelfLife, setShelfLife] = useState('');
  const [shelfLifeUnit, setShelfLifeUnit] = useState();
  const [equipmentUsed, setEquipmentUsed] = useState('');
  const [fdaRegistrationCode, setFdaRegistrationCode] = useState('');
  const [qualityChecks, setQualityChecks] = useState('');
  const [issuesIdentified, setIssuesIdentified] = useState('');
  const [correctiveActions, setCorrectiveActions] = useState('');
  const [code, setCode] = useState('');
  const [manufacturingId, setManufacturingId] = useState(null);

  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [lifespanUnitList, setLifespanUnitList] = useState([]);

  //env conditions data

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {

    if (manufacturingId) {
      getPlantProtectionRecords(manufacturingId);
    }
  }, [manufacturingId])

  useEffect(() => {
    if (manufacturingRecords) {
      setManufacturingId(manufacturingRecords.id);
      setDate(manufacturingRecords.date);
      setProductName(manufacturingRecords.productName);
      setBatchNumber(manufacturingRecords.batchNumber);
      setQuantityProduced(manufacturingRecords.quantityProduced);
      setShelfLife(manufacturingRecords.shelfLife);
      setShelfLifeUnit(manufacturingRecords.shelfLifeUnit);
      setQuantityUnit(manufacturingRecords.quantityUnit);
      setGtin(manufacturingRecords.gtin);
      setPerformedBy(manufacturingRecords.performedBy);
      setPackagingDetails(manufacturingRecords.packagingDetails);
      setSupervisedBy(manufacturingRecords.supervisedBy);
      setEquipmentUsed(manufacturingRecords.equipmentUsed);
      setQualityChecks(manufacturingRecords.qualityChecks);
      setIssuesIdentified(manufacturingRecords.issuesIdentified);
      setCorrectiveActions(manufacturingRecords.correctiveActions);
      setFdaRegistrationCode(manufacturingRecords.fdaRegistrationCode);
      setCode(manufacturingRecords.code);
      // setUserId(userData.id);
    }
  }, [manufacturingRecords]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getManufacturingQuantityUnit(user.user.token));
      setLifespanUnitList(await getLifespanUnits(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getPlantProtectionRecords = async (manufacturingId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/manufacturingrecords/${manufacturingId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setDate(data.date);
        setEquipmentUsed(data.equipmentUsed);
        setPerformedBy(data.performedBy);
        setSupervisedBy(data.supervisedBy);
        setQualityChecks(data.qualityChecks);
        setGtin(data.gtin);
        setIssuesIdentified(data.issuesIdentified);
        setCorrectiveActions(data.correctiveActions);
        setQuantityProduced(data.quantityProduced);
        setQuantityUnit(data.quantityUnit);
        setProductName(data.productName);
        setBatchNumber(data.batchNumber);
        setShelfLife(data.shelfLife);
        setShelfLifeUnit(data.shelfLifeUnit);
        setPackagingDetails(data.packagingDetails);
        setFdaRegistrationCode(data.fdaRegistrationCode);
        setCode(data.code);


      })
      .catch((error) => {
        toast.error('Error fetching manufacturing records data.');
        console.error("Error fetching manufacturing records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }

    if (!batchNumber) {
      fieldWithIdRequired('batchNumber');
      toast.error("Batch Number is required.");
      retVal = false;
    }

    if (!gtin) {
      fieldWithIdRequired('gtin');
      toast.error("Gtin is required.");
      retVal = false;
    }

    if (!productName) {
      fieldWithIdRequired('productName');
      toast.error("Product Name is required.");
      retVal = false;
    }

    if (!shelfLife) {
      fieldWithIdRequired('shelfLife');
      toast.error("Shelf Life is required.");
      retVal = false;
    }

    if (!shelfLifeUnit) {
      fieldWithIdRequired('shelfLifeUnit');
      toast.error("Shelf Life Unit is required.");
      retVal = false;
    }

    if (!equipmentUsed) {
      fieldWithIdRequired('equipmentsUsed');
      toast.error("Equipments Used is required.");
      retVal = false;
    }

    if (!quantityProduced) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }

    if (!quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!performedBy) {
      fieldWithIdRequired('performedBy');
      toast.error("Performed By is required.");
      retVal = false;
    }


    // environmental conditions data


    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      batchNumber: batchNumber,
      productName: productName,
      shelfLife: shelfLife,
      shelfLifeUnit: shelfLifeUnit,
      gtin:gtin,
      packagingDetails: packagingDetails,
      fdaRegistrationCode: fdaRegistrationCode,
      equipmentUsed: equipmentUsed,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantityProduced: quantityProduced,
      quantityUnit:quantityUnit,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/manufacturingrecords`, requestOptions);

      if (response.ok) {
        const responseData = await response.json();
        setManufacturingId(responseData.id); // Ensure correct ID is being set
        toast.success("The Manufacturing Record has been successfully created.");
        setShowPreloader(true);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the manufacturingrecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: manufacturingId,
      cropId:crop.id,
      date: date,
      batchNumber: batchNumber,
      productName: productName,
      gtin:gtin,
      shelfLife: shelfLife,
      shelfLifeUnit: shelfLifeUnit,
      packagingDetails: packagingDetails,
      fdaRegistrationCode: fdaRegistrationCode,
      equipmentUsed: equipmentUsed,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      qualityChecks: qualityChecks,
      quantityProduced: quantityProduced,
      quantityUnit:quantityUnit,
      // manufacturingProcess
      
    };

    console.log("data >>:", data);

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/manufacturingrecords/${manufacturingId}`, requestOptions);

      if (response.ok) {
        toast.success("The manufacturing record has been successfully updated.");
        setShowPreloader(true);
        //navigate(`/manufacturingrecords/crop/${cropId}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the manufacturing record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Manufacturing Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/manufacturingrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

              {manufacturingId && 
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="code"
                      value={code ? code : '--'}
                      readOnly
                    />
                  </div>
                </div>
              }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Product Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter the product name..."
                      id="productName"
                      value={productName}
                      onChange={(e) => {setProductName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">GTIN / GTN *</label>
                    <input
                      type="text"
                      placeholder="Enter the gtin or gtn..."
                      className="form-control"
                      id="gtin"
                      value={gtin}
                      onChange={(e) => {setGtin(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Batch Number *</label>
                    <input
                      type="text"
                      placeholder="Enter the batch number..."
                      className="form-control"
                      id="batchNumber"
                      value={batchNumber}
                      onChange={(e) => {setBatchNumber(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantity">Quantity Produced *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantityProduced"
                      placeholder="Enter the quantity produced..."
                      value={quantityProduced}
                      onChange={(e) => {setQuantityProduced(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={quantityUnit}
                      onChange={(e) => { setQuantityUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Quantity Type...</option>
                      {quantityUnitList && quantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Equipment Used</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="equipmentUsed"
                      placeholder="Enter the equipment used..."
                      value={equipmentUsed}
                      onChange={(e) => setEquipmentUsed(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="appliedBy">Performed By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the performed by..."
                      value={performedBy}
                      onChange={(e) => {setPerformedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Quality Checks</label>
                    <input
                      type="text"
                      className="form-control"
                      id="qualityChecks"
                      placeholder="Enter the quality checks..."
                      value={qualityChecks}
                      onChange={(e) => setQualityChecks(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Issues Identified</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="issuesIdentified"
                      placeholder="Enter the issues identified..."
                      value={issuesIdentified}
                      onChange={(e) => setIssuesIdentified(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Corrective Actions</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="correctiveActions"
                      placeholder="Enter the corrective actions..."
                      value={correctiveActions}
                      onChange={(e) => setCorrectiveActions(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Packaging Details</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="packagingDetails"
                      placeholder="Enter the packaging details..."
                      value={packagingDetails}
                      onChange={(e) => setPackagingDetails(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="fdaRegistrationCode">FDA Registration Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fdaRegistrationCode"
                      placeholder="Enter the fda registration code..."
                      value={fdaRegistrationCode}
                      onChange={(e) => setFdaRegistrationCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Shelf Life *</label>
                    <input
                      type="number"
                      placeholder="Enter the shelf life..."
                      className="form-control"
                      id="shelfLife"
                      value={shelfLife}
                      onChange={(e) => {setShelfLife(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Shelf Life Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={shelfLifeUnit}
                      onChange={(e) => { setShelfLifeUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Shelf Life Unit...</option>
                      {lifespanUnitList && lifespanUnitList.map((lifespan) => (
                        <option key={lifespan} value={lifespan}>
                          {lifespan}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

              </div>
              <br />
              {manufacturingId && <ManufacturingProcessListing manufacturingId={manufacturingId} />}
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(manufacturingId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default ManufacturingForm;