import {
  useRef,
  useState,
} from 'react';

import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { baseURL } from '../../../utils/misc';
import Footer from '../../../layout/Footer';
import { runningCompany } from '../../../appSetup';

function ResetPassword() {
  const { dispatch } = useAuth();
  const { username, resetCode } = useParams();
  const passwordRef = useRef();
  const resetCodeRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    const resetCode = resetCodeRef.current.value;

    // Client-side validation
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      setLoading(true);

      const apiUrl = `${baseURL}/Users/updatepassword/${username}`;

      console.log('PATCH Request URL:', apiUrl);

      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
          passwordResetCode: resetCode,
        }),
      });

      console.log('Response Status Code:', response.status);

      const data = await response.json(); // No need to parse again, it's already parsed

      console.log('Server Response Data:', data);

      if (response.ok && data.active) {
        // Optionally reset the form or navigate to login page
         resetCodeRef.current.value = '';
         passwordRef.current.value = '';
         confirmPasswordRef.current.value = '';
        navigate('/users');
      } else {
        console.error('Response Text:', data);
        setError('Password update failed');
      }
    } catch (error) {
      console.error('Error occurred during password update:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div className="login-screen">
              <div className="login-box">
                <Link to="#" className="login-logo">
                  <img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
                </Link>
                <h5>Reset your password!</h5>
                <div className="form-group">
                  <input type="text" ref={resetCodeRef} className="form-control" placeholder="Reset Code" required />
                </div>
                <div className="form-group">
                  <input type="password" ref={passwordRef} className="form-control" placeholder="Password" required />
                </div>
                <div className="form-group">
                  <input type="password" ref={confirmPasswordRef} className="form-control" placeholder="Confirm Password" required />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="actions mt-4">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
                <Footer/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
