import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import WarehouseTable from "./WarehouseTable";
import { getFullCropName } from "../../utils/helpers";

function WarehouseListing({ cropId }) {
    const { user } = useAuth();
    const [warehouseRecords, setWarehouseRecords] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [crop, setCrop] = useState();


    useEffect(() => {
        const fetchCrops = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/crops/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching crops');
                }
                const data = await response.json();
                setCrop(data);
            } catch (error) {
                toast.error("Error fetching crops.");
                console.error("Error fetching crops:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchCrops();
    }, [user, navigate, cropId]);

    useEffect(() => {
        const fetchWarehouseRecords = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/warehouserecords/crop/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching ware house records');
                }
                const data = await response.json();
                setWarehouseRecords(data);
            } catch (error) {
                toast.error("Error fetching ware house records.");
                console.error("Error fetching ware house records:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchWarehouseRecords();
    }, [crop]);

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">

                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of Warehouse Records</div>
                                <div className="mt-3 p-2 parentLabel">
                                    <div className="mt-2 mb-1">
                                        <span>Crop: </span>{getFullCropName(crop)}
                                        <Link to={`/crops/field/${crop && crop.fieldId}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                                    </div>
                                </div>
                                <br/>
                                <Link
                                    to={`/warehouserecord/create/?crop=${cropId}`}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Warehouse Record
                                </Link>
                            

                            </div>
                            <WarehouseTable warehouseRecords={warehouseRecords} allowEdit={true} />
                        </div>
                    </div>
                </div>


                {/* Row start */}

                {/* Row end */}

            </div>

        </>
    );
}

export default WarehouseListing;