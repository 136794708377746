import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import { useEffect, useState } from "react";
import { baseURL } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import PackagingForm from "../../../components/PackagingRecords/PackagingForm";
import Footer from "../../../layout/Footer";

const EditPackagingRecord = () => {
    const { packagingId } = useParams();
    const [packagingRecord, setPackagingRecord] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        // Fetch workflow process data when the component mounts
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };

            try {
                const response = await fetch(`${baseURL}/packagingrecords/${packagingId}`, requestOptions);
                if (response.ok) {
                    const data = await response.json();
                    setPackagingRecord(data);
                } else {
                    console.error('Error fetching packaging record data');
                }
            } catch (error) {
                console.error('Error fetching packaging record data:', error);
            }
        };

        fetchData();
    }, [packagingId,navigate, user]);



    return (<>


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item">{packagingRecord && packagingRecord && packagingRecord.code}</li>
                        <li className="breadcrumb-item">
                            <Link to={`/packagingrecords/crop/${packagingRecord && packagingRecord && packagingRecord.cropId}`} >Packaging Records</Link>
                        </li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    {packagingRecord && <PackagingForm crop={packagingRecord && packagingRecord.crop}
                        packagingRecord={packagingRecord && packagingRecord}
                        setPackagingRecord={setPackagingRecord} />}
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}
            <Footer/>

        </div>
        {/* Page wrapper end */}
    </>);
}

export default EditPackagingRecord;