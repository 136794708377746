import { baseURL } from "../../utils/misc";

export const fetchFarmerData = async (user) => {
    if (user && user.user && user.user.id) {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        const response = await fetch(`${baseURL}/farmers/user/${user.user.id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }
    return null;
};

export const fetchFarmData = async (farmerId, user) => {
    if (farmerId) {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        const response = await fetch(`${baseURL}/farms/farmer/${farmerId}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }
    return null;
};

export const fetchFieldData = async (farmId, user) => {
    if (farmId) {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        const response = await fetch(`${baseURL}/fields/farm/${farmId}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }
    return null;
};

export const fetchCropsData = async (fieldId, user) => {
    if (fieldId) {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        const response = await fetch(`${baseURL}/crops/field/${fieldId}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }
    return null;
};

export const fetchRecords = async (url, cropIds, user) => {
    if (cropIds.length > 0) {
        let totalRecordsCount = 0;
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        for (const cropId of cropIds) {
            const response = await fetch(`${url}/crop/${cropId}`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data) {
                totalRecordsCount += data.length;
            }
        }
        return totalRecordsCount;
    }
    return 0;
};
