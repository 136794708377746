import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { isoToDate } from "../../utils/helpers";
import { Button, Modal } from "react-bootstrap";
import SpoilageFormBasic from "./ExportCustomFormBasic";

function ExportCustomTable({ exportCustomRecords, exportCustomRecord, crop, setExportCustomRecord,
    allowEdit, handleModalShow, handleSubmit, showModal, handleModalClose, exportCustomId, allowModalEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const isAdmin = user && user.user && hasAdminRole(user.user.roles);

    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
        },
        {
            id: 2,
            name: "Edit",
            icon: "icon-edit1",
            authType: 'admin',
            onClick: (record) => {
                if (allowModalEdit) {
                    handleModalShow(record, true); // Open modal for editing
                } else {
                    // Redirect to edit page if modal edit is not allowed
                    window.location.href = `/exportcustomsrecord/edit/${record.id}`;
                }
            },
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (exportCustomRecords && exportCustomRecords.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "12%", targets: 0 },
                        { width: "12%", targets: 2 }
                    ]
                });
            });
        }
    }, [exportCustomRecords, $]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemoveExportCustom = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/exportcustomsrecords/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Export Custom Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Export Custom Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive">
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>Customs Declaration Number</th>
                                        <th>Departure Date</th>
                                        <th>Exporter</th>
                                        <th>Country Of Destination</th>
                                        <th>Export Mode</th>
                                        <th>Port Of Loading</th>
                                        <th>Port Of Discharge</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exportCustomRecords && exportCustomRecords.length > 0 ? exportCustomRecords.map((exportRecord, index) => (
                                        <tr key={index}>
                                            <td>{exportRecord.code ? exportRecord.code : '--'}</td>
                                            <td>{exportRecord.customsDeclarationNumber}</td>
                                            <td>{isoToDate(exportRecord.departureDate)}</td>
                                            <td>{exportRecord.exporter}</td>
                                            <td>{exportRecord.destinationCountry}</td>
                                            <td>{exportRecord.exportMode}</td>
                                            <td>{exportRecord.portOfLoading}</td>
                                            <td>{exportRecord.portOfDischarge}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                to="#"
                                                                id={"dropdownMenuLink" + exportRecord.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + exportRecord.id}
                                                            >
                                                                {profileMenuItems
                                                                    .filter(item => !item.authType || (item.authType === "admin" && isAdmin)) // Filter items based on role
                                                                    .map(actionMenuItem => (
                                                                        <li key={exportRecord.id + '-' + actionMenuItem.id}>
                                                                            {actionMenuItem.onClick ? (
                                                                                <Link
                                                                                    to="#"
                                                                                    className="dropdown-item"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                        actionMenuItem.onClick(exportRecord);
                                                                                    }}
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            ) : (
                                                                                <Link
                                                                                    to={actionMenuItem.routerPath + "/" + exportRecord.id} // Changed href to to
                                                                                    className="dropdown-item"
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    ))}

                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="7" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>
                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Export Customs Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this export customs record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveExportCustom}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Export Customs Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Exporter:</strong> {selectedRecord.exporter ? selectedRecord.exporter : "N/A"}</p>
                                            <p><strong>Destination Country:</strong> {selectedRecord.destinationCountry ? selectedRecord.destinationCountry : "N/A"}</p>
                                            <p><strong>Export Mode:</strong> {selectedRecord.exportMode ? selectedRecord.exportMode : "N/A"}</p>
                                            <p><strong>Customs Declaration Number:</strong> {selectedRecord.customsDeclarationNumber ? selectedRecord.customsDeclarationNumber : "N/A"}</p>
                                            <p><strong>Shipping Line or Airline:</strong> {selectedRecord.shippingLineOrAirline ? selectedRecord.shippingLineOrAirline : "N/A"}</p>
                                            <p><strong>Flight or Vessel Number:</strong> {selectedRecord.flightOrVesselNumber ? selectedRecord.flightOrVesselNumber : "N/A"}</p>
                                            <p><strong>Port of Loading:</strong> {selectedRecord.portOfLoading ? selectedRecord.portOfLoading : "N/A"}</p>
                                            <p><strong>Port of Discharge:</strong> {selectedRecord.portOfDischarge ? selectedRecord.portOfDischarge : "N/A"}</p>
                                            <p><strong>Departure Date:</strong> {selectedRecord.departureDate ? isoToDate(selectedRecord.departureDate) : "N/A"}</p>
                                            <p><strong>Arrival Date:</strong> {selectedRecord.arrivalDate ? isoToDate(selectedRecord.arrivalDate) : "N/A"}</p>
                                            <p><strong>Total Weight:</strong> {selectedRecord.totalWeight ? selectedRecord.totalWeight : "N/A"} {selectedRecord.weightUnit ? selectedRecord.weightUnit : "N/A"}</p>
                                            <p><strong>Container Number:</strong> {selectedRecord.containerNumber ? selectedRecord.containerNumber : "N/A"}</p>
                                            <p><strong>Total Declared Value:</strong> {selectedRecord.totalDeclaredValue ? selectedRecord.totalDeclaredValue : "N/A"}</p>
                                            <p><strong>Currency:</strong> {selectedRecord.currency ? selectedRecord.currency : "N/A"}</p>
                                            <p><strong>Freight Forwarder:</strong> {selectedRecord.freightForwarder ? selectedRecord.freightForwarder : "N/A"}</p>
                                            <p><strong>Handling Agent:</strong> {selectedRecord.handlingAgent ? selectedRecord.handlingAgent : "N/A"}</p>
                                            <p>
                                                <strong>Shipping Documents:</strong>
                                                &nbsp;
                                                {selectedRecord.shippingDocuments ? (
                                                    <a className="btn btn-primary" href={selectedRecord.shippingDocuments} target="_blank" rel="noopener noreferrer">
                                                        <i className="icon-eye1" aria-hidden="true"></i> View Attachment
                                                    </a>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </p>
                                        </div>

                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{exportCustomId ? "Edit Export Customs Record" : "Add Export Customs Record"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SpoilageFormBasic
                                        crop={crop}
                                        exportCustomRecord={exportCustomRecord}
                                        setExportCustomRecord={setExportCustomRecord}
                                        handleSubmit={handleSubmit}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExportCustomTable;