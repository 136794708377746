import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import RequireAuth from './components/common/RequireAuth';
import InitiateContract from './pages/Contracts/InitiateContract';
import AttentionRequiredContractsIndex from './pages/Contracts/AttentionRequiredContractsIndex';
import FourOhFour from './pages/common/FourOhFour';
import Home from './pages/common/Home';
import ChangePassword from './pages/common/User/ChangePassword';
import ForgotPassword from './pages/common/User/ForgotPassword';
import Login from './pages/common/User/Login';
import ResetPassword from './pages/common/User/ResetPassword';
import UserProfile from './pages/common/User/Users/UserProfile';
import UserRoles from './pages/common/User/Users/UserRoles';
import Users from './pages/common/User/Users/Users';
import AddAndEditWorkFLow from './pages/WorkFlow/AddAndEditWorkFlow';
import CreateWorkFlow from './pages/WorkFlow/create/CreateWorkFlow';
import EditWorkFlow from './pages/WorkFlow/edit/EditWorkFlow';
import WorkFLow from './pages/WorkFlow/WorkFlow';
import CreateWorkFlowProcess from './pages/WorkFlow/WorkFlowProcess/create/CreateWorkFlowProcess';
import EditWorkFlowProcess from './pages/WorkFlow/WorkFlowProcess/edit/EditWorkFlowProcess';
import TableProcess from './pages/WorkFlow/WorkFlowProcess/table/TableProcess';
import CreateWorkFlowStages from './pages/WorkFlow/WorkFlowStages/create/CreateWorkFlowStages';
import EditWorkFlowStages from './pages/WorkFlow/WorkFlowStages/edit/EditWorkFlowStages';
import TableStages from './pages/WorkFlow/WorkFlowStages/table/TableStages';
import CreateWorkFlowStatus from './pages/WorkFlow/WorkFlowStatuses/create/CreateWorkFlowStatus';
import EditWorkFlowStatus from './pages/WorkFlow/WorkFlowStatuses/edit/EditWorkFlowStatus';
import TableStatuses from './pages/WorkFlow/WorkFlowStatuses/table/TableStatuses';
import WorkflowStageInstructionIndex from './pages/BMRSetups/WorkflowStageInstructions/WorkflowStageInstructionIndex';
import WorkflowStageInstructionFormIndex from './pages/BMRSetups/WorkflowStageInstructions/WorkflowStageInstructionFormIndex';
import WorkFlowPermission from './pages/WorkflowPermission/WorkFlowPermission';
import ContractsIndex from './pages/Contracts/ContractsIndex';
import WorkflowUpdateOwnership from './pages/WorkflowRecordActivity/WorkflowUpdateOwnership/WorkflowUpdateOwnership';
import StagesTransition from './pages/StagesTransitiion/StagesTransition';
import EditUser from './pages/common/User/Users/edit/EditUser';
import CreateUser from './pages/common/User/Users/create/CreateUser';
import BmrAttachment from './pages/WorkflowRecordActivity/BMRAttachment/BmrAttachment';
import BMRRecordsIndex from './pages/WorkflowRecord/BMRRecordsIndex';
import Register from './pages/common/User/Register';
import InvitationsIndex from './pages/Registration/Invitations/InvitationsIndex';
import InvitationCreateIndex from './pages/Registration/Invitations/InvitationCreateIndex';
import RegisterAsIndex from './pages/Registration/RegisterAs/RegisterAsIndex';
import RegisterExporterIndex from './pages/Exporters/RegisterExporter/RegisterExporterIndex';
import CreateFarm from './pages/Farm/create/CreateFarm';
import EditFarm from './pages/Farm/edit/EditFarm';
import FarmTable from './components/Farm/FarmTable';
import Farms from './pages/Farm/table/Farms';
import Fields from './pages/Field/table/Fields';
import CreateField from './pages/Field/create/CreateField';
import EditField from './pages/Field/edit/EditField';
import CreateCrop from './pages/Crop/create/CreateCrop';
import EditCrop from './pages/Crop/edit/EditCrop';
import Crops from './pages/Crop/table/Crops';
import EditPlantingRecord from './pages/PlantingRecords/edit/EditPlantingRecord';
import CreatePlantingRecord from './pages/PlantingRecords/create/CreatePlantingRecord';
import PlantingRecords from './pages/PlantingRecords/table/PlantingRecords';
import PlantProtectionRecords from './pages/PlantProtectionRecords/table/PlantProtectionRecords';
import CreatePlantProtectionRecord from './pages/PlantProtectionRecords/create/CreatePlantProtectionRecord';
import EditPlantProtectionRecord from './pages/PlantProtectionRecords/edit/EditPlantProtectionRecord';
import CreateHarvestingRecord from './pages/HarvestingRecords/create/CreateHarvestingRecord';
import EditHarvestingRecord from './pages/HarvestingRecords/edit/EditHarvestingRecord';
import HarvestingRecords from './pages/HarvestingRecords/table/harvestingRecords';
import PackHouseRecords from './pages/PackHouseRecords/table/PackHouseRecords';
import CreatePackHouseRecord from './pages/PackHouseRecords/create/CreatePackHouseRecord';
import EditPackHouseRecord from './pages/PackHouseRecords/edit/EditPackHouseRecord';
import CreateManufacturingRecord from './pages/ManufacturingRecords/create/CreateManufacturingRecord';
import EditManufacturingRecord from './pages/ManufacturingRecords/edit/EditManufacturingRecord';
import ManufacturingRecords from './pages/ManufacturingRecords/table/ManufacturingRecords';
import RegisterFarmerIndex from './pages/Farmers/RegisterFarmer/RegisterFarmerIndex';
import CreatePackagingRecord from './pages/PackagingRecords/create/CreatePackagingRecord';
import EditPackagingRecord from './pages/PackagingRecords/edit/EditPackagingRecord';
import TransferRecords from './pages/TransferRecords/table/Transfers';
import CreateTransferRecord from './pages/TransferRecords/create/CreateTransferRecord';
import EditTransferRecord from './pages/TransferRecords/edit/EditTransferRecord';
import WeedManagementRecords from './pages/WeedManagementRecords/table/WeedManagementRecords';
import CreateWeedRecord from './pages/WeedManagementRecords/create/CreateWeedRecord';
import EditWeedRecord from './pages/WeedManagementRecords/edit/EditWeedRecord';
import NutrientManagementRecords from './pages/NutrientManagementRecords/table/NutrientManagementRecords';
import CreateNutrientRecord from './pages/NutrientManagementRecords/create/CreateNutrientRecord';
import EditNutrientRecord from './pages/NutrientManagementRecords/edit/EditNutrientRecord';
import PestManagementRecords from './pages/PestManagementRecords/table/PestManagementRecords';
import CreatePestRecord from './pages/PestManagementRecords/create/CreatePestRecord';
import EditPestRecord from './pages/PestManagementRecords/edit/EditPestRecord';
import FieldPreparationRecords from './pages/FIeldPreparationRecords/table/FieldPreparationRecords';
import CreateFieldPreparationRecord from './pages/FIeldPreparationRecords/create/CreateFieldPreparationRecord';
import EditFieldPreparationRecord from './pages/FIeldPreparationRecords/edit/EditFieldPreparationRecord';
import WareHouseRecords from './pages/WareHouseRecords/table/WareHouses';
import CreateWareHouseRecord from './pages/WareHouseRecords/create/CreateWareHouseRecord';
import EditWareHouseRecord from './pages/WareHouseRecords/edit/EditWareHouseRecord';
import LogisticsRecords from './pages/LogisticsRecords/table/Logistics';
import CreateLogisticsRecord from './pages/LogisticsRecords/create/CreateLogisticsRecord';
import EditLogisticsRecord from './pages/LogisticsRecords/edit/EditLogisticsRecord';
import SpoilageRecords from './pages/SpoilageRecords/table/Spoilages';
import CreateSpoilageRecord from './pages/SpoilageRecords/create/CreateSpoilageRecord';
import EditSpoilageRecord from './pages/SpoilageRecords/edit/EditSpoilageRecord';
import CreateManufacturingProcess from './pages/ManufacturingRecords/ManufacturingProcessRecords/create/CreateManufacturingProcess';
import EditManufacturingProcess from './pages/ManufacturingRecords/ManufacturingProcessRecords/edit/EditManufacturingProcess';
import Farmers from './pages/Farmers/table/Farmers';
import SelectCropToManufacturingIndex from './components/ManufacturingRecords/SelectCropToManufacturingIndex';
import CreateFarmer from './pages/Farmers/create/CreateFarmer';
import EditFarmer from './pages/Farmers/edit/EditFarmer';
import SelectCropToSpoilageIndex from './components/SpoilageReports/SelectCropToSpoilageIndex';
import CompanyInfo from './components/Exporters/CompanyInfo';
import InductionRecords from './pages/InductionRecords/table/InductionRecords';
import CreateInductionRecord from './pages/InductionRecords/create/CreateInductionRecord';
import EditInductionRecord from './pages/InductionRecords/edit/EditInductionRecord';
import RegisterBuyerIndex from './pages/Buyer/RegisterBuyer/RegisterBuyerIndex';
import ContractViewIndex from './pages/Contracts/ContractViewIndex';
import ContractReviewIndex from './pages/Contracts/WorkflowForms/ContractReviewIndex';
import ContractUpdateIndex from './pages/Contracts/WorkflowForms/ContractUpdateIndex';
import Packagings from './pages/PackagingRecords/table/Packagings';
import PreExportProcessesIndex from './pages/Contracts/WorkflowForms/PreExportProcessesIndex';
import Transfers from './pages/TransferRecords/table/Transfers';
import Logistics from './pages/LogisticsRecords/table/Logistics';
import WareHouses from './pages/WareHouseRecords/table/WareHouses';
import ExportProcessesIndex from './pages/Contracts/WorkflowForms/ExportProcessesIndex';
import InTransitProcessesIndex from './pages/Contracts/WorkflowForms/InTransitProcessesIndex';
import PostImportProcessesIndex from './pages/Contracts/WorkflowForms/PostImportProcessesIndex';
import ImportProcessesIndex from './pages/Contracts/WorkflowForms/ImportProcessesIndex';
import Spoilages from './pages/SpoilageRecords/table/Spoilages';
import ExportCustoms from './pages/ExportCustomsRecords/table/ExportCustoms';
import CreateExportCustomRecord from './pages/ExportCustomsRecords/create/CreateExportCustomRecord';
import EditExportCustomRecord from './pages/ExportCustomsRecords/edit/EditExportCustomRecord';
import { useEffect } from 'react';
import { runningCompanyName } from './appSetup';

if (typeof window !== "undefined") {
  //require("bootstrap/dist/js/bootstrap");
  require("datatables.net-dt/js/dataTables.dataTables");
}

function App() {
  useEffect(() => {document.title = 'Traceability - ' + runningCompanyName}, [])
  return (
    <div className="container-fluid">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          {/* <Route path="/resetpassword/:username" element={<ResetPassword />} />
          {/* <Route path="/resetpassword/:username/:resetCode" element={<ResetPassword />} /> */}

          {/* REGISTRATIONS */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/register/:uniqueCode?" element={<RegisterAsIndex />} />
          <Route path="/exporter/register/:uniqueCode" element={<RegisterExporterIndex />} />
          <Route path="/farmer/register/:uniqueCode" element={<RegisterFarmerIndex />} />
          <Route path="/buyer/register/:uniqueCode" element={<RegisterBuyerIndex />} />
          {/* END: REGISTRATIONS */}

          {/* private routes */}
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />

            {/* ADMIN */}
            <Route path="/users" element={<Users />} />
            <Route path="/user/create" element={<CreateUser />} />
            <Route path="/user/edit/:userId" element={<EditUser />} />

            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/user-roles/:userId" element={<UserRoles />} />
            {/* END: ADMIN */}

            {/* Company Info */}
            <Route path="/company-info" element={<CompanyInfo />} />


            {/* WORKFLOW SETUPS */}

            {/* Export Customs Records */}
            <Route path="/exportcustomsrecords/crop/:cropId" element={<ExportCustoms />} />
            <Route path="/exportcustomsrecord/create" element={<CreateExportCustomRecord />} />
            <Route path="/exportcustomsrecord/edit/:exportCustomId" element={<EditExportCustomRecord />} />
            {/* Farmers */}
            <Route path="/outgrowers" element={<Farmers />} />
            <Route path="/outgrower/create" element={<CreateFarmer />} />
            <Route path="/outgrower/edit/:farmerId" element={<EditFarmer />} />

            {/* Farms */}
            <Route path="/farms" element={<Farms />} />
            <Route path="/farms/farmer/:farmerIdParam?" element={<Farms />} />
            <Route path="/farm/create" element={<CreateFarm />} />
            <Route path="/farm/edit/:farmId" element={<EditFarm />} />

            {/* Fields */}
            <Route path="/fields/farm/:farmId" element={<Fields />} />
            <Route path="/field/create" element={<CreateField />} />
            <Route path="/field/edit/:fieldId" element={<EditField />} />

            {/* Crops */}
            <Route path="/crops/field/:fieldId" element={<Crops />} />
            <Route path="/crop/create" element={<CreateCrop />} />
            <Route path="/crop/edit/:cropId" element={<EditCrop />} />

            {/* Planting Records */}
            <Route path="/plantingrecords/crop/:cropId" element={<PlantingRecords />} />
            <Route path="/plantingrecord/create" element={<CreatePlantingRecord />} />
            <Route path="/plantingrecord/edit/:plantingRecordId" element={<EditPlantingRecord />} />

            {/* Field Preparation Records */}
            <Route path="/fieldpreparationrecords/crop/:cropId" element={<FieldPreparationRecords />} />
            <Route path="/fieldpreparationrecord/create" element={<CreateFieldPreparationRecord />} />
            <Route path="/fieldpreparationrecord/edit/:fieldPreparationId" element={<EditFieldPreparationRecord />} />

          {/* Plant Protection Records */}
            {/* Weed Management */}
            <Route path="/weedmanagementrecords/crop/:cropId" element={<WeedManagementRecords />} />
            <Route path="/weedmanagementrecord/create" element={<CreateWeedRecord />} />
            <Route path="/weedmanagementrecord/edit/:weedRecordId" element={<EditWeedRecord />} />

            {/* Nutrient Management */}
            <Route path="/nutrientmanagementrecords/crop/:cropId" element={<NutrientManagementRecords />} />
            <Route path="/nutrientmanagementrecord/create" element={<CreateNutrientRecord />} />
            <Route path="/nutrientmanagementrecord/edit/:nutrientRecordId" element={<EditNutrientRecord />} />

            {/* Pest Management */}
            <Route path="/pestmanagementrecords/crop/:cropId" element={<PestManagementRecords />} />
            <Route path="/pestmanagementrecord/create" element={<CreatePestRecord />} />
            <Route path="/pestmanagementrecord/edit/:pestRecordId" element={<EditPestRecord />} />

            {/* Harvesting Records */}
            <Route path="/harvestingrecords/crop/:cropId" element={<HarvestingRecords />} />
            <Route path="/harvestingrecord/create" element={<CreateHarvestingRecord />} />
            <Route path="/harvestingrecord/edit/:harvestingRecordId" element={<EditHarvestingRecord />} />

            {/* Pack House Records */}
            <Route path="/packhouserecords/crop/:cropId" element={<PackHouseRecords />} />
            <Route path="/packhouserecords/create" element={<CreatePackHouseRecord />} />
            <Route path="/packhouserecords/edit/:packHouseId" element={<EditPackHouseRecord />} />

            {/* Manufacturing Records */}
            <Route path="/crops-manufacturingrecords" element={<SelectCropToManufacturingIndex />} />

            <Route path="/manufacturingrecords/crop/:cropId" element={<ManufacturingRecords />} />
            <Route path="/manufacturingrecord/create" element={<CreateManufacturingRecord />} />
            <Route path="/manufacturingrecord/edit/:manufacturingId" element={<EditManufacturingRecord />} />
            {/* Manufacturing Processes */}
            <Route path="/manufacturingprocess/create" element={<CreateManufacturingProcess />} />
            <Route path="/manufacturingprocess/edit/:manufacturingProcessId" element={<EditManufacturingProcess />} />

            {/* Packaging Records */}
            <Route path="/packagingrecords/crop/:cropId" element={<Packagings />} />
            <Route path="/packagingrecord/create" element={<CreatePackagingRecord />} />
            <Route path="/packagingrecord/edit/:packagingId" element={<EditPackagingRecord />} />

            {/* Spoilage Records */}
            <Route path="/crops-spoilagerecords" element={<SelectCropToSpoilageIndex />} />
            
            <Route path="/spoilagerecords/crop/:cropId" element={<Spoilages />} />
            <Route path="/spoilagerecord/create" element={<CreateSpoilageRecord />} />
            <Route path="/spoilagerecord/edit/:spoilageId" element={<EditSpoilageRecord />} />

            {/* Transfer Records */}
            <Route path="/transferrecords/crop/:cropId" element={<Transfers />} />
            <Route path="/transferrecord/create" element={<CreateTransferRecord />} />
            <Route path="/transferrecord/edit/:transferId" element={<EditTransferRecord />} />

            {/* Logistics Records */}
            <Route path="/logisticsrecords/crop/:cropId" element={<Logistics />} />
            <Route path="/logisticsrecord/create" element={<CreateLogisticsRecord />} />
            <Route path="/logisticsrecord/edit/:logisticsId" element={<EditLogisticsRecord />} />

            {/* Warehouse Records */}
            <Route path="/warehouserecords/crop/:cropId" element={<WareHouses />} />
            <Route path="/warehouserecord/create" element={<CreateWareHouseRecord />} />
            <Route path="/warehouserecord/edit/:warehouseId" element={<EditWareHouseRecord />} />

            {/* Induction Records */}
            <Route path="/inductionrecords/crop/:cropId" element={<InductionRecords />} />
            <Route path="/inductionrecord/create" element={<CreateInductionRecord />} />
            <Route path="/inductionrecord/edit/:inductionRecordId" element={<EditInductionRecord />} />

            {/* WorkFlow Process */}
            <Route path="/workflowprocesses/workflow/:workflowId" element={<TableProcess />} />
            <Route path="/workflowprocesses/edit/:workflowprocessId" element={<EditWorkFlowProcess />} />
            <Route path="/workflowprocess/create" element={<CreateWorkFlowProcess />} />

            {/* WorkFlow Stage */}
            <Route path="/workflowstage/edit/:workflowstagesId" element={<EditWorkFlowStages />} />
            <Route path="/workflowstage/create" element={<CreateWorkFlowStages />} />
            <Route path="/workflowstages/workflowprocess/:workflowProcessId" element={<TableStages />} />

            {/* WorkFlowTransition */}
            <Route path="/workflowtransition/workflowStage/:currentWorkflowStageId/:workflowProcessId?" element={<StagesTransition />} />

            {/* WorkFlowPermission */}
            <Route path="/workflowpermission/workflowStage/:workflowStageId/:workflowStatusId?" element={<WorkFlowPermission />} />
            
            {/* WorkFlow Status */}
            <Route path="/workflowstatus/edit/:workflowstatusesId" element={<EditWorkFlowStatus />} />
            <Route path="/workflowstatus/create" element={<CreateWorkFlowStatus />} />
            <Route path="/workflowstatuses/:workflowId" element={<TableStatuses />} />

            {/* WorkFlow */}
            <Route path="/workflows" element={<WorkFLow />} />
            <Route path="/workflow/create" element={<CreateWorkFlow />} />
            <Route path="/workflow/edit/:workflowId" element={<EditWorkFlow />} />
            <Route path="/workflows/add/:id?" element={<AddAndEditWorkFLow />} />
            {/* END: WORKFLOW SETUPS */}

            {/* BMR REFERENCED FORMS */}
            {/* WorkFlowRecordActivities */}
            <Route path="/contracts" element={<ContractsIndex />} />
            <Route path="/contracts/record/:recordId" element={<ContractViewIndex />} />
            <Route path="/bmrs/approved" element={<BMRRecordsIndex />} />
            <Route path="/bmr/:processId/update-ownership/:workflowRecordActivityId/:workflowStageId" element={<WorkflowUpdateOwnership />} />
            <Route path="/:processId/bmr/:bmrId/attachment" element={<BmrAttachment />} />
            <Route path="/contracts/initiate" element={<InitiateContract />} />
            <Route path="/contracts/attention-required" element={<AttentionRequiredContractsIndex />} />
             {/* END: BMR REFERENCED FORMS */}

            {/* Contract Initiation  */}
            <Route path="/contracts/contract-initiation/contract-review/contract/:contractId/activity/:workflowRecordActivityId" element={<ContractReviewIndex />} />
            <Route path="/contracts/contract-initiation/update-contract/contract/:contractId/activity/:workflowRecordActivityId" element={<ContractUpdateIndex />} />
            {/* END: Contract Initiation  */}

            {/* Pre Export Process  */}
            <Route path="/contracts/pre-export-processes/pre-export-stage/contract/:contractId/activity/:workflowRecordActivityId" element={<PreExportProcessesIndex />} />
            {/* END: Pre Export Process  */}

            {/* Export Process  */}
            <Route path="/contracts/export-processes/export-stage/contract/:contractId/activity/:workflowRecordActivityId" element={<ExportProcessesIndex />} />
            {/* END: Export Process  */}

            {/* In-Transit Process  */}
            <Route path="/contracts/in-transit-processes/in-transit-stage/contract/:contractId/activity/:workflowRecordActivityId" element={<InTransitProcessesIndex />} />
            {/* END: Export Process  */}

            {/* Import Process  */}
            <Route path="/contracts/import-processes/import-stage/contract/:contractId/activity/:workflowRecordActivityId" element={<ImportProcessesIndex />} />
            {/* END: Export Process  */}

            {/* Post Process  */}
            <Route path="/contracts/post-import-processes/post-import-stage/contract/:contractId/activity/:workflowRecordActivityId" element={<PostImportProcessesIndex />} />
            {/* END: Export Process  */}

            {/* BMR SETUPS */}
            {/* workflow-stage-instructions */}
            <Route path="/bmrsetups/workflow-stage-instructions/:workflowStageId?" element={<WorkflowStageInstructionIndex />} />
            <Route path="/bmrsetups/workflow-stage-instructions/add/workflowStage/:workflowStageId/:stageInstructionId?" element={<WorkflowStageInstructionFormIndex />} />
            {/* END: BMR SETUPS */}

            {/* REGISTRATION */}
            <Route path="/registration/invitations" element={<InvitationsIndex />} />
            <Route path="/registration/invitations/create" element={<InvitationCreateIndex />} />
            {/* END: REGISTRATION */}
          </Route>
          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </BrowserRouter>
    </div >
  );
}
export default App;
