import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import Preloader from "../../../components/common/Preloader";


const WorkflowStageInstructionForm = ({ workflowStageId,stageInstructionId = null }) => {
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const { user } = useAuth();
  const [workflowStage, setWorkflowStage] = useState();
  const [instructions, setInstructions] = useState("");

  useEffect(() => {
    if (workflowStageId) {
      getWorkFlowStageData();
    }

    if(stageInstructionId) {
      getLineClearanceChecklistHeaderData();
    }
  }, [])


  const getLineClearanceChecklistHeaderData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
    }

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    };
    setShowPreloader(true);

    fetch(`${baseURL}/workflow-stage-instructions/${stageInstructionId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setInstructions(data.instructions);
      })
      .catch((error) => {
        toast.error("Error fetching the specific Workflow Stage.");
      })
      .finally(()=>{
        setShowPreloader(false);
      });
  };

  const getWorkFlowStageData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
    }

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    };
    setShowPreloader(true);

    fetch(`${baseURL}/workflowstages/${workflowStageId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setWorkflowStage(data);
      })
      .catch((error) => {
        toast.error("Error fetching the specific Workflow Stage.");
      })
      .finally(()=>{
        setShowPreloader(false);
      })
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if (!instructions) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      workflowStageId: workflowStageId,
      instructions: instructions,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflow-stage-instructions`, requestOptions);

      if (response.ok) {
        toast.success("The workflow-stage-instruction has been successfully initiated.");
        setShowPreloader(true);
        navigate(`/bmrsetups/workflow-stage-instructions/${workflowStageId}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the workflow-stage-instruction.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    // // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    // Basic field validations
    if (!instructions) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      id: stageInstructionId,
      workflowStageId: workflowStageId,
      instructions: instructions
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflow-stage-instructions/${stageInstructionId}`, requestOptions);

      if (response.ok) {
        toast.success("The workflow-stage-instruction has been successfully updated.");
        setShowPreloader(true);
        navigate(`/bmrsetups/workflow-stage-instructions/${workflowStageId}`);
      } else {
        console.error('response failed to update');
      }
    } catch (error) {
      toast.error("Error updating the workflow-stage-instruction.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Add Workflow Stage Instruction to {workflowStage ? workflowStage.name : "--"} Stage</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group row gutters">
                <label
                  htmlFor="workflowStageId"
                  className="col-sm-2 col-form-label"
                >
                  Workflow Stage:
                </label>
                <div className="col-sm-10">
                  <span>{workflowStage ? workflowStage.name : "--" }</span>
                </div>
              </div>
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Instructions :</label>
                    <textarea
                      rows="10"
                      className="form-control"
                      id="instructions"
                      placeholder="Enter the instructions..."
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(stageInstructionId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WorkflowStageInstructionForm;