import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getHarvestingStages, getHarvestQuantityUnit, getHowIsProductHandled } from "../../api/HarvestingRecords/getHarvestingRecords";
import EnvironmentalCondition from "../common/EnvironmentalCondition";

const HarvestingForm = ({ harvestingRecords,crop }) => {
  const { user } = useAuth();
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [harvestingRecordId, setHarvestingRecordId] = useState(null);
  const [harvestingStage, setHarvestingStage] = useState('');
  const [howIsProductHandledBeforeTransportation, setHowIsProductHandledBeforeTransportation] = useState('');
  const [harvestingStageOther, setHarvestingStageOther] = useState();
  const [howIsProductHandledBeforeTransportationOther, setHowIsProductHandledBeforeTransportationOther] = useState();
  const [associatedCost, setAssociatedCost] = useState('');
  const [code, setCode] = useState('');
  const [harvestingStageList, setHarvestingStageList] = useState([]);
  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [howIsProductHandledBeforeTransportationList, setHowIsProductHandledBeforeTransportationList] = useState([]);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

  useEffect(() => {
    getSetUpData();
  }, []);


  useEffect(() => {

    if (harvestingRecordId) {
      getHarvestingRecords(harvestingRecordId);
    }
  }, [harvestingRecordId])

  useEffect(() => {
    if (harvestingRecords) {
      setHarvestingRecordId(harvestingRecords.id);
      setDate(harvestingRecords.date);
      setQuantity(harvestingRecords.quantity);
      setQuantityUnit(harvestingRecords.quantityUnit);
      setHowIsProductHandledBeforeTransportationOther(harvestingRecords.howIsProductHandledBeforeTransportationOther);
      setHarvestingStageOther(harvestingRecords.harvestingStageOther);
      setHarvestingStage(harvestingRecords.harvestingStage);
      setHowIsProductHandledBeforeTransportation(harvestingRecords.howIsProductHandledBeforeTransportation);
      setAssociatedCost(harvestingRecords.associatedCost);
      setCode(harvestingRecords.code);

      if (harvestingRecords.environmentalCondition) {
        setEnvironmentalConditionId(harvestingRecords.environmentalCondition.id);
        setEnvironmentalCondition(harvestingRecords.environmentalCondition);
      }
    }
  }, [harvestingRecords]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setHarvestingStageList(await getHarvestingStages(user.user.token));
      setQuantityUnitList(await getHarvestQuantityUnit(user.user.token));
      setHowIsProductHandledBeforeTransportationList(await getHowIsProductHandled(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getHarvestingRecords = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/harvestingrecords/${harvestingRecordId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setDate(data.date);
        setQuantity(data.quantity);
        setQuantityUnit(data.quantityUnit);
        setHarvestingStage(data.harvestingStage);
        setHarvestingStageOther(data.harvestingStageOther);
        setHowIsProductHandledBeforeTransportationOther(data.howIsProductHandledBeforeTransportationOther);
        setHowIsProductHandledBeforeTransportation(data.howIsProductHandledBeforeTransportation);
        setAssociatedCost(data.associatedCost);
        setCode(data.code);
        if (data.environmentalCondition) {
          setEnvironmentalConditionId(data.environmentalCondition.id);
          setEnvironmentalCondition(data.environmentalCondition);
        }
        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching harvesting records data.');
        console.error("Error fetching harvesting records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!quantity) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }

    if (!quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!harvestingStage) {
      fieldWithIdRequired('harvestingStage');
      toast.error("Harvesting Stage is required.");
      retVal = false;
    }

    if (!howIsProductHandledBeforeTransportation) {
      fieldWithIdRequired('howIsProductHandledBeforeTransportation');
      toast.error("How is Product Handled Before Transportation is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }

    
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      quantity: quantity,
      quantityUnit: quantityUnit,
      harvestingStage: harvestingStage,
      harvestingStageOther: harvestingStageOther,
      howIsProductHandledBeforeTransportationOther: howIsProductHandledBeforeTransportationOther,
      howIsProductHandledBeforeTransportation: howIsProductHandledBeforeTransportation,
      associatedCost: associatedCost,
      environmentalCondition
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/harvestingrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Harvesting Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/harvestingrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the harvesting record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: harvestingRecordId,
      cropId:crop.id,
      date: date,
      quantity: quantity,
      quantityUnit: quantityUnit,
      harvestingStage: harvestingStage,
      harvestingStageOther: harvestingStageOther,
      howIsProductHandledBeforeTransportationOther: howIsProductHandledBeforeTransportationOther,
      howIsProductHandledBeforeTransportation: howIsProductHandledBeforeTransportation,
      associatedCost: associatedCost,
      environmentalConditionId,
      environmentalCondition
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/harvestingrecords/${harvestingRecordId}`, requestOptions);

      if (response.ok) {
        toast.success("The harvest record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/harvestingrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the harvesting record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Harvesting Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/harvestingrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

              {harvestingRecordId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code ? code : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="name"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Quantity *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the quantity..."
                      value={quantity}
                      onChange={(e) => {setQuantity(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Quantity Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="quantityUnit"
                      value={quantityUnit}
                      onChange={(e) => { setQuantityUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Quantity Unit...</option>
                      {quantityUnitList && quantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Harvesting Stage *</label>
                    <select
                      className="form-control form-control-lg"
                      id="harvestingStage"
                      value={harvestingStage}
                      onChange={(e) => { setHarvestingStage(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Harvesting Stage...</option>
                      {harvestingStageList && harvestingStageList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                {harvestingStage === 'Other' && (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="harvestingStageOther">Specify Other Harvesting Stage</label>
                    <input
                      type="text"
                      className="form-control"
                      id="harvestingStageOther"
                      placeholder="Enter the harvesting stage other..."
                      value={harvestingStageOther}
                      onChange={(e) => setHarvestingStageOther(e.target.value)}
                    />
                  </div>
                </div>
                )}

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">How Is Product Handled Before Transportation *</label>
                    <select
                      className="form-control form-control-lg"
                      id="treatmentBeforePlanting"
                      value={howIsProductHandledBeforeTransportation}
                      onChange={(e) => { setHowIsProductHandledBeforeTransportation(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select How Product Is Handled Before Transportation...</option>
                      {howIsProductHandledBeforeTransportationList && howIsProductHandledBeforeTransportationList.map((treatment) => (
                        <option key={treatment} value={treatment}>
                          {treatment}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                {howIsProductHandledBeforeTransportation === 'Other' && (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="howIsProductHandledBeforeTransportationOther">Specify How Product Is Handled Before Transportation</label>
                      <input
                        type="text"
                        className="form-control"
                        id="howIsProductHandledBeforeTransportationOther"
                        placeholder="Enter how product is handled before transportation..."
                        value={howIsProductHandledBeforeTransportationOther}
                        onChange={(e) => setHowIsProductHandledBeforeTransportationOther(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="associatedCost">Associated Cost <em>(in GHS)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Associated Cost"
                      id="associatedCost"
                      value={associatedCost}
                      onChange={(e) => setAssociatedCost(e.target.value)}
                    />
                  </div>
                </div>

              </div>
              <br />
              <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(harvestingRecordId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default HarvestingForm;