import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getWeedIngredientQuantityUnit, getWeedMaturityStagesOfCrop, getWeedSources } from "../../api/WeedManagementRecords/getWeedRecordsList";
import EnvironmentalCondition from "../common/EnvironmentalCondition";

const WeedForm = ({ weedRecords,crop}) => {
  const { user } = useAuth();

  const [date, setDate] = useState('');
  const [activeIngredients, setActiveIngredients] = useState('');
  const [appliedBy, setAppliedBy] = useState('');
  const [source, setSource] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [toolOrMaterialUsed, setToolOrMaterialUsed] = useState('');
  const [quantityApplied, setQuantityApplied] = useState('');
  const [quantityAppliedUnit, setQuantityAppliedUnit] = useState('');
  const [weedRecordId, setWeedRecordId] = useState(null);
  const [maturityStageOfCrop, setMaturityStageOfCrop] = useState('');
  const [associatedCost, setAssociatedCost] = useState('');
  const [code, setCode] = useState('');
  const [maturityStageOfCropList, setMaturityStageOfCropList] = useState([]);
  const [ingredientQuantityUnitList, setIngredientQuantityUnitList] = useState([]);
  const [sourceList, setsourceList] = useState([]);

    //env conditions data
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {
    if (weedRecordId) {
      getWeedRecords(weedRecordId);
    }
  }, [weedRecordId])

  useEffect(() => {
    if (weedRecords) {
      setWeedRecordId(weedRecords.id);
      setDate(weedRecords.date);
      setSource(weedRecords.source);
      setActiveIngredients(weedRecords.activeIngredients);
      setToolOrMaterialUsed(weedRecords.toolOrMaterialUsed);
      setMaturityStageOfCrop(weedRecords.maturityStageOfCrop);
      setQuantityApplied(weedRecords.quantityApplied);
      setQuantityAppliedUnit(weedRecords.quantityAppliedUnit);
      setAppliedBy(weedRecords.appliedBy);
      setSupervisedBy(weedRecords.supervisedBy);
      setAssociatedCost(weedRecords.associatedCost);
      setCode(weedRecords.code);

      if (weedRecords.environmentalCondition) {
        setEnvironmentalConditionId(weedRecords.environmentalCondition.id);
        setEnvironmentalCondition(weedRecords.environmentalCondition);
      }
    }
  }, [weedRecords]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setMaturityStageOfCropList(await getWeedMaturityStagesOfCrop(user.user.token));
      setIngredientQuantityUnitList(await getWeedIngredientQuantityUnit(user.user.token));
      setsourceList(await getWeedSources(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getWeedRecords = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/weedmanagementrecords/${weedRecordId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setDate(data.date);
        setActiveIngredients(data.activeIngredients);
        setMaturityStageOfCrop(data.maturityStageOfCrop);
        setAppliedBy(data.appliedBy);
        setSource(data.source);
        setSupervisedBy(data.supervisedBy);
        setToolOrMaterialUsed(data.toolOrMaterialUsed);
        setQuantityApplied(data.quantityApplied);
        setQuantityAppliedUnit(data.quantityAppliedUnit);
        setAssociatedCost(data.associatedCost);
        setCode(data.code);

        if (data.environmentalCondition) {
          setEnvironmentalConditionId(data.environmentalCondition.id);
          setEnvironmentalCondition(data.environmentalCondition);
        }

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching weed records data.');
        console.error("Error fetching weed records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!toolOrMaterialUsed) {
      fieldWithIdRequired('nameOfProduct');
      toast.error("Planting Material Type is required.");
      retVal = false;
    }


    if (!maturityStageOfCrop) {
      fieldWithIdRequired('maturityStageOfCrop');
      toast.error("Cropping Type is required.");
      retVal = false;
    }

    if (!appliedBy) {
      fieldWithIdRequired('appliedBy');
      toast.error("Applied By is required.");
      retVal = false;
    }


    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }



    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      maturityStageOfCrop: maturityStageOfCrop,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      toolOrMaterialUsed: toolOrMaterialUsed,
      source:source,
      quantityApplied: quantityApplied,
      quantityAppliedUnit:quantityAppliedUnit,
      associatedCost,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/weedmanagementrecords`, requestOptions);

      if (response.ok) {
        toast.success("The weed record has been successfully created.");
        console.log("post success response >>:", response);
        setShowPreloader(true);
        navigate(`/weedmanagementrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the weedmanagementrecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: weedRecordId,
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      maturityStageOfCrop: maturityStageOfCrop,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      toolOrMaterialUsed: toolOrMaterialUsed,
      source: source,
      quantityApplied: quantityApplied,
      quantityAppliedUnit:quantityAppliedUnit,
      associatedCost: associatedCost,
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/weedmanagementrecords/${weedRecordId}`, requestOptions);

      if (response.ok) {
        toast.success("The weed record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/weedmanagementrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the weed record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Weed Management Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/weedmanagementrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

              {weedRecordId && 
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="code"
                      value={code ? code : '--'}
                      readOnly
                    />
                  </div>
                </div>
              }

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Maturity Stage Of Crop *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={maturityStageOfCrop}
                      onChange={(e) => { setMaturityStageOfCrop(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Maturity Stage Type...</option>
                      {maturityStageOfCropList && maturityStageOfCropList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="toolOrMaterialUsed">Tool Or Material Used *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="toolOrMaterialUsed"
                      placeholder="Enter the Tool or Material used..."
                      value={toolOrMaterialUsed}
                      onChange={(e) => {setToolOrMaterialUsed(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Active Ingredients</label>
                    <input
                      type="text"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the active ingredients..."
                      value={activeIngredients}
                      onChange={(e) => setActiveIngredients(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityApplied">Quantity Applied</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the quantity applied..."
                      value={quantityApplied}
                      onChange={(e) => setQuantityApplied(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Applied Unit</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={quantityAppliedUnit}
                      onChange={(e) => setQuantityAppliedUnit(e.target.value)}
                    >
                      <option value="">Select Ingredient Applied Type...</option>
                      {ingredientQuantityUnitList && ingredientQuantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="source">Source</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                    >
                      {sourceList && sourceList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="appliedBy">Applied By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the applied by..."
                      value={appliedBy}
                      onChange={(e) => {setAppliedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="associatedCost">Associated Cost <em>(in GHS)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Associated Cost"
                      id="associatedCost"
                      value={associatedCost}
                      onChange={(e) => setAssociatedCost(e.target.value)}
                    />
                  </div>
                </div>

                

              </div>
              
              <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(weedRecordId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WeedForm;