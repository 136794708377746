import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import useAuth from '../../hooks/useAuth';
import Navbar from '../../layout/Navbar';
import { baseURL, handleNotOkHttpResponse } from '../../utils/misc';
import Preloader from "../../components/common/Preloader";
import { getWorkflowStageById } from '../../api/WorkflowStage/getWorkflowStageById';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

function StagesTransition() {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const [selectedNextStageId, setSelectedNextStageId] = useState(null);
  const [theChecklists, setTheChecklists] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const { currentWorkflowStageId } = useParams();
  const [showPreloader, setShowPreloader] = useState(false);
  const [nextStageId, setNextStageId] = useState(null);
  const [label, setLabel] = useState("");
  const [showLabelInput, setShowLabelInput] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(true);
  const [deletedStages, setDeletedStages] = useState([]); // Track removed stages

  var workflowProcessId;

  useEffect(() => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }
  }, []);

  useEffect(() => {
    if (currentWorkflowStageId) {
      fetchAvailableWorkflowStages();
    }
  }, [tableData, currentWorkflowStageId, workflowProcessId]);

  useEffect(() => {
    setTheChecklists(selectedStages);
  }, [selectedStages])

  useEffect(() => {
    if (currentWorkflowStageId) {
      getAllNextWorkflowStagesNow();
    }
  }, [currentWorkflowStageId]);

  const getAllNextWorkflowStagesNow = async () => {
    setShowPreloader(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };

      const response = await fetch(`${baseURL}/workflowtransitions/currentworkflowstage/${currentWorkflowStageId}`, requestOptions);

      if (!response.ok) {
        throw new Error('Error fetching next workflow stages');
      }

      const allNextWorkflowStages = await response.json();

      if (allNextWorkflowStages) {
        setSelectedStages(allNextWorkflowStages);
      } else {
        setSelectedStages([]);
      }

    } catch (error) {
      console.error("Error fetching next workflow stages:", error);
      setSelectedStages([]);
    } finally {
      setShowPreloader(false);
    }
  };

  const updateTableData = (formData) => {
    setTableData([...tableData, formData]);
  };

  const handleShow = (stageId) => {
    setShow(true);
    setSelectedNextStageId(stageId);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedNextStageId(null);
  };

  const handleRemoveHeader = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }
    try {

      if (!selectedNextStageId) {
        console.error('No selected Services Provider ID');
        return;
      }

      const data = {
        id: selectedNextStageId,
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
        redirect: "follow",
        body: JSON.stringify(data)
      };

      const response = await fetch(`${baseURL}/workflowtransitions/${selectedNextStageId}`, requestOptions);
      if (response.ok) {
        toast.success("next-workflow-stage removed successfully");
        handleClose();
        setSelectedStages(prev => prev.filter(stage => stage.id !== selectedNextStageId));
        removeStageFromTable(selectedNextStageId);
      } else {
        handleNotOkHttpResponse(response);
        return false;
      }
    } catch (error) {
      console.error("Error on removing next-workflow-stage:", error.message);
    }
  };

  const fetchAvailableWorkflowStages = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }
  
    setShowPreloader(true);
  
    var currentWorkflowStage = await getWorkflowStageById(user.user.token, currentWorkflowStageId);
    setCurrentStage(currentWorkflowStage);
  
    if (!currentWorkflowStage) {
      toast.warn('The current workflow stage is missing!');
      return;
    }
  
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };
  
      const response = await fetch(`${baseURL}/workflowstages/workflowProcess/${currentWorkflowStage.workflowProcessId}`, requestOptions);
  
      if (!response.ok) {
        throw new Error('Error fetching available workflow stages');
      }
  
      const data = await response.json();

      //Get only the stages available
      let availableStages = data.filter(m => m.id !== currentWorkflowStageId);
      const selectedStagesDict = new Set(selectedStages.map(m => m.nextStageID));
      availableStages = availableStages.filter(m => !selectedStagesDict.has(m.id));
  
      // Update the state with the available stage IDs
      setAllStages(availableStages);
    } catch (error) {
      toast.error("Error fetching available workflow stages.");
      console.error("Error fetching available workflow stages:", error);
    } finally {
      setShowPreloader(false);
    }
  };
  
  
  const handleStageSelection = (value) => {
    setNextStageId(value);
    setShowLabelInput(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowPreloader(true);
    try {
      if (!nextStageId) {
        toast.warn('Please select a stage and provide a label.');
        return;
      }
      
      const data = {
        currentStageID: currentWorkflowStageId,
        nextStageID: nextStageId,
        label: label
      };
  
      const response = await fetch(`${baseURL}/workflowtransitions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
        body: JSON.stringify(data), 
      });
  
      if (response.ok) {
        toast.success('Workflow transition updated successfully.');
        setNextStageId('');
        setLabel('');
        setSelectedStages(prev => [...prev, data]);
        updateTableData(data);
        
        setAllStages(prev => prev.filter(stage => stage !== nextStageId));
      } else {
        const errorData = await response.text();
        console.error('Error updating workflow transition:', errorData);
        toast.error('Error updating workflow transition.');
      }
    } catch (error) {
      console.error('Error updating workflow transition:', error);
      toast.error('Error updating workflow transition.');
    } finally {
      setShowPreloader(false);
    }
  };
  
  const removeStageFromTable = (stageId) => {
    const removedStage = tableData.find(stage => stage.id === stageId);
    if (removedStage) {
      setDeletedStages([...deletedStages, removedStage]);
      setAllStages(prev => [...prev, removedStage.nextStageID]);
    }
    setTableData(tableData.filter(stage => stage.id !== stageId));
  };
  
  

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Workflow Stage</li>
              <li className="breadcrumb-item active">Workflow Transitions</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          <div className="main-container">
            <div className="row gutter card">
              <div className="col-lg-12 mb10 mt-2">
                <h4 className="breadcrumb_title">
                  Workflow Transition for Workflow Stage: {currentStage ? '\'' + currentStage.name + '\'' : (<em>No current stage selected</em>)}
                </h4>
              </div>
              <div className="col-lg-12 card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {theChecklists.length > 0 ? (
                      <div className="table-responsive">
                        <table id="data-table" className="table custom-table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Next Workflow Stage</th>
                              <th>Label</th>
                              <th><div className="float-end">Actions</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            {theChecklists?.map((stage,index) => (
                              <tr key={index}>
                                <td>{stage.nextWorkflowStage ? stage.nextWorkflowStage.name:""}</td>
                                <td>{stage.label}</td>
                                <td>
                                  <button className="btn btn-dark float-end" onClick={() => handleShow(stage.id)}>Remove</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No Stage Yet Selected as Transition Stage</p>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Workflow Stages Available for Selection</div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="form-group row gutters">
                        <label htmlFor="currentStage" className="col-sm-2 col-form-label">Unassigned Stages:</label>
                        <div className="col-sm-10">
                          <select
                            id="currentStage"
                            className="form-control"
                            value={nextStageId}
                            onChange={(e) => handleStageSelection(e.target.value)}
                          >
                            <option value="">Select the Next Workflow Stage...</option>
                            {allStages.map((stage) => {
                              return (
                                <option key={stage.id} value={stage.id}>
                                  {stage.order + " - " + stage.name}
                                </option>
                              );
                            })}
                          </select>

                        </div>
                      </div>
                      <div className="form-group row gutters">
                        <label htmlFor="label" className="col-sm-2 col-form-label">Label:</label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="label"
                            id="label"
                            value={label}
                            className="form-control"
                            onChange={e => setLabel(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-primary float-end"
                          disabled={!nextStageId}
                        >
                          Add as a Next Level Workflow Stage
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Deletion confirmation dialog modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
          <Modal.Title style={{ color: 'white' }}>Remove Next Workflow Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">Are you sure you want to remove this next workflow stage label?</p>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveHeader}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StagesTransition;
