import { Navigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../../components/common/Preloader";
import WorkflowStageInstructionTable from "./WorkflowStageInstructionTable";

const WorkflowStageInstruction = ({workflowStageIdParam}) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [workflowProcessesList, setWorkflowProcessesList] = useState([]);
    const [workflowStagesList, setWorkflowStagesList] = useState([]);
    const [workflowProcessId, setWorkflowProcessId] = useState(workflowStageIdParam);
    const [workflowStageId, setWorkflowStageId] = useState(workflowStageIdParam);
    const [stageInstructionLists, setStageInstructionlists] = useState([]);

    const [showPreloader, setShowPreloader] = useState(false);

    useEffect(() => {
      if(workflowStageIdParam) {
        setWorkflowStageId(workflowStageIdParam);
      }
      getSetupData();

      if (workflowStageId) {
        fetchLineParametersChecklists(workflowStageId);
      }
    }, []);

    useEffect(() => {
    }, [stageInstructionLists]);

    const getSetupData = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        
        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          }
        };
    
        setShowPreloader(true);
        // Fetch workflowprocesses
        fetch(`${baseURL}/workflowprocesses`, requestOptions)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
                console.error('Error fetching Workflow Processes.')
            }
          })
          .then((data) => {
            // Set the useState valuations with the fetched data
            setWorkflowProcessesList(data);
          })
          .catch((error) => {
            toast.error("Error fetching Workflow Processes.");
        })
        .finally((done) => {setShowPreloader(false); });

        if (workflowProcessId) {
          fetchLineParametersChecklists(workflowProcessId);
        }
    };

    
    const getSetWorkflowStages = async (workflowProcessId) => {
      if (!(user && user.user.token)) {
          navigate('/login');
      }
      
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        }
      };
  
      setShowPreloader(true);
      // Fetch workflowprocesses
      fetch(`${baseURL}/workflowstages/workflowprocess/${workflowProcessId}`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
              console.error('Error fetching Workflow Stages.')
          }
        })
        .then((data) => {
          // Set the useState valuations with the fetched data
          setWorkflowStagesList(data);
        })
        .catch((error) => {
          toast.error("Error fetching Workflow Stages.");
      })
      .finally((done) => {setShowPreloader(false); });
      if (workflowStageId) {
        fetchLineParametersChecklists(workflowStageId);
      }
  };

    
    const handleWorkflowStageChange = (event) => {
      workflowStageIdParam = null;
      const selectedId = event.target.value;
      setWorkflowStageId(selectedId);

      if(!selectedId) return;

      fetchLineParametersChecklists(selectedId);
    };
  
    const fetchLineParametersChecklists = (workflowStageId) => {
      if (!(user && user.user.token)) {
        navigate("/login");
        return; // Return early if user is not authenticated
      }
  
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };
  
      setShowPreloader(true);
      // Fetch line clearance checklists
      fetch(
        `${baseURL}/workflow-stage-instructions/workflowStage/${workflowStageId}`,
        requestOptions
      )
        .then((response) => { 
          if (response.ok) {
            return response.json();
          } else {
            console.error("Error fetching Workflow Stage Instruction.");
            throw new Error("Error fetching Workflow Stage Instruction.");
          }
        })
        .then((data) => {
          setStageInstructionlists(data);
        })
        .catch((error) => {
          toast.error(error.message || "Error fetching Concentrate Preparations.");
        })
        .finally((done) => {setShowPreloader(false); });
    };
      
    return (
      <>
        <Preloader show={showPreloader} />
        {/* Row start */}
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Workflow Stage Instructions</div>
              </div>
              <div className="card-body pt-0">
                <div className="form-group row gutters">
                  <label
                    htmlFor="bmrProductTypeId"
                    className="col-sm-2 col-form-label"
                  >
                    Workflow Process:
                  </label>
                  <div className="col-sm-10">
                    <select
                      id="workflowProcessId"
                      className="form-control form-control-lg"
                      value={workflowProcessId}
                      onChange={(e) => {setWorkflowProcessId(e.target.value); getSetWorkflowStages(e.target.value); }}
                    >
                      <option value="">Select WorkfLow Process...</option>
                      {workflowProcessesList.map((workflowProcess) => (
                        <option
                          key={workflowProcess.id}
                          value={workflowProcess.id}
                        >
                          {workflowProcess.order+1 + " - " + workflowProcess.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row gutters">
                  <label
                    htmlFor="bmrProductTypeId"
                    className="col-sm-2 col-form-label"
                  >
                    Workflow Stage:
                  </label>
                  <div className="col-sm-10">
                    {workflowStagesList && workflowStagesList.length > 0 && (
                      <select
                        id="workflowStageId"
                        className="form-control form-control-lg"
                        value={workflowStageId}
                        onChange={handleWorkflowStageChange}
                      >
                        <option value="">Select WorkfLow Stage...</option>
                        {workflowStagesList.map((workflowStage) => (
                          <option
                            key={workflowStage.id}
                            value={workflowStage.id}
                          >
                            {workflowStage.order + 1 + " - " + workflowStage.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                {workflowStageId && (
                  <>
                    <hr />
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Link
                          to={`/bmrsetups/workflow-stage-instructions/add/workflowStage/${workflowStageId}`}
                          className="btn btn-primary float-end"
                        >
                          Add New Workflow Stage Instruction
                        </Link>
                      </div>
                    </div>
                    <br />

                    <WorkflowStageInstructionTable
                      checklists={stageInstructionLists}
                      workflowStageId={workflowStageId}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Row end */}
      </>
    );
}
 
export default WorkflowStageInstruction;