import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";

const WorkflowStageInstructionTable = ({checklists, workflowStageId}) => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const [show, setShow] = useState(false);
    const [selectedInstructionId, setSelectedInstructionId] = useState(null);
    const [theChecklist, setTheChecklist] = useState([]);
    
    useEffect(() => {
        setTheChecklist(checklists);
    }, [checklists])

    useEffect(() => {
    }, [theChecklist])

    const tableHeaders = [
        "Instructions",
        "Actions"
    ];


    const handleShow = (stageId) => {
        setShow(true);
        setSelectedInstructionId(stageId);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedInstructionId(null);
    };

    const handleRemoveHeader = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }
        try {

            if (!selectedInstructionId) {
                console.error('No selected Services Provider ID');
                return;
            }

            const data = {
                id: selectedInstructionId,
            }
            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
                body: JSON.stringify(data)
            };

            const response = await fetch(`${baseURL}/workflow-stage-instructions/${selectedInstructionId}`, requestOptions);
            if (response.ok) {
                toast.success("Workflow Stage Instruction removed successfully.");
                handleClose();
                setTheChecklist(theChecklist.filter(item => item.id !== selectedInstructionId));
                // navigate(window.location.pathname, { replace: true });
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing workflow-stage-instruction:", error.message);
        }
    };

    return (
        <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="table-responsive">
                <table id="data-table" className="table custom-table">
                    <thead className="thead-dark">
                        <tr>
                          {tableHeaders.map(header => (
                            <th key={header}>
                              {header === "Actions" ? (<div className="float-end">{header}</div>) : header}
                            </th>
                          ))}
                        </tr>
                    </thead>
                    <tbody>
                        {theChecklist && theChecklist.length > 0 ? theChecklist.map((checklist) => (
                            <tr key={checklist.id}>
                                <td>{checklist.instructions}</td>
                                <td className="">
                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                        <div className="dropdown">
                                            <a
                                                className="btn btn-secondary btn-sm dropdown-toggle dn-1199 ms-2"
                                                role="button"
                                                href="#"
                                                id={"dropdownMenuLink" + checklist.id}
                                                data-bs-toggle="dropdown"
                                            >
                                                Actions <i className="fa fa-caret-down"></i>
                                            </a>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby={"dropdownMenuLink" + checklist.id}
                                            >
                                                <li>
                                                    <Link
                                                        to={`/bmrsetups/workflow-stage-instructions/add/workflowStage/${workflowStageId}/${checklist.id}`}
                                                        key="1"
                                                        className="dropdown-item"
                                                        style={
                                                            isSinglePageActive(`${`/bmrsetups/workflow-stage-instructions/add/workflowStage/${workflowStageId}/${checklist.id}`}`, navigate.pathname)
                                                                ? { color: "#ff5a5f" }
                                                                : undefined
                                                        }
                                                    ><i className="flaticon-transfer-1"></i>&nbsp;&nbsp;Edit</Link>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="dropdown-item"
                                                        onClick={() => handleShow(checklist.id)}
                                                    >
                                                        <i className="flaticon-transfer-1"></i>&nbsp;&nbsp;Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </td>
                            </tr>
                        ))  : (<tr><td colSpan={tableHeaders.length} className="text-center"><em>No data available!</em></td></tr>)}
                    </tbody>
                </table>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                        <Modal.Title style={{ color: 'white' }}>Delete Workflow Stage Instruction</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-4">
                            Are you sure you want to delete this workflow-stage-instruction?
                        </p>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveHeader}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    </div>
    );
}
 
export default WorkflowStageInstructionTable;