import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import WarehouseFormBasic from "./WarehouseFormBasic";

const WarehouseForm = ({ warehouseRecord,setWarehouseRecord,crop }) => {
  const { user } = useAuth();

  const [warehouseId, setWarehouseId] = useState(null);
  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    if (warehouseRecord) {
      setWarehouseId(warehouseRecord.id);

      if (warehouseRecord.environmentalCondition) {
        setEnvironmentalConditionId(warehouseRecord.environmentalCondition.id);
        setEnvironmentalCondition(warehouseRecord.environmentalCondition);
      }
    }
  }, [warehouseRecord]);



  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      cropId:crop.id,
      date: warehouseRecord.date,
      inventoryManagementSystem: warehouseRecord.inventoryManagementSystem,
      name: warehouseRecord.name,
      contactInformation: warehouseRecord.contactInformation,
      location: warehouseRecord.location,
      manager: warehouseRecord.manager,
      operationalHours: warehouseRecord.operationalHours,
      capacity: warehouseRecord.capacity,
      productsStored:warehouseRecord.productsStored,
      storageConditions:warehouseRecord.storageConditions,
      storageType:warehouseRecord.storageType,
      securityMeasures:warehouseRecord.securityMeasures,
      certification:warehouseRecord.certification,
      certificationAttachment:warehouseRecord.certificationAttachment,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/warehouserecords`, requestOptions);

      if (response.ok) {
        toast.success("The Warehouse Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/warehouserecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the warehouserecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: warehouseId,
      cropId:crop.id,
      date: warehouseRecord.date,
      inventoryManagementSystem: warehouseRecord.inventoryManagementSystem,
      name: warehouseRecord.name,
      contactInformation: warehouseRecord.contactInformation,
      location: warehouseRecord.location,
      manager: warehouseRecord.manager,
      operationalHours: warehouseRecord.operationalHours,
      capacity: warehouseRecord.capacity,
      productsStored:warehouseRecord.productsStored,
      storageConditions:warehouseRecord.storageConditions,
      storageType:warehouseRecord.storageType,
      securityMeasures:warehouseRecord.securityMeasures,
      certification:warehouseRecord.certification,
      certificationAttachment:warehouseRecord.certificationAttachment,
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/warehouserecords/${warehouseId}`, requestOptions);

      if (response.ok) {
        toast.success("The Warehouse record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/warehouserecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the warehouse record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Warehouse Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/warehouserecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <WarehouseFormBasic crop={crop} warehouseRecord={warehouseRecord} setWarehouseRecord={setWarehouseRecord} handleSubmit={warehouseId ? handleSubmitEdit : handleSubmitCreate} />
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WarehouseForm;