export const runningCompanyId = process.env.REACT_APP_COMPANY_ID;

export const companySetup = [
    {
        id:'SYNERGY_TRACE_SYS',
        name: 'Synergy Traceability System',
        logo: 'Synergy Traceability System_Logo_Portrait.jpeg',
        logoTiny: 'Synergy Traceability System_Logo_Landscape.jpeg',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
            'OUT-GROWERS',
        ]
    },
    {
        id:'SUGARLAND_LTD',
        name: 'Sugarland Ltd',
        logo: 'SugarlandLtd.png',
        logoTiny: 'SugarlandLtd.png',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id:'BB_HARMONICS',
        name: 'BB Harmonics',
        logo: 'BBHarmonics.png',
        logoTiny: 'BBHarmonics.png',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id:'360_NATURALS',
        name: '360 Naturals',
        logo: '360Naturals.png',
        logoTiny: '360Naturals.png',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
            'OUT-GROWERS',
        ]
    },
    {
        id:'TROPIGHA_FARMS_LTD',
        name: 'Tropigha Farms Ltd',
        logo: 'TropighaFarmsLtd.jpg',
        logoTiny: 'TropighaFarmsLtd.jpg',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id:'SV_NOBEL_ENT',
        name: 'SV Noble Enterprise',
        logo: 'SVNobelEnt.JPG',
        logoTiny: 'SVNobelEnt.JPG',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
]

// Utility function to find a company by its ID
export const getCompanyById = (id) => {
    return companySetup.find(company => company.id === id);
};

// Utility function to check if a string exists in the module array (case-insensitive)
export const moduleExists = (moduleArray, searchString) => {
    return moduleArray.some(module => module.toLowerCase().includes(searchString.toLowerCase()));
};

export const getRunningCompany = () => {
    return getCompanyById(runningCompanyId);
};

export const runningCompany = getRunningCompany();
export const runningCompanyName = runningCompany.name;

export const runningCompanyHasModule = (moduleToSearch) => {
    return moduleExists(runningCompany.modules, moduleToSearch);
}
