import { useEffect, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import { getAllNextWorkflowStages } from "../../../api/WorkflowStage/getAllNextWorkflowStages";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../utils/misc";

const ReferencedFormStageInstructions = ({ workflowStageId }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [workflowStateInstructions, setWorkflowStateInstructions] = useState(
    []
  );

  useEffect(() => {
    fetchStageInstructions();
  }, []);

  useEffect(() => {}, [workflowStateInstructions]);

  const fetchStageInstructions = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      return;
    }
    setShowPreloader(true);

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };
      const response = await fetch(
        `${baseURL}/workflow-stage-instructions/workflowStage/${workflowStageId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(
          "Error fetching instructions for the current workflow stage!"
        );
      }

      const data = await response.json();
      if (data) {
        setWorkflowStateInstructions(data);
      }
    } catch (error) {
      toast.error(
        "Error fetching instructions for the current workflow stage!"
      );
    } finally {
      setShowPreloader(false);
    }
  };

  let count = 0;

  return (
    <>
      <Preloader show={showPreloader} />
      {workflowStateInstructions && workflowStateInstructions.length > 0 && (<>
        <div className="p-2 workflow-stage-instructions">
          <div><em><strong>Instructions:</strong></em></div>
          {workflowStateInstructions.map((instructionData) => (
            <span key={count++} dangerouslySetInnerHTML={{ __html: instructionData.instructions }}></span>
          ))}
        </div>
        <hr />
      </>)}
    </>
  );
};

export default ReferencedFormStageInstructions;
