import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Preloader from "../common/Preloader";
import {fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import FileInput from "../common/FileInput";
import EnvironmentalConditionNew from "../common/EnvironmentalConditionNew";

const WarehouseFormBasic = ({ warehouseRecord, setWarehouseRecord, handleSubmit }) => {

  const [environmentalCondition, setEnvironmentalCondition] = useState();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    if (warehouseRecord) {
      if (warehouseRecord.environmentalCondition) {
        setEnvironmentalCondition(warehouseRecord.environmentalCondition);
      }
    }
  }, [warehouseRecord]);



  const validateFields = () => {
    let retVal = true;

    if (!warehouseRecord.date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!warehouseRecord.storageType) {
      fieldWithIdRequired('storageType');
      toast.error("Storage Type is required.");
      retVal = false;
    }

    if (!warehouseRecord.storageConditions) {
      fieldWithIdRequired('storageConditions');
      toast.error("Storage Conditions is required.");
      retVal = false;
    }

    if (!warehouseRecord.manager) {
      fieldWithIdRequired('manager');
      toast.error("Manager is required.");
      retVal = false;
    }

    if (!warehouseRecord.capacity) {
      fieldWithIdRequired('capacity');
      toast.error("Capacity is required.");
      retVal = false;
    }

    if (!warehouseRecord.location) {
      fieldWithIdRequired('location');
      toast.error("Location is required.");
      retVal = false;
    }

    if (!warehouseRecord.contactInformation) {
      fieldWithIdRequired('contactInformation');
      toast.error("Contact Information is required.");
      retVal = false;
    }

    if (!warehouseRecord.name) {
      fieldWithIdRequired('name');
      toast.error("Supervised By is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }



    return retVal;
  };

  const handleChange = (field, value, required) => {
    const updatedItem = { ...warehouseRecord }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setWarehouseRecord(updatedItem); // Update the state with the modified copy
    if(required) fieldWithIdRequired(field); //Show UI validation
  };

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="row gutters">

        {warehouseRecord && warehouseRecord.id &&
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={warehouseRecord.code ? warehouseRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Date *</label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={isoToDate(warehouseRecord && warehouseRecord.date)}
              onChange={(e) => handleChange("date", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter the name..."
              id="name"
              value={warehouseRecord && warehouseRecord.name}
              onChange={(e) => handleChange("name", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Location *</label>
            <input
              type="text"
              className="form-control"
              id="location"
              placeholder="Enter the location..."
              value={warehouseRecord && warehouseRecord.location}
              onChange={(e) => handleChange("location", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantity">Capacity *</label>
            <input
              type="number"
              className="form-control"
              id="capacity"
              placeholder="Enter the capacity..."
              value={warehouseRecord && warehouseRecord.capacity}
              onChange={(e) => handleChange("capacity", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Storage Conditions *</label>
            <textarea
              rows="2"
              className="form-control"
              id="storageConditions"
              placeholder="Enter the storage Conditions..."
              value={warehouseRecord && warehouseRecord.storageConditions}
              onChange={(e) => handleChange("storageConditions", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Manager *</label>
            <input
              type="text"
              className="form-control"
              id="manager"
              placeholder="Enter the manager..."
              value={warehouseRecord && warehouseRecord.manager}
              onChange={(e) => handleChange("manager", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="appliedBy">Contact Information *</label>
            <input
              type="tel"
              className="form-control"
              id="contactInformation"
              placeholder="Enter the contact information..."
              value={warehouseRecord && warehouseRecord.contactInformation}
              onChange={(e) => handleChange("contactInformation", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Storage Type *</label>
            <input
              type="text"
              className="form-control"
              id="storageType"
              placeholder="Enter the storage Type..."
              value={warehouseRecord && warehouseRecord.storageType}
              onChange={(e) => handleChange("storageType", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>



        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Products Stored</label>
            <textarea
              rows="2"
              className="form-control"
              id="productsStored"
              placeholder="Enter the products stored..."
              value={warehouseRecord && warehouseRecord.productsStored}
              onChange={(e) => handleChange("productsStored", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Security Measures</label>
            <textarea
              rows="2"
              className="form-control"
              id="securityMeasures"
              placeholder="Enter the security measures..."
              value={warehouseRecord && warehouseRecord.securityMeasures}
              onChange={(e) => handleChange("securityMeasures", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Certification</label>
            <input
              type="text"
              className="form-control"
              id="certification"
              placeholder="Enter the certification..."
              value={warehouseRecord && warehouseRecord.certification}
              onChange={(e) => handleChange("certification", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="attachments">Attach Certification File</label>
            <FileInput id={"attachments"}
              value={warehouseRecord && warehouseRecord.certificationAttachment}
              onChange={(e) => handleChange("certificationAttachment", e.target.value, false)}
              required={false}
              className="form-control" />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Operational Hours</label>
            <input
              type="text"
              className="form-control"
              id="operationalHours"
              placeholder="Enter the operationalHours..."
              value={warehouseRecord && warehouseRecord.operationalHours}
              onChange={(e) => handleChange("operationalHours", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Inventory Management System</label>
            <input
              type="text"
              className="form-control"
              id="inventoryManagementSystem"
              placeholder="Enter the inventory management system..."
              value={warehouseRecord && warehouseRecord.inventoryManagementSystem}
              onChange={(e) => handleChange("inventoryManagementSystem", e.target.value, false)}
            />
          </div>
        </div>

      </div>
      <br />
      <EnvironmentalConditionNew transferRecord={warehouseRecord} setTransferRecord={setWarehouseRecord} />
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if (validateFields()) { handleSubmit(e); } }}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WarehouseFormBasic;