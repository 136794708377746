import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import { getLogisticsRecordsByCropId } from "../../api/LogisticsRecords/getLogisticsList";
import { Modal } from "react-bootstrap";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import LogisticsTable from "./LogisticsTable";
import LogisticsFormBasic from "./LogisticsFormBasic";

function LogisticsRecords({ crop }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [logisticsRecords, setLogisticsRecords] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [logisticsRecord, setLogisticsRecord] = useState(null);
    const [logisticsId, setLogisticsId] = useState(null);
    const isAdmin = user && user.user && hasAdminRole(user.user.roles);
    //env conditions data
    const [environmentalCondition, setEnvironmentalCondition] = useState();
    const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

    useEffect(() => {
        if (!user || !user.user || !user.user.token) {
            toast.warn("You are not authorized to do this!");
            navigate("/");
            return;
        }

        if (crop) {
            getLogisticsRecordsByCropId(user.user.token, crop.id)
                .then(records => setLogisticsRecords(records))
                .catch(error => {
                    toast.error("Error fetching logistics records.");
                    console.error(error);
                });
        }
    }, [crop, user, navigate]);

    useEffect(() => {
        if (logisticsRecord) {
          setLogisticsId(logisticsRecord.id);
    
          if (logisticsRecord.environmentalCondition) {
            setEnvironmentalConditionId(logisticsRecord.environmentalCondition.id);
            setEnvironmentalCondition(logisticsRecord.environmentalCondition);
          }
        }
      }, [logisticsRecord]);

    const handleModalShow = (record = null) => {
        setLogisticsRecord(record || {
            cropId: crop.id,
            date: '',
            environmentalCondition: {
                temperature: '',
                weatherConditions: '',
                humidity: '',
                rainfall: '',
                windSpeed: '',
                recordedDate: '',
                effects: ''
            },
            departureLocation: '',
            destinationLocation: '',
            dispatchTime: '',
            expectedDeliveryTime: '',
            logisticsProvider: '',
            quantity: null,
            quantityUnit: '',
            transportMode: '',
            actualDeliveryTime: '',
            vehicleDetails: '',
            documentation: '',
            documentationFile: '',
            status: '',
            comments: ''
        });
        setLogisticsId(record ? record.id : null);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleSubmitCreate = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }

        const data = {
            cropId: crop.id,
            date: logisticsRecord.date,
            expectedDeliveryTime: logisticsRecord.expectedDeliveryTime,
            actualDeliveryTime: logisticsRecord.actualDeliveryTime,
            dispatchTime: logisticsRecord.dispatchTime,
            departureLocation: logisticsRecord.departureLocation,
            logisticsProvider: logisticsRecord.logisticsProvider,
            status: logisticsRecord.status,
            destinationLocation: logisticsRecord.destinationLocation,
            transportMode: logisticsRecord.transportMode,
            comments: logisticsRecord.comments,
            vehicleDetails: logisticsRecord.vehicleDetails,
            documentation: logisticsRecord.documentation,
            documentationFile: logisticsRecord.documentationFile,
            quantity: logisticsRecord.quantity,
            quantityUnit: logisticsRecord.quantityUnit,
            environmentalCondition,
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
        };

        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/logisticsrecords`, requestOptions);
            if (response.ok) {
                toast.success("The Logistic Record has been successfully created.");
                getLogisticsRecordsByCropId(user.user.token, crop.id)
                    .then(records => setLogisticsRecords(records))
                    .catch(error => {
                        toast.error("Error fetching updated logistics records.");
                        console.error(error);
                    });
                handleModalClose();
            } else {
                const errorText = await response.text(); // Get raw text response
                console.error('Error response text:', errorText);
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error creating the logistics record.");
            console.error('Fetch error:', error);
        } finally {
            setShowPreloader(false);
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            return;
        }

        const data = {
            id: logisticsId,
            cropId: crop.id,
            date: logisticsRecord.date,
            expectedDeliveryTime: logisticsRecord.expectedDeliveryTime,
            actualDeliveryTime: logisticsRecord.actualDeliveryTime,
            dispatchTime: logisticsRecord.dispatchTime,
            departureLocation: logisticsRecord.departureLocation,
            logisticsProvider: logisticsRecord.logisticsProvider,
            status: logisticsRecord.status,
            destinationLocation: logisticsRecord.destinationLocation,
            transportMode: logisticsRecord.transportMode,
            comments: logisticsRecord.comments,
            vehicleDetails: logisticsRecord.vehicleDetails,
            documentation: logisticsRecord.documentation,
            documentationFile: logisticsRecord.documentationFile,
            quantity: logisticsRecord.quantity,
            quantityUnit: logisticsRecord.quantityUnit,
            environmentalConditionId,
            environmentalCondition
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
        };

        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/logisticsrecords/${logisticsId}`, requestOptions);
            if (response.ok) {
                toast.success("The Logistic record has been successfully updated.");
                getLogisticsRecordsByCropId(user.user.token, crop.id)
                    .then(records => setLogisticsRecords(records))
                    .catch(error => {
                        toast.error("Error fetching updated logistics records.");
                        console.error(error);
                    });
                handleModalClose();
            } else {
                const errorText = await response.text(); // Get raw text response
                console.error('Error response text:', errorText);
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error updating the Logistic record.");
            console.error('Fetch error:', error);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card" style={{ backgroundColor: "#eff1f5" }}>
                            <div className="card-header">
                                <div className="card-title">
                                    Logistics Records {crop ? " - " + getFullCropName(crop) : ""}
                                </div>
                                {isAdmin && (
                                    <Link
                                        onClick={() => handleModalShow()}
                                        className="btn btn-primary float-end"
                                    >
                                        <i className="icon-add"></i> Add Logistics Record
                                    </Link>
                                )}
                            </div>
                            {logisticsRecords.length > 0 && (
                                <LogisticsTable
                                    logisticsRecords={logisticsRecords}
                                    logisticsRecord={logisticsRecord}
                                    setLogisticsRecord={setLogisticsRecord}
                                    crop={crop}
                                    allowEdit={true}
                                    handleSubmit={logisticsId ? handleSubmitEdit : handleSubmitCreate}
                                    handleModalShow={handleModalShow}
                                    handleModalClose={handleModalClose}
                                    logisticsId={logisticsId}
                                    allowModalEdit={true}
                                />
                            )}
                        </div>
                        <Modal size="lg" show={showModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{logisticsId ? "Edit Logistics Record" : "Add Logistics Record"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LogisticsFormBasic
                                    crop={crop}
                                    logisticsRecord={logisticsRecord}
                                    setLogisticsRecord={setLogisticsRecord}
                                    handleSubmit={logisticsId ? handleSubmitEdit : handleSubmitCreate}
                                />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LogisticsRecords;
