import React, { useEffect, useState, } from 'react';

import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SideBarWrapper
  from '../../../../components/common/SideBarWrapper'; // Make sure you have this component imported
import useAuth from '../../../../hooks/useAuth';
import { baseURL } from '../../../../utils/misc';
import Navbar from '../../../../layout/Navbar';
import Preloader from "../../../../components/common/Preloader";

function UserProfile () {
  const {user} = useAuth();
  const [showPreloader, setShowPreloader] = useState(false);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [otherNames, setOtherNames] = useState();
  const [username, setUsername] = useState();
  const [designation, setDesignation] = useState();
  const [email, setEmail] = useState();
  const [telephone, setTelephone] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [profilePictureFile, setProfilePictureFile] = useState();

  

  useEffect(() => {
    if(user && user.user)
      fetchUserInfo();
  }, []);


  const fetchUserInfo = async () => {
    const apiUrl = `${baseURL}/Users/${user.user.id}`;
    const token = user && user.user ? user.user.token : null;
    setShowPreloader(true);

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        if(userData) {
          updateStates(userData);
        }
      } else {
        console.error('Failed to fetch user information');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setShowPreloader(false);
    }
  };

  function updateStates(userData) {
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setOtherNames(userData.otherNames);
    setUsername(userData.username);
    setEmail(userData.email);
    setTelephone(userData.telephone);
    setDesignation(userData.designation);
    setProfilePicture(userData.profilePicture);

    console.log("update >> info:", userData);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!user || !user.user) {
      toast.warn("You are not authorized to do this!");
      return;
    }

    
    const formData = new FormData();
    formData.append("Id", user.user.id);
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);
    if(otherNames) formData.append("OtherNames", otherNames);
    formData.append("Username", username);
    formData.append("Email", email);
    formData.append("Designation", designation);
    formData.append("Telephone", telephone);
    if(profilePictureFile) formData.append("ProfilePicture", profilePictureFile, profilePictureFile.name);

  
    const requestOptions = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
      body: formData,
      redirect: "follow",
    };
    const apiUrl = `${baseURL}/Users/userinformation/${user.user.id}`;

    setShowPreloader(true);
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if(data) updateStates(data);
        setProfilePictureFile(undefined);
        toast.success("Update successful");
      } else {
        console.error('Failed to update user information');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Loading ends */}

      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <SideBarWrapper />
        {/* Sidebar wrapper end */}

        {/* Page content start  */}
        <div className="page-content">
          {/* Header start */}
          <Navbar />
          {/* <Toast/> */}
          {/* Header end */}

          {/* Page header start */}
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">User Profile</li>
            </ol>

            <ul className="app-actions"></ul>
          </div>
          {/* Page header end */}

          {/* Main container start */}
          <div className="main-container">
            <div className="row gutters">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="account-settings">
                      <div className="user-profile">
                        <div className="user-avatar">
                          <img src={profilePicture} alt="Traceability_User_Picture" />
                        </div>
                        <h5 className="user-name">{username}</h5>
                        <h6 className="user-email">{email}</h6>
                      </div>
                      <div className="list-group">
                        <Link to="/changePassword" className="list-group-item">
                          Change Password
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-5 col-md-4 col-sm-4 col-12">
                <div className="card h-100">
                  <div className="card-header">
                    <div className="card-title">Update Profile</div>
                  </div>

                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="col-xl-12 col-lg-8 col-md-8 col-sm-8 col-12">
                        <div className="form-group">
                          <label htmlFor="profilePicture">
                            Profile Picture *<em>(choose an image)</em>*
                          </label>
                          <input
                            type="file" // Change input type to "url"
                            onChange={(e) => setProfilePictureFile(e.target.files[0]) }
                            className="form-control"
                            id="profilePrictureFile"
                            accept="image/*"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value) }
                            className="form-control"
                            id="firstName"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value) }
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="otherNames"
                            value={otherNames}
                            onChange={(e) => setOtherNames(e.target.value) }
                            className="form-control"
                            id="otherNames"
                            placeholder="Other Names"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value) }
                            className="form-control"
                            id="username"
                            placeholder="Username"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value) }
                            className="form-control"
                            id="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            name="telephone"
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value) }
                            className="form-control"
                            id="telephone"
                            placeholder="Telephone"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="designation"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value) }
                            className="form-control"
                            id="designation"
                            placeholder="designation"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="text-right">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Main container end */}
        </div>
        {/* Page content end */}
      </div>
      {/* Page wrapper end */}
    </>
  );
};
export default UserProfile;
