import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import TransferTable from "./TransferTable";
import { Modal } from "react-bootstrap";
import TransferForm from "./TransferForm";

function TransferListing({ cropId }) {
    const { user } = useAuth();

    const [transferRecords, setTransferRecords] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [crop, setCrop] = useState();
    const [showAddModal, setShowAddModal] = useState(false);


    useEffect(() => {
        const fetchCrops = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/crops/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching crops');
                }
                const data = await response.json();
                setCrop(data);
            } catch (error) {
                toast.error("Error fetching crops.");
                console.error("Error fetching crops:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchCrops();
    }, [user, navigate, cropId]);

    useEffect(() => {
        const fetchTransferRecords = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/transferrecords/crop/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching transfer records');
                }
                const data = await response.json();
                setTransferRecords(data);
            } catch (error) {
                toast.error("Error fetching transfer records.");
                console.error("Error fetching transfer records:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchTransferRecords();
    }, [cropId]);

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">

                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of Transfer Records</div>
                                <div className="mt-3 p-2 parentLabel">
                                    <div className="mt-2 mb-1">
                                        <span>Crop: </span>{getFullCropName(crop)}
                                        <Link to={`/crops/field/${crop && crop.fieldId}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                                    </div>
                                </div>
                                <br/>
                                <Link
                                    to={`/transferrecord/create/?crop=${cropId}`}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Transfer Record
                                </Link>
                                
                            </div>
                            <TransferTable transferRecords={transferRecords} allowEdit={true} />
                        </div>
                    </div>
                </div>

                
                {/* Add Modal */}
                <Modal show={showAddModal} onHide={(/*handleCloseDeleteModal*/ () => {})}>
                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                        <Modal.Title style={{ color: 'white' }}>Add Transfer Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TransferForm crop={crop} />
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        {/* <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveTransfer}>
                            Delete
                        </Button>  ***********************************************************/}
                    </Modal.Footer>
                </Modal>

            </div>

        </>
    );
}

export default TransferListing;