import React, { useEffect, useState } from 'react';
import Navbar from "../../layout/Navbar";
import SideBarWrapper from "../../components/common/SideBarWrapper";
import useAuth from "../../hooks/useAuth";
import { baseURL, hasRoleInWorkflowRoles } from "../../utils/misc";
import "./Home.css"; // Import the custom CSS file
import Footer from '../../layout/Footer';
import { fetchCropsData, fetchFarmData, fetchFarmerData, fetchFieldData, fetchRecords } from '../../api/HomeData/getHomeData';
import AttentionRequiredContracts from '../../components/Contracts/AttentionRequiredContracts';

function Home() {
	const { user } = useAuth();
	const [farmerId, setFarmerId] = useState(null);
	const [crops, setCrops] = useState([]);
	const [totalHarvests, setTotalHarvests] = useState(0);
	const [totalPlanting, setTotalPlanting] = useState(0);
	const [farmId, setFarmId] = useState(null);
	const [totalPackaging, setTotalPackaging] = useState(0);
	const [fieldId, setFieldId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const farmerData = await fetchFarmerData(user);
                if (farmerData && farmerData.id) {
                    setFarmerId(farmerData.id);
                }
            } catch (error) {
                console.error('Error fetching farmer data:', error);
            }
        };
        fetchData();
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            if (farmerId) {
                try {
                    const farmData = await fetchFarmData(farmerId, user);
                    if (farmData && farmData.length > 0) {
                        setFarmId(farmData[0].id);
                    }
                } catch (error) {
                    console.error('Error fetching farm data:', error);
                }
            }
        };
        fetchData();
    }, [farmerId, user]);

    useEffect(() => {
        const fetchData = async () => {
            if (farmId) {
                try {
                    const fieldData = await fetchFieldData(farmId, user);
                    if (fieldData && fieldData.length > 0) {
                        setFieldId(fieldData[0].id);
                    }
                } catch (error) {
                    console.error('Error fetching field data:', error);
                }
            }
        };
        fetchData();
    }, [farmId, user]);

    useEffect(() => {
        const fetchData = async () => {
            if (fieldId) {
                try {
                    const cropsData = await fetchCropsData(fieldId, user);
                    if (cropsData) {
                        setCrops(cropsData);
                    }
                } catch (error) {
                    console.error('Error fetching crops data:', error);
                }
            }
        };
        fetchData();
    }, [fieldId, user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const totalHarvestsCount = await fetchRecords(`${baseURL}/harvestingrecords`, crops.map(crop => crop.id), user);
                setTotalHarvests(totalHarvestsCount);
            } catch (error) {
                console.error('Error fetching harvesting records:', error);
            }
        };
        fetchData();
    }, [crops, user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const totalPlantingsCount = await fetchRecords(`${baseURL}/plantingrecords`, crops.map(crop => crop.id), user);
                setTotalPlanting(totalPlantingsCount);
            } catch (error) {
                console.error('Error fetching planting records:', error);
            }
        };
        fetchData();
    }, [crops, user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const totalPackagingsCount = await fetchRecords(`${baseURL}/packagingrecords`, crops.map(crop => crop.id), user);
                setTotalPackaging(totalPackagingsCount);
            } catch (error) {
                console.error('Error fetching packaging records:', error);
            }
        };
        fetchData();
    }, [crops, user]);

	return (
		<>
			<div className="page-wrapper">
				<SideBarWrapper farmerId={farmerId} />
				<div className="page-content">
					<Navbar />
					<div className="page-header">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">Home</li>
							<li className="breadcrumb-item active">Dashboard</li>
						</ol>
					</div>
					<div className="main-container">
						{user && user.user && user.user.roles && hasRoleInWorkflowRoles(user.user.roles) && (<>
							<AttentionRequiredContracts />
						</>)}

            <div className="row gutters">
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="info-stats4">
								<div className="info-icon">
									<i className="icon-gift"></i>
								</div>
								<div className="sale-num">
									<h3>95</h3>
									<p>Number of Orders</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="info-stats4">
								<div className="info-icon">
									<i className="icon-shopping_basket"></i>
								</div>
								<div className="sale-num">
									<h3>20</h3>
									<p>Fields: <em>Organic-15, Conventional-5</em></p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="info-stats4">
								<div className="info-icon">
									<i className="icon-shopping_basket"></i>
								</div>
								<div className="sale-num">
									<h3>35</h3>
									<p>Shipments</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
							<div className="info-stats4">
								<div className="info-icon">
									<i className="icon-format_color_fill"></i>
								</div>
								<div className="sale-num">
									<h3>5</h3>
									<p>Spoilage</p>
								</div>
							</div>
						</div>
					</div>

          <div className="row gutters">
						<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">Quick Stats</div>
								</div>
								<div className="card-body">
									<div className="customScroll5">
										<div className="quick-analytics">
											<a href="#">
												<i className="icon-shopping-cart1"></i> 500,000 New Orders
											</a>
											<a href="#">
												<i className="icon-package"></i> 325,010 Warehouses
											</a>
											<a href="#">
												<i className="icon-share1"></i> 250,000 Images Uploaded
											</a>
											<a href="#">
												<i className="icon-eye1"></i> 870,000 Monthly Visits
											</a>
											<a href="#">
												<i className="icon-shopping-cart1"></i> 500,000 New Orders
											</a>
											<a href="#">
												<i className="icon-shopping-bag1"></i> 950,000 Crops
											</a>
											<a href="#">
												<i className="icon-eye1"></i> 870,000 Monthly Visits
											</a>
											<a href="#">
												<i className="icon-bell"></i> 350,500 Tickets Booked
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">Activity</div>
								</div>
								<div className="card-body">
									<div className="customScroll5">
										<ul className="project-activity">
											<li className="activity-list">
												<div className="detail-info">
													<p className="date">Today</p>
													<p className="info">Messages accepted with attachments.</p>
												</div>
											</li>
											<li className="activity-list success">
												<div className="detail-info">
													<p className="date">Yesterday</p>
													<p className="info">Send email notifications of subscriptions and deletions to list owner.</p>
												</div>
											</li>
											<li className="activity-list danger">
												<div className="detail-info">
													<p className="date">10th December</p>
													<p className="info">Required change logs activity reports.</p>
												</div>
											</li>
											<li className="activity-list warning">
												<div className="detail-info">
												<p className="date">15th December</p>
													<p className="info">Strategic partnership plan.</p>
												</div>
											</li>
											<li className="activity-list success">
												<div className="detail-info">
													<p className="date">21st December</p>
													<p className="info">Send email notifications of subscriptions and deletions to list owner.</p>
												</div>
											</li>
											<li className="activity-list danger">
												<div className="detail-info">
													<p className="date">25th December</p>
													<p className="info">Required change logs activity reports.</p>
												</div>
											</li>
											<li className="activity-list warning">
												<div className="detail-info">
												<p className="date">28th December</p>
													<p className="info">Strategic partnership plan.</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">Export Contracts</div>
								</div>
								<div className="card-body">
									<div className="customScroll5">
										<ul className="user-messages">
											<li className="clearfix">
												<div className="customer">AM</div>
												<div className="delivery-details">
													<span className="badge badge-primary">Ordered</span>
													<h5>Aaleyah Malik</h5>
													<p>We are pleased to inform that the following ticket no. <b>Le Rouge510</b> have been booked.</p>
												</div>
											</li>
											<li className="clearfix">
												<div className="customer">AS</div>
												<div className="delivery-details">
													<span className="badge badge-primary">Delivered</span>
													<h5>Ali Sayed</h5>
													<p>The carrier successfully delivered the message to the end user.</p>
												</div>
											</li>
											<li className="clearfix">
												<div className="customer">ZR</div>
												<div className="delivery-details">
													<span className="badge badge-primary">Cancelled</span>
													<h5>Zaira Raheem</h5>
													<p>The following describe the status codes and messages states for delivery receipts.</p>
												</div>
											</li>
											<li className="clearfix">
												<div className="customer">LJ</div>
												<div className="delivery-details">
													<span className="badge badge-primary">Returned</span>
													<h5>Lily Jordan</h5>
													<p>Status codes and descriptions are returned in the following OpenMarket-specific TLVs When a delivery receipt is received.</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					
            {/* {user.user.roles.length > 0 && !(user && user.user && user.user.roles && hasRoleInWorkflowRoles(user.user.roles)) && (<>
              <BMRRecordsForm />
            </>) } */}
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}

export default Home;
